import React, { useState } from "react";
import { Grid, Button, TextField, Container, Hidden  } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import HomeWebForm from "../home/HomeWebForm";

function InfoSection({
  xlUp,
  mdUp,
  mdDown,
  smDown,
  xsDown,
  setShowWaitListForm,
}) {
  return (
    <Grid container item xs={12} md={8} alignContent="center">
      <Grid item xs={12}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: xlUp ? 80 : mdUp ? 60 : 40,
            lineHeight: 1,
            marginBottom: mdUp ? 24 : 12,
            fontWeight: 600,
          }}
        >
          Moving Services
        </Grid>
      </Grid>
      <Grid item xs={12} md={10}>
         <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
           
        <br/>
        A professional mover takes the stress out of relocating to your new home and saves you valuable time. But a wrong choice can add to your stresses through delayed moves and lost or damaged property. Souqh matches you with the right local mover.

<br/>
<br/>
<Hidden xsUp mdUp xlUp smUp> 
After the euphoria of officially becoming a homeowner (or landing a promotion in another city) comes the stress-inducing exercise of packing up your belongings and transplanting to your new home. 

Whether you are moving across the country, within the same province, or just a few miles from where you currently live, a professional moving partner will simplify the relocation. Doing everything yourself - from carefully packing your china collection, flat-screen TV, and other valuable items in boxes and loading everything into a truck, and then unpacking everything at your new home - can be a lot to take on. 

A full-service moving company takes care of the entire moving process. They do this safely and efficiently. But finding a suitable mover for your budget and needs takes time and effort. That is why Souqh is such a lifesaver. The Souqh real estate and home services marketplace pools Canadian movers into one large database that is searchable by local area.
<br/>
        </Hidden>
        </Grid>
      </Grid>
     
    </Grid>
  );
}

function ImageSection({
  xlUp,
  mdUp,
  mdDown,
  smDown,
  xsDown,})
  {
  return (
    
    <Grid item xs={12} md={4}
    style={{
      
      marginTop: mdUp ? 24 : 12,
    }}
    >
      {
        //<iframe width="100%" height="450" src="https://www.youtube.com/embed/xdh48tFm4dM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <img
       src="/images/home_page/Illustration 22.svg"
        style={{
           //height: xlUp ? 625 : mdUp ? 313 : 219,
          width: "100%",
        }}
      /> }
    </Grid>
  );
}

function Introduction({
  smUp,
  smDown,
  mdUp,
  mdDown,
  lgUp,
  xlUp,
  commonPadding,
}) {
  const [showWaitListForm, setShowWaitListForm] = useState(false);
  const handleClose = () => {
    setShowWaitListForm(false);
  };
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        backgroundImage: lgUp
          ? "url('/images/home_buyers/Green patch.svg')"
          : "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "10%",
        padding: commonPadding,
      }}
    >

      <Container>
        <Container>
          <Grid container>
            {mdUp ? (
              <Grid container direction="row">
                <InfoSection
                  xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown}

                />
                <ImageSection xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown} />
              </Grid>
            ) : (
              <Grid container direction="row">
                
                <InfoSection
                  xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown}
                /> <ImageSection  xlUp={xlUp}
                mdUp={mdUp}
                mdDown={mdDown}
                smDown={smDown} />
            </Grid>
            )}


          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}

export default Introduction;
