import React from "react";
import { Typography, Grid, Container } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";

function OurTeam({ smUp, mdUp, lgUp, xlUp, commonPadding }) {
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        backgroundImage: lgUp
          ? "url('/images/aboutus/orange_patch.svg')"
          : "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "7%",
        padding: commonPadding,
        backgroundPosition: "100%",
      }}
    >
      <Container>
        <Grid
          item
          xs={12}
          style={{
            paddingBottom: mdUp ? 60 : 36,
          }}
        >
          <Grid
            className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
            style={{
              fontSize: mdUp ? 45 : 30,
              lineHeight: mdUp ? "48px" : "37px",
              marginBottom: mdUp ? 12 : 8,
              textAlign: "center",
              fontWeight: 600,
            }}
          >
            Why Find Professionals On Souqh
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}

export default OurTeam;
