import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Card, Container, Grid, Hidden, Typography } from "@material-ui/core";
import clsx from "clsx";
import WebStyles from "../../WebStyles.module.css";

export default function ReviewCarousel({ mdDown, mdUp, lgUp, commonPadding }) {
  const testimonialArray = [
    {
      gender: "female",
      text: "Souqh is the long waited tech solution in the real estate industry that revolutionized the way we service our clients and stand out from the competition! I highly recommend it to all professionals involved in homes ownership. They truly live up to their slogan Home journey simplified",
      author: [
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          <b>Zarifa Bahran</b>
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          Realtor
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          Moe Peyawary Real Estate Team
        </p>,
      ],
    },
    {
      gender: "male",
      text: "I have had the pleasure of also receiving business already from this platform and recommend it",
      // author: "Zamir Alakozi, Lawyer, Advent Legal",
      author: [
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          <b>Zamir Alakozi</b>
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          Lawyer
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          Advent Legal
        </p>,
      ],
    },
    {
      gender: "female",
      text: "I’ve been needing a service like this that actually works!",
      // author: "Chanda Logan, Residential Mortgage Agent, The Mortgage Division",
      author: [
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          <b>Chanda Logan</b>
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          Residential Mortgage Agent
        </p>,
        <p
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 16 : 12,
            margin: 0,
          }}
        >
          The Mortgage Division
        </p>,
      ],
    },
  ];
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: commonPadding,
        backgroundColor: "#fdebe7",
        height: lgUp ? 696 : mdUp ? 435 : 300,
        marginBottom: lgUp ? 100 : mdUp ? 50 : 20,
        marginTop: lgUp ? 56 : 30,
      }}
    >
      <Container style={{ marginTop: lgUp ? 120 : mdUp ? 35 : 25 }}>
        <Container>
          <Carousel
            transitionTime={3000}
            autoPlay={true}
            showThumbs={false}
            showStatus={false}
          >
            {testimonialArray.map((data, index) => (
              <Card
                key={index}
                elevation={0}
                style={{
                  paddingRight: 10,
                  height: lgUp ? 440 : mdUp ? 374 : 250,
                  borderRadius: 10,
                }}
              >
                <Grid container item xs={12}>
                  <Hidden mdDown>
                    <Grid item xs={5}>
                      <img
                        style={{ height: 400, width: 400 }}
                        src={
                          data.gender === "female"
                            ? "/images/home_page/female_testimonial.svg"
                            : "/images/home_page/male_testimonial.svg"
                        }
                      ></img>
                    </Grid>
                  </Hidden>
                  <Grid
                    xs={12}
                    md={12}
                    lg={7}
                    style={{
                      marginTop: mdUp ? 30 : 0,
                      paddingRight: 10,
                      padding: mdDown ? 20 : "0px 48px",
                    }}
                    item
                    // container
                    // align="left"
                    alignContent="center"
                    container
                  >
                    <Grid
                      item
                      xs={12}
                      className={clsx(
                        WebStyles.paraBlackText,
                        WebStyles.nunitoFont
                      )}
                      style={{
                        fontSize: mdUp ? 20 : 14,
                        // lineHeight: mdUp ? "39px" : "19.5px",
                        // fontStyle: "italic",
                        textAlign: "left",
                      }}
                    >
                      {data.text}
                    </Grid>
                    <Grid
                      className={clsx(
                        WebStyles.paraBlackText,
                        WebStyles.hankrndFont
                      )}
                      style={{
                        fontSize: mdUp ? 16 : 12,
                        // lineHeight: mdUp ? "30px" : "15px",
                        // marginTop: mdDown ? 20 : 0,
                        textAlign: "left",
                        marginTop: mdUp ? 24 : 12,
                      }}
                      item
                      xs={12}
                    >
                      {data.author}
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            ))}
          </Carousel>
        </Container>
      </Container>
    </Grid>
  );
}
