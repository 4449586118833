import { Button, Chip, Container, Grid, Snackbar } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import WebStyles from "../../WebStyles.module.css";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ExploreServiceProvider({
  selectedTab,
  smDown,
  mdUp,
  lgUp,
  lgDown,
  mdDown,
  smUp,
  commonPadding,
}) {
  const [showComingSoon, setShowComingSoon] = React.useState(false);
  // let businessTypes = [
  //   "Realtor",
  //   "Mortgage Broker",
  //   "Lawyer",
  //   "Contractor",
  //   "Mover",
  // ];
  let businessTypes = [
    {
      id: 1,
      name: "Real Estate Broker / Agent",
      shortName: "Realtor",
      logoUrl: "Realtor.svg",
      isBusinessType: true,
      sequenceNumber: 1,
    },
    {
      id: 2,
      name: "Mortgage Broker / Agent",
      shortName: "Mortgage Broker",
      logoUrl: "MortgageBroker.svg",
      isBusinessType: true,
      sequenceNumber: 2,
    },
    {
      id: 3,
      name: "Real Estate Lawyer",
      shortName: "Lawyer",
      logoUrl: "LegalServices.svg",
      isBusinessType: true,
      sequenceNumber: 3,
    },
    {
      id: 6,
      name: "Mover",
      shortName: "Moving",
      logoUrl: "Movers.svg",
      isBusinessType: true,
      sequenceNumber: 4,
    },
    {
      shortName: "And More",
    },
  ];

  return (
    <Grid
      item
      container
      lg={11}
      md={12}
      style={{
        padding: commonPadding,
        backgroundColor: "#d9f5f3",
        marginTop: 20,
        borderTopRightRadius: lgUp ? 210 : 0,
        borderBottomRightRadius: lgUp ? 210 : 0,
      }}
    >
      <Snackbar
        open={showComingSoon}
        autoHideDuration={6000}
        onClose={() => {
          setShowComingSoon(false);
        }}
        ContentProps={{ style: { backgroundColor: "#1b1e4c", minWidth: 0 } }}
        // style={{ display: "flex" }}
        message={
          <Grid container justifyContent="center" item xs={12}>
            Coming soon !
          </Grid>
        }
      >
        {/* <Grid
          container
          justifyContent="center"
          item
          xs={12}
          style={{ backgroundColor: "#1b1e4c" }}
        >
          Coming soon !
        </Grid> */}
      </Snackbar>
      <Container
        style={{ paddingBottom: 20, paddingTop: 10, padding: "46px 20px 50px" }}
      >
        <Grid
          align="left"
          xs={12}
          item
          className={clsx(WebStyles.hankrndFont, WebStyles.blueHeaderTxt)}
          style={{
            fontSize: mdUp ? 40 : 20,
            lineHeight: mdUp ? "48px" : "24px",
            fontWeight: 600,
          }}
        >
          {selectedTab === 0
            ? "Explore Service Providers"
            : "Become a Service Provider"}
        </Grid>
        <Grid
          align="left"
          xs={12}
          item
          className={clsx(WebStyles.barlowFont, WebStyles.paraBlackText)}
          style={{
            fontSize: mdUp ? 28 : 14,
            lineHeight: mdUp ? "38px" : "19px",
            marginTop: 10,
          }}
        >
          <Grid lg={7} md={12} sm={12} item>
            {" "}
            {selectedTab === 0
              ? " Simply search, compare, and connect with trusted service providers - all in one platform."
              : "Your one-stop connection to leads, referrals, technology and digital marketing."}
          </Grid>
        </Grid>
        <Grid container item align="left" style={{ marginTop: 10 }}>
          <Grid item lg={8} md={7} xs={9} style={{ marginTop: 10 }}>
            {businessTypes.map((type, index) => (
              <Chip
                key={index}
                style={{
                  marginRight: 5,
                  color: "#72CAC9",
                  fontFamily: "sq-hank-rnd",
                  fontWeight: 500,
                  fontSize: mdUp ? 20 : 10,
                  backgroundColor: "#FFFF",
                  marginBottom: lgDown ? 10 : 0,
                }}
                label={type.shortName}
                onClick={() => {
                  const explorePageUrl = type.name
                    ? `https://app.souqh.ca/marketplace?websiteSearchedServiceId=${type.name}`
                    : `https://app.souqh.ca/marketplace`;
                  window.open(explorePageUrl);
                }}
              />
            ))}
          </Grid>
          <Grid
            item
            lg={4}
            md={12}
            container
            justifyContent="flex-end"
            style={{ marginTop: mdDown ? 10 : 0 }}
          >
            <Button
              style={{
                backgroundColor: "#72CAC9",
                color: "#FFFFFF",
                textTransform: "none",
                borderRadius: 20,
                marginLeft: smDown ? 20 : 0,
                padding: "8px 20px",
                fontSize: mdUp ? 20 : 10,
              }}
              onClick={() => {
                if (selectedTab === 0) {
                  window.open("https://app.souqh.ca/marketplace");
                } else {
                  window.open("https://app.souqh.ca/new");
                }
              }}
            >
              <Grid item className={WebStyles.nunitoFont}>
                {selectedTab === 0
                  ? "Explore Service Providers"
                  : "Become a Service Provider"}
              </Grid>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}

export default ExploreServiceProvider;
