import React, { useEffect, useState } from "react";
import { Grid, useMediaQuery, useTheme, Hidden } from "@material-ui/core";
import MoreGreatFeatures from "./MoreGreatFeatures";
import Introduction from "./Introduction";
import SubFooter from "../SubFooter";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree";
//import HomeJournerySimplified from "./HomeJournerySimplified";
function ReapprovedMortgageBroker() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const commonPadding = lgUp ? "0px 6%" : "0px 2%";
  // const commonPadding = 0;
  const [selectedTab, setselectedTab] = useState(0);
  const title = "Homebuyers in Canada trust Souqh to find the best real estate service providers to help them on their home journey.";
  const subTitle =
  "Search Souqh for the best mortgage broker in your local area here.";
  const btnText = "Search Now!";
  const redirectUrl = "https://app.souqh.ca/marketplace";

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div
      style={{
        overflow: "hidden",
        position: "relative",
        top: smUp ? (lgUp ? 55 : 60) : 78,
        marginBottom: 105,
      }}
    >
      <Introduction
        smUp={smUp}
        smDown={smDown}
        mdUp={mdUp}
        mdDown={mdDown}
        lgUp={lgUp}
        xlUp={xlUp}
        xsDown={xsDown}
        commonPadding={commonPadding}
      />
       
      <Hidden lgUp mdUp smUp xsUp>
    
       <MoreGreatFeatures
        smUp={smUp}
        mdUp={mdUp}
        lgUp={lgUp}
        xsDown={xsDown}
        commonPadding={commonPadding}
      ></MoreGreatFeatures>
        </Hidden>
     
      <SectionThree
        smUp={smUp}
        smDown={smDown}
        mdUp={mdUp}
        mdDown={mdDown}
        lgUp={lgUp}
        xlUp={xlUp}
        xsDown={xsDown}
        commonPadding={commonPadding}
      />
      <SectionTwo
        smUp={smUp}
        smDown={smDown}
        mdUp={mdUp}
        mdDown={mdDown}
        lgUp={lgUp}
        xlUp={xlUp}
        xsDown={xsDown}
        commonPadding={commonPadding}
      />
        
      <SubFooter
        title={title}
        subTitle={subTitle}
        btnText={btnText}
        redirectUrl={redirectUrl}
      />
    </div>
  );
}

export default ReapprovedMortgageBroker;
