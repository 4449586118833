import {
  Grid,
  useMediaQuery,
  useTheme,
  Link as MaterialLink,
} from "@material-ui/core";
import React, { useState } from "react";
import WebStyles from "../../WebStyles.module.css";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import ServicesMenu from "../ServicesMenu";

function SiteMenu({ closeMenuBar, openMenu }) {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [expand, setExpand] = useState(false);

  const navLinks = [
    {
      name: "Home Buyers/Owners",
      link: "/home-buyers",
    },
    {
      name: "Service Provider",
      link: "/service-providers",
    },
    {
      name: "About Us",
      link: "/about-us",
    },
    {
      name: "Contact Us",
      link: "/contact-us",
    },
    {
      name: "Services",
      link: "/services",
    },
    {
      name: "Landing Page",
      link: "/landing-page",
    },
  ];
  return (
    <Grid
      item
      container
      xs={12}
      className={"sitehmbr"}
      style={{
        width: "100%",
        height: "100%",
        position: "fixed",
        left: openMenu ? 60 : 4000,
        zIndex: 1200,
        // backgroundColor: "rgb(245 247 247)",
        backgroundColor: "#0e1c4e",
      }}
    >
      <Grid item xs={12} style={{ padding: "32px 5%" }}>
        <Grid item container xs={12} style={{ marginBottom: 16 }}>
          <CloseIcon
            onClick={() => closeMenuBar()}
            style={{
              fontSize: 48,
              height: 48,
              width: 48,
              fontWeight: 600,
              color: "white",
            }}
          />
        </Grid>
        <ServicesMenu></ServicesMenu>
        <Grid
          item
          container
          xs={12}
          className={WebStyles.paraBlackText}
          style={{
            fontSize: 24,
            padding: "12px 0px",
            borderBottom: "1px solid rgb(189 191 202)",
            cursor: "pointer",
            color: "white",
          }}
        >
          <MaterialLink
            href="https://app.souqh.ca/marketplace"
            target="_blank"
            onClick={() => closeMenuBar()}
            style={{
              textDecoration: "none",
              marginLeft: 10,
              color: "white",
            }}
            className={clsx(WebStyles.nunitoFont)}
          >
            Marketplace
          </MaterialLink>
        </Grid>
        {navLinks.map((item, index) => {
          return (
            <Grid
              item
              container
              xs={12}
              className={WebStyles.paraBlackText}
              style={{
                fontSize: 24,
                padding: "12px 0px",
                borderBottom: "1px solid rgb(189 191 202)",
                cursor: "pointer",
                color: "white",
              }}
              key={index}
            >
              <Link
                to={item.link}
                onClick={() => closeMenuBar()}
                style={{
                  textDecoration: "none",
                  marginLeft: 10,
                  color: "white",
                }}
                className={clsx(WebStyles.nunitoFont)}
              >
                {item.name}
              </Link>
            </Grid>
          );
        })}
        <Grid
          item
          container
          xs={12}
          className={WebStyles.paraBlackText}
          style={{
            fontSize: 24,
            padding: "12px 0px",
            borderBottom: "1px solid rgb(189 191 202)",
            cursor: "pointer",
            color: "white",
          }}
        >
          <MaterialLink
            href="https://blog.souqh.ca/"
            target="_blank"
            onClick={() => closeMenuBar()}
            style={{
              textDecoration: "none",
              marginLeft: 10,
              color: "white",
            }}
          >
            Blog
          </MaterialLink>
        </Grid>

        <Grid
          item
          container
          xs={12}
          className={WebStyles.paraBlackText}
          style={{
            fontSize: 24,
            padding: "12px 3px",
            borderBottom: "1px solid rgb(189 191 202)",
            cursor: "pointer",
            color: "white",
          }}
        >
          <Grid
            container
            item
            xs={12}
            onClick={() => setExpand(!expand)}
            style={{ paddingRight: 12 }}
          >
            <Grid container item xs={10} justifyContent="flex-start">
              <Link
                style={{
                  textDecoration: "none",
                  marginLeft: 10,
                  color: "white",
                }}
              >
                Login
              </Link>
            </Grid>
            {expand ? <ExpandLess /> : <ExpandMore />}
          </Grid>
          {expand && (
            <Grid container>
              <Grid
                item
                container
                xs={12}
                className={WebStyles.paraBlackText}
                style={{
                  fontSize: 16,
                  padding: "12px 0px",
                  cursor: "pointer",
                  color: "white",
                }}
              >
                <MaterialLink
                  href="https://app.souqh.ca/userlogin"
                  onClick={() => closeMenuBar()}
                  style={{
                    textDecoration: "none",
                    marginLeft: 10,
                    color: "white",
                  }}
                >
                  Home Buyers
                </MaterialLink>
              </Grid>
              <Grid
                item
                container
                xs={12}
                className={WebStyles.paraBlackText}
                style={{
                  fontSize: 16,
                  padding: "12px 0px",
                  cursor: "pointer",
                  color: "white",
                }}
              >
                <MaterialLink
                  href="https://app.souqh.ca/login"
                  onClick={() => closeMenuBar()}
                  style={{
                    textDecoration: "none",
                    marginLeft: 10,
                    color: "white",
                  }}
                >
                  Service Providers
                </MaterialLink>
              </Grid>
              
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SiteMenu;
