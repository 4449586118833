import React from "react";
import { Grid, Container, useTheme, useMediaQuery } from "@material-ui/core";
import WebStyles from "../WebStyles.module.css";
import StepBox from "../components/serviceProviders/StepBox";
import clsx from "clsx";

export default function ServicesHowItWorks() {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  // const commonPadding = lgUp ? "0px 6%" : "0px 2%";

  const steps = [
    {
      name: "Register",
      description:
        "Sign up for free. Complete your profile in less than 2 minutes and get started.",
      image: "/images/service_providers/laptop.svg",
      numImg: "/images/service_providers/one.svg",
    },
    {
      name: "Search",
      description:
        "Souqh makes finding service providers easier with storefronts, verified ratings and simplified workflows.",
      image: "/images/service_providers/search.svg",
      numImg: "/images/service_providers/two.svg",
    },
    {
      name: "Connect",
      description:
        "Connect with trusted service providers. From seamless communication right at your fingertips, to documentation that is easy to access and locate all in one place, Souqh is your one-stop connection to home buying resources.",
      image: "/images/service_providers/hand_cell.svg",
      numImg: "/images/service_providers/three.svg",
    },
  ];
  return (
    <Grid
      item
      container
      xs={12}
      style={
        {
          // backgroundImage: lgUp
          //   ? "url('/images/home_buyers/Orange patch_right.svg'), url('/images/home_buyers/Pink patch.svg')"
          //   : "none",
          // backgroundRepeat: "no-repeat, no-repeat, no-repeat",
          // backgroundSize: "10%, 7%",
          // backgroundPosition: "100% 0%, 0% 10%",
          // padding: commonPadding,
        }
      }
    >
      <Container>
        <Container>
          <Grid
            item
            xs={12}
            lg={12}
            container
            style={{ marginTop: mdUp ? 148 : 16, paddingBottom: 40 }}
          >
            <Grid
              item
              xs={12}
              style={{
                marginBottom: 12,
              }}
            >
              <Grid
                className={clsx(
                  WebStyles.samonHeaderTxt,
                  WebStyles.hankrndFont
                )}
                style={{
                  fontSize: mdUp ? 60 : 30,
                  lineHeight: mdUp ? "74px" : "32px",
                  textAlign: "left",
                  marginBottom: 8,
                  fontWeight: 600,
                }}
              >
                How it works
              </Grid>
              <Grid
                className={clsx(WebStyles.paraBlackText, WebStyles.nunitoFont)}
                style={{
                  fontSize: mdUp ? 28 : 14,
                  lineHeight: mdUp ? "38px" : "19px",
                  marginBottom: 24,
                  textAlign: "left",
                }}
              >
                Get started in three simple steps
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              container
              style={{
                marginBottom: 16,
              }}
            >
              {steps.map((item, index) => {
                return (
                  <Grid item xs={12} md={4} container key={index}>
                    <StepBox
                      index={index}
                      data={item}
                      mdUp={mdUp}
                      lgUp={lgUp}
                      lgDown={lgDown}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}
