
export const HB_QUESTION_ANSWERS = [
  {
    id: 1,
    question: "Registering with the Real Estate Council of Ontario (RECO",
    answer:
    <p>You must complete your registration with RECO within 12 months of completing the Pre-Registration Phase. However, a prerequisite for RECO registration is obtaining employment by a registered brokerage firm. A good place to start looking for real estate agent jobs is Indeed.com. You have the option to choose anywhere from small independent brokerages to multinational real estate giants.</p>
  },
  {
    id: 2,
    question: "Complete your registration application on RECO",
    answer:<p>
    Once employed, complete your registration application on RECO’s online portal <a href="https://myweb.reco.on.ca/members/">MyWeb.</a> There are no fees to sign up on the portal and it’s fairly easy to use. On MyWeb you can renew, or reinstate your registration, access the continuing education program, pay your insurance fees, update your address and contact information and view important industry information. Registration forms can be found on RECO’s website <a href="https://www.reco.on.ca/form-category/registration/?zyc_query=%7B%22term_id%22%3A70%2C%22name%22%3A%22Registration%22%2C%22slug%22%3A%22registration%22%2C%22term_group%22%3A0%2C%22term_taxonomy_id%22%3A70%2C%22taxonomy%22%3A%22form-category%22%2C%22description%22%3A%22%22%2C%22parent%22%3A0%2C%22count%22%3A4%2C%22filter%22%3A%22raw%22%2C%22term_order%22%3A%220%22%7D">here</a> and a breakdown of the fee structure <a href="https://www.reco.on.ca/wp-content/uploads/Application-Fees4.pdf">here.</a>,
  </p>,
  },
  {
    id: 3,
    question: "Post-registration phase",
    answer:
      <p>The third phase of becoming a real estate agent in Ontario is the Post-Registration Phase. During this phase, you will complete your “articling” period of two years while continuing to take mandatory courses of up to 50 hours. These courses will teach you all about real estate law and commercial transactions.
        <ul>

          <li><a href="https://humber.ca/realestate/#nav2-panel">Working in Real Estate: Rural, Recreational, Waterfront Properties</a></li>
          <li><a href="https://humber.ca/realestate/#nav3-panel">Working in Real Estate: Ownership Alternatives and Complexities</a></li>
          <li>Real Estate as an Investment Strategy: Residential Properties</li>
          <li>Real Estate as an Investment Strategy: Commercial Properties - Fundamentals</li>
          <li>Real Estate as an Investment Strategy: Commercial Properties - Advanced</li>
        </ul>

      </p>,
  },
  
];
