import React, { useState } from "react";
import { Grid, Button, TextField, Container, Hidden  } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";

function SectionTwo({
  xlUp,
  mdUp,
  smUp,
  xsUp,
  mdDown,
  smDown,
  xsDown,
  setShowWaitListForm,
}) {
  return (
    <Container>
      <Container>
    <Grid container item xs={12}  md={12} alignContent="center" style={{
     paddingLeft: mdUp ? "10%" : smUp ? "5%" : "0",
       paddingRight: mdUp? "10%" : smUp ? "5%" : "0"
    }}>
      <Grid item xs={12}>
        <Grid
          align="left"
          className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 50 : 30,
           
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
            marginTop : mdUp ? 12:8,
          }}
        >
          A promising record
          
        </Grid>
      </Grid>
      <Hidden mdUp smUp lgUp  xsUp>
      <Grid item xs={12} md={12}>
     
         <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
         
This one might seem like a no-brainer, but one of the most important things you need to focus on, whether you’re a client or aspiring realtor, is your track record. What this means is a recorded history of successful deals and stellar reviews from all types of clients.        </Grid>

      </Grid>
      </Hidden>
      <Grid container item xs={12}>
        <Grid item xs={12} md={6} style={{
          //paddingLeft: mdUp ? "2%" : smUp ? "1%" : "0",
          padding: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ?  35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
          Buyers and <br/>
          sellers
        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
             lineHeight: 1.5,
            //fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
  <Hidden mdUp lgUp smUp xsUp>
  make sure you check out your realtor’s online portfolio which includes ratings and reviews, number of deals closed, average time on market, and charges. Don’t be discouraged by new players in the field; what they lack in experience they will make up with contemporary knowledge and skills. They will also be more responsive and eager to please. Regardless of whether your real estate agent is relatively new or experienced, you’ll get a good idea of their services by going through their websites.
  </Hidden>
  <br/>
  If they attract high-quality well-paying clients, then you know people trust them with their money and property.
</Grid>
        </Grid>
        <Grid item xs={12} md={6} style={{
          padding: mdUp ? "2%" : smUp ? "1%" : "0",
          //paddingRight: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
         Starting out in the real estate business?
        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
             lineHeight: 1.5,
            //fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
      <Hidden mdUp smUp lgUp xsUp>Remember it’s the quality of your service that matters rather than the quantity of years and reviews. You have an edge over older and more established agents when it comes to being tech-savvy. Focus on building and curating an impressive digital image. Your website should be top-notch: it needs to have an engaging and easily accessible UI, clear high-resolution images, fact-checked and high quality content, as well as all your contact details displayed prominently.</Hidden>
<br/>
If a potential client has a positive experience navigating your website, they’re more likely to turn into a permanent customer
</Grid>
        
        </Grid>
      
      </Grid>
      {/* <Grid item xs={12} md={6} style={{ marginTop: mdUp ? 20 : 10 }}>
      <TextField fullWidth placeholder="Your email"></TextField>
    </Grid> */}
    <Hidden mdUp lgUp smUp xsUp>
    <strong>Prerequisites
    </strong>
    <ul>
      <li>Canadian resident</li>
      <li>
      Minimum age: 18 years

      </li>
      <li>An Ontario secondary school diploma or equivalent</li>
    </ul>
</Hidden>




    </Grid>
    </Container>
    </Container>
  );
}



// function Introduction({
//   smUp,
//   smDown,
//   mdUp,
//   mdDown,
//   lgUp,
//   xlUp,
//   commonPadding,
// }) {
//   const [showWaitListForm, setShowWaitListForm] = useState(false);
//   const handleClose = () => {
//     setShowWaitListForm(false);
//   };
//   return (
//     <Grid
//       item
//       container
//       xs={12}
//       style={{
//         backgroundImage: lgUp
//           ? "url('/images/home_buyers/Green patch.svg')"
//           : "none",
//         backgroundRepeat: "no-repeat",
//         backgroundSize: "10%",
//         padding: commonPadding,
//       }}
//     >

//       <Container>
//         <Container>
//           <Grid container>
          
//               <Grid container direction="row">
//                 <SectionTwo
//                   xlUp={xlUp}
//                   mdUp={mdUp}
//                   mdDown={mdDown}
//                   smDown={smDown}

//                 />
                
//               </Grid>
          

//           </Grid>
//         </Container>
//       </Container>
//     </Grid>
//   );
// }

export default SectionTwo;
