import React from "react";
import clsx from "clsx";
import { Container, Grid, useMediaQuery, useTheme } from "@material-ui/core";
import WebStyles from "../WebStyles.module.css";

export default function ServicesHeader({ title, children }) {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <Grid
      align="center"
      item
      className={clsx(WebStyles.purpleTxt, WebStyles.hankrndFont)}
      style={{
        fontSize: mdUp ? 40 : 20,
        lineHeight: mdUp ? "40px" : "20px",
        marginBottom: 36,
        marginTop: 10,
        fontWeight: 600,
      }}
    >
      {title}
      <Container>
        <Container>
          <Grid container direction="row">
            <Grid container item xs={12} md={12} alignContent="center">
              <Grid item xs={12}>
                <Grid
                  align="center"
                  className={clsx(
                    WebStyles.paraBlackText,
                    WebStyles.barlowFont
                  )}
                  style={{
                    fontSize: mdUp ? 22 : 18,
                    // lineHeight: 1,
                    marginBottom: xlUp ? 24 : mdUp ? 20 : 10,
                    fontWeight: "normal",
                  }}
                >
                  {children}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}
