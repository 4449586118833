import React, { useEffect } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import SubFooter from "../SubFooter";
import ServicesLayout from "../ServicesLayout";

function RealEstateLawyerList() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const xsUp = useMediaQuery(theme.breakpoints.up("xs"));
  const title = "Get Started with Souqh";
  const subTitle =
    "If you are looking for the best realtors in your area anywhere in Canada, Souqh has the solution for you. Souqh is a marketplace for real estate service providers, from realtors, real estate lawyers, to contractors.";
  const btnText = "Sign Up Now!";
  const redirectUrl = "";

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const SP_QUESTION_ANSWERS = [
    // {
    //   id: 1,
    //   question: "What makes a good real estate agent",
    //   link:
    //    "/what-makes-a-good-real-estate-agent",
    // },
    // {
    //   id: 2,
    //   question: "How to become a real estate agent in Ontario",
    //   link:
    //     "/become-a-real-estate-agent",
    // },
  ];

  const HB_QUESTION_ANSWERS = [
    {
      id: 1,
      question: "Investing in a real estate lawyer",
      //answer:
      //"We know from our own experience how overwhelming and stressful the home journey can be and we were determined to make the process easier. Souqh is Canada’s only end-to-end digital services marketplace who supports home buyers looking for guidance with their home buying journey. Finding a trusted service provider, such as a realtor, mortgage broker, lawyer, or general contractor, has never been easier. From verified reviews, to documentation that is easy to access and locate all in one place, Souqh simplifies your home journey. And the best part? It’s all free!",
      link: "/real-estate-lawyers",
    },
  ];

  const features = [
    {
      name: "Find the right real estate service provider for your needs",
      color: "#72cac9",
      image: "/images/home_buyers/Illustration 14.svg",
      description:
        "Souqh is where real estate investors and homebuyers in Canada are going to find experienced local real estate lawyers.",
    },
    {
      name: "Trusted Service Providers",
      color: "#72cac9",
      image: "/images/home_buyers/Trusted Service Providers.svg",
      description:
        "Worried about the right fit? Souqh helps you find trusted service providers across a pool of storefronts that match your unique needs, along with verified ratings and testimonials from home buyers just like you.",
    },
    {
      name: "Easily connect, safely share documents, and corroborate information",
      color: "#F37C61",
      image: "/images/home_buyers/Illustration 16.svg",
      description:
        "our platform makes it super easy to collaborate, share and sign documents, and send payments. It’s a safe place to do business where your personal information and documents are safely stored and only accessible to you.",
    },
    {
      name: "Earn Credits",
      color: "#B872AF",
      image: "/images/home_buyers/Illustartion 17.svg",
      description:
        "Every time you interact on Souqh or refer a service provider, you earn credits that can be used for future services. It pays to transact on Souqh!",
    },
    {
      name: "Rest Easy",
      color: "#B872AF",
      image: "/images/home_buyers/Illustration 18.svg",
      description:
        "Rest easy knowing that you completely own and control your data. Our enterprise-grade cloud security, which runs on industry-leading Amazon cloud, protects your data using 256-bit SSL/TLS encryption.",
    },
  ];

  return (
    <div
      style={{
        overflow: "hidden",
        position: "relative",
        top: smUp ? (lgUp ? 55 : 60) : 78,
        marginBottom: 105,
      }}
    >
      <ServicesLayout
        smUp={smUp}
        mdUp={mdUp}
        lgUp={lgUp}
        lgDown={lgDown}
        xsUp={xsUp}
        HB_QUESTION_ANSWERS={HB_QUESTION_ANSWERS}
        SP_QUESTION_ANSWERS={SP_QUESTION_ANSWERS}
        features={features}
        btnText="Lawyer"
        title="Real Estate Lawyers"
        serviceFeatureSubTitle="We have made it completely painless to find the right professionals to help you through your home journey."
        serviceName="Real Estate Lawyer"
      >
        Invest in an experienced real estate lawyer that knows your area and
        local laws well to protect yourself, stay compliant, and get the most
        out of your real estate deals.
        <br />
        <br />
      </ServicesLayout>
      <SubFooter
        title={title}
        subTitle={subTitle}
        btnText={btnText}
        redirectUrl={redirectUrl}
      />
    </div>
  );
}

export default RealEstateLawyerList;
