import React, { useEffect, useState } from "react";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import Introduction from "./Introduction";
import SubFooter from "../SubFooter";
import SectionTwo from "./SectionTwo";
import SectionThree from "./SectionThree"
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions";
function GoodRealAgentPage() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const commonPadding = lgUp ? "0px 6%" : "0px 2%";
  // const commonPadding = 0;
  const [selectedTab, setselectedTab] = useState(0);
  const title = "Souqh has the solution for you.";
  const subTitle =
    "Souqh is a marketplace for real estate service providers, from realtors, real estate lawyers, to contractors.";
  const btnText = "Search Now!";
  const redirectUrl = "https://app.souqh.ca/marketplace?websiteSearchedServiceId=Real%20Estate%20Broker%20/%20Agent&websiteSearchedCityId=1";

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div
      style={{
        overflow: "hidden",
        position: "relative",
        top: smUp ? (lgUp ? 55 : 60) : 78,
        marginBottom: 105,
      }}
    >
      <Introduction
        smUp={smUp}
        smDown={smDown}
        mdUp={mdUp}
        mdDown={mdDown}
        lgUp={lgUp}
        xlUp={xlUp}
        xsDown={xsDown}
        commonPadding={commonPadding}
      />
       <SectionTwo
        smUp={smUp}
        smDown={smDown}
        mdUp={mdUp}
        mdDown={mdDown}
        lgUp={lgUp}
        xlUp={xlUp}
        xsDown={xsDown}
        commonPadding={commonPadding}
      />
      <SectionThree
        smUp={smUp}
        smDown={smDown}
        mdUp={mdUp}
        mdDown={mdDown}
        lgUp={lgUp}
        xlUp={xlUp}
        xsDown={xsDown}
        commonPadding={commonPadding}
      />
     
       <FrequentlyAskedQuestions
        mdUp={mdUp}
        lgUp={lgUp}
        smUp={smUp}
        smDown={smDown}
        commonPadding={commonPadding}
      ></FrequentlyAskedQuestions>
      
      <SubFooter
        title={title}
        subTitle={subTitle}
        btnText={btnText}
        redirectUrl={redirectUrl}
      />
    </div>
  );
}

export default GoodRealAgentPage;
