import {
  ButtonBase,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  withStyles,
  Link as MaterialLink,
  Button,
  Hidden,
  TextField,
 
} from "@material-ui/core";

import React, { useState, useEffect } from "react";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import AddIcon from "@material-ui/icons/Add";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveIcon from "@material-ui/icons/Remove";
import { cloneDeep, forEach } from "lodash";
import { HB_QUESTION_ANSWERS } from "./homePageConstants";
import Fade from "@material-ui/core/Fade";
import { useHistory } from "react-router";
import IntroductionHomeBuyer from "./IntroductionHomeBuyer";
import IntroductionRealtor from "./IntroductionRealtor";
import HowItWorks from "./HowItWorks";
import ExploreSP from "./ExploreSP";
//import HomeWebForm from "./HomeWebForm";
import Features from "./Features";
import SubFooter from "../SubFooter";
const title = "Get Started with Souqh";
  const subTitle =
    "If you are looking for the best realtors in your area anywhere in Canada, Souqh has the solution for you. Souqh is a marketplace for real estate service providers, from realtors, real estate lawyers, to contractors.";
  const btnText = "Search Now!";
  const redirectUrl = "https://app.souqh.ca/marketplace?websiteSearchedServiceId=Real%20Estate%20Broker%20/%20Agent&websiteSearchedCityId=1";

export default function HomeBuyerGridSection({
  commonPadding,
  mdUp,
  lgUp,
  smUp,
  xlUp,
  smDown,
  lgDown,
  mdDown
}) {
  const [selectedTab, setselectedTab] = useState(0);
  const isMobileDevice = () => {
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  };
  
  const isTabletDevice = () => {
    let check = false;
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      check = true;
    }
  
    return check;
  };
  
  const [questionAndAnswersRow1, setQuestionAndAnswersRow1] =
    useState(HB_QUESTION_ANSWERS);
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [who, setWho] = React.useState("");

  const handleClickOpen = (who) => {
    setWho(who);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (value === 0) {
      setQuestionAndAnswersRow1(HB_QUESTION_ANSWERS);
    }
  }, [value]);

  const handelExpandRow1 = (index) => {
    let tempData = cloneDeep(questionAndAnswersRow1);

    tempData.forEach((data, i) => {
      if (index === data.id) {
        data.expanded = !data.expanded;
      } else {
        data.expanded = false;
      }
    });
    setQuestionAndAnswersRow1(tempData);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const AntTabs = withStyles({
  //   root: {
  //     borderBottom: "1px solid #e8e8e8",
  //   },
  //   indicator: {
  //     backgroundColor: "#F37C61",
  //   },
  // })(Tabs);

  // const AntTab = withStyles((theme) => ({
  //   root: {
  //     textTransform: "none",
  //     minWidth: 72,
  //     fontWeight: 700,
  //     marginRight: theme.spacing(4),
  //     fontFamily: ["Nunito Sans", "sans-serif"].join(","),
  //     color: "#72CAC9",
  //     "&:hover": {
  //       color: "#F37C61",
  //       opacity: 1,
  //     },
  //     "&$selected": {
  //       color: "#F37C61",
  //       fontWeight: 700,
  //     },
  //     "&:focus": {
  //       color: "#F37C61",
  //     },
  //   },
  //   selected: {},
  // }))((props) => <Tab disableRipple {...props} />);

  return (
  <Grid >
    
    <IntroductionHomeBuyer
        smDown={smDown}
        mdUp={mdUp}
        lgUp={lgUp}
        lgDown={lgDown}
        xlUp={xlUp}
        smUp={smUp}
        commonPadding={commonPadding}

      >
      </IntroductionHomeBuyer>
      <HowItWorks
        smDown={smDown}
        mdUp={mdUp}
        lgUp={lgUp}
        lgDown={lgDown}
        xlUp={xlUp}
        smUp={smUp}
      ></HowItWorks>
      <Features
        smUp={smUp}
        smDown={smDown}
        mdUp={mdUp}
        lgUp={lgUp}
        lgDown={lgDown}
        xlUp={xlUp}

      />
      <Grid >
      <Container commonPadding={commonPadding}
        style={{
          backgroundImage:
            "url('/images/home_page/question-answer-section.svg')",
          backgroundRepeat: "no-repeat",
          backgroundSize: smUp ? (mdUp ? "28%" : "50% 50%") : "40%",
          backgroundPosition: "right -55px",
          minHeight:  mdUp ?  "300" : "auto",
          
        }}>
      <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.hankrndFont)}
          style={{
            fontSize: xlUp ? 50 : mdUp ? 40 : 24,
            lineHeight: 1,
            marginBottom: mdUp ? 24 : 12,
            fontWeight: 600,
          }}
        >
          Helpful Resources
        </Grid>
        <Container style={{
          minHeight:"150px"
        }}
        >
      {questionAndAnswersRow1.map((data, index) => {
                return (
                  <Grid container
                    key={index}
                    item
                    xs={12}
                   direction="row"
                    align="left"
                    style={{ marginBottom: 10, }}
                  >
                    
                    <Grid
                      className={clsx(
                        WebStyles.paraBlackText,
                        WebStyles.hankrndFont
                      )}
                      style={{
                        fontSize: mdUp ? 20 : 10,
                        lineHeight: mdUp ? "24px" : "12px",
                        marginBottom: 10,
                        fontWeight: 600,
                      }}
                      item
                      container
                      justify="flex-start"
                      alignContent="flex-start"
                      xs={12}
                      md={6}
                    >
                      {/* <Grid container item xs={1} alignContent="baseline">
                        <Grid item>
                          <ButtonBase onClick={() => handelExpandRow1(data.id)}>
                            {!data.expanded ? (
                              <AddIcon
                                style={{ fontSize: mdUp ? "1.5rem" : "1rem" }}
                                className={WebStyles.purpleTxt}
                              />
                            ) : (
                              <RemoveIcon
                                style={{ fontSize: mdUp ? "1.5rem" : "1rem" }}
                                className={WebStyles.purpleTxt}
                              />
                            )}
                          </ButtonBase>
                        </Grid>
                      </Grid> */}
                      <Grid container item xs={1} alignContent="baseline">
                      <ControlPointIcon
                                style={{ fontSize: mdUp ? "1.5rem" : "1rem" }}
                                className={WebStyles.purpleTxt}
                              />
                      </Grid>
                      <Grid item xs={10} container alignContent="center">
                      <MaterialLink
            href={data.link}  style={{color:"#2b3350"}}>
                        {data.question}</MaterialLink>
                      </Grid>
                    </Grid>
                   
                    
                    </Grid>
                );
              })}
              </Container>
              </Container>
              </Grid>    
         
              <ExploreSP   smUp={smUp}
        smDown={smDown}
        mdUp={mdUp}
        lgUp={lgUp}
        lgDown={lgDown}
        xlUp={xlUp} />   
        
  </Grid>
  );
}
