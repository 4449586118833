import { Hidden } from "@material-ui/core";

export const HB_QUESTION_ANSWERS = [
  {
    id: 1,
    question: "What to look for when selecting a real estate agent",
    answer:
    <p>If you’re looking for a real estate agent in Ontario, you need to make sure they are registered under the Real Estate Business and Brokers Act, 2002 (REBBA). Before you begin working with a real estate broker or salesperson make sure you confirm that they are registered by using the <a href="https://www.reco.on.ca/RegistrantSearch">Real Estate Professional Search feature.</a><br/>

    It is also worth checking out the different properties they’ve sold and the prices they have fetched in the market.</p>
  },
  {
    id: 2,
    question: "How is the real estate commission calculated?",
    answer:<p>
    <Hidden mdUp lgUp xsUp smUp>When you’re hiring a real estate agent, remember that all of the details about the agent’s commission should be outlined in the listing agreement, which is the contract both parties will sign to sell the property.

Real estate commissions are to be paid by the seller (homeowner) and will be split between the agent who represents the seller and the agent who represents the buyer. The seller of the property has to pay for both buying and selling agents' commission.
</Hidden>
There are three types of real estate commission in Ontario: 
<ul>
  <li>
  A percentage of the sales price: an average of 5%
  </li>
  <li>
A fixed or flat fee: $20,000
</li> 
<li>A combination of a % and a flat fee: $4.5% + $5,000
  </li>
</ul>
<Hidden lgUp mdUp smUp xsUp>
Across the province, the real estate commission ranges from 3.5% to 5% of the selling price. A buyer’s commission is usually fixed at 2.5% of the commission. It is important to remember that the Real Estate Council of Ontario (RECO) does not allow a sliding commission scale where the percentage increases as the price increases.

The commission rate is not dependent on the time it takes to close the deal. As a seller, you cannot pay less commission to the real estate agent if your house sells faster. Similarly, a realtor cannot ask for higher commission if the property takes long to sell.
</Hidden>
<br/>
<strong>Taxes:</strong> In Ontario, sellers have to pay a Harmonized Sales Tax (HST) of 13% on the real estate commission. The HST is made up of an 8% provincial sales tax and a 5% federal sales tax.
  </p>,
  },
  {
    id: 3,
    question: "Who do you pay real estate commission to?",
    answer:
    
      <p><Hidden mdUp lgUp smUp xsUp>
        If you are a seller, you will not pay the sales commission to the real estate agent directly, nor does the agent have the right to ask you to. The real estate commission will be paid to the agency or brokerage and only upon completion of the sale, which is when the new owner takes legal possession of the property.</Hidden>The third phase of becoming a real estate agent in Ontario is the Post-Registration Phase. During this phase, you will complete your “articling” period of two years while continuing to take mandatory courses of up to 50 hours. These courses will teach you all about real estate law and commercial transactions.
       The standard practice is that the seller’s real estate lawyer will deduct the commission amount from the money received from the buyer on closing, and then pay the agency directly according to the contract. The brokerage then pays the listing agent and the buyer’s agent.

      </p>,
  },
  {
    id: 4,
    question: "Is the real estate commission negotiable?",
    answer:
    
      <p><Hidden mdUp lgUp smUp xsUp>
     As there isn’t a standard commission rate, the real estate agent or agency will set their own rates when selling your house. Similar to other service fees, a realtor’s commission can also be negotiated between the seller and the real estate agency.
        </Hidden>
        This, however, depends on the property, the market and the agency involved. Sometimes the agency can agree to lower the commission if the seller is an old customer or conducts multiple transactions.
      <Hidden mdUp lgUp smUp xsUp>
      If the agency offers more services than its competitors it can ask for a higher commission. Keep in mind that if you pay a higher commission to your agent, they will be able to advertise and market your house better with the extra money. Since the seller will pay the commission for the buyer’s agents as well, the buyer cannot negotiate the sales commission.
      </Hidden>
      </p>,
  },
  {
    id: 5,

    question: "What services are covered in a realtor’s commission?",
    answer:
    
      <p><Hidden mdUp lgUp smUp xsUp>
        You might be wondering whether hiring a realtor is worth the price of the sales commission. Research shows that realtors sell your house for a higher price than you would fetch selling it on your own. This is because realtors have access to MLS, the cooperative selling systems operated by real estate boards and associations in Canada.
       </Hidden>
       A real estate agent will also offer services that will save you time and increase the value of your house before you list it. Some of the services realtors offer include:
<ul><li> Performing a comparative market analysis to fix a market-competitive price

</li><li>Arranging for high quality photo shoots

</li><li>Preparing a comprehensive and SEO-friendly listing copy to attract buyers

</li><li>Providing staging guidance

</li><li>Conducting multiple professional property viewings

</li><li>Ensuring your house is listed on all major property search websites
</li><li>Assisting in reviewing and negotiating buyer offers</li><li> Hosting open houses on weekends to attract other realtors</li>
</ul>
<Hidden lgUp xsUp smUp mdUp>
<strong>Note:</strong> There are realtors known as 1% agents i.e. they charge a commission of 1% compared to the standard. However, they will not offer the same quantity and quality of services as a full-service agent with a higher commission.
</Hidden>
</p>,
  },
  
];
