import React, { useState } from "react";
import { Grid, Button, TextField, Container, Hidden  } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import HomeWebForm from "../home/HomeWebForm";

function InfoSection({
  xlUp,
  mdUp,
  mdDown,
  smDown,
  xsDown,
  setShowWaitListForm,
}) {
  return (
    <Grid container item xs={12} md={12} alignContent="center">
      <Grid item xs={12}>
        <Grid
          align="center"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: xlUp ? 80 : mdUp ? 60 : 40,
            lineHeight: 1,
            marginBottom: mdUp ? 24 : 12,
            fontWeight: 600,
          }}
        >
          Souqh for Realtors
        </Grid>
      </Grid>
     
      {/* <Grid item xs={12} md={6} style={{ marginTop: mdUp ? 20 : 10 }}>
      <TextField fullWidth placeholder="Your email"></TextField>
    </Grid> */}
    <Grid
     container
     item
     xs={12}
     md={12}
     align="center"
     alignContent="center"
     justifyContent="center">
       <Grid item container md= {8} xs={12}>
       <Grid md={6} sm={6} xs={12}>
       <Button
          style={{
            backgroundColor: "#F37C61",
            color: "#FFFFFF",
            textTransform: "none",
            borderRadius: mdUp ? 24 : 12,
            marginTop: smDown ? 20 : 0,
            marginBottom: smDown ? 20 : 10,
            // marginLeft: lgUp ? 20 : 0,
            fontSize: mdUp ? 20 : 14,
            padding: "8px 24px",
            marginRight: xsDown ? 24 : 10,
            marginLeft: xsDown ? 24 : 10,
            width:"95%"
           
          }}
          onClick={() => {
            /**Login Link */
            //window.open("https://app.souqh.ca/login", "_blank");
            //window.open("https://app.souqh.ca/login", "_blank");
          }}
        >
          <Grid className={WebStyles.hankrndFont}>Sign up now for free!</Grid>
        </Button>
       </Grid>
       <Grid md={6} sm={6} xs={12}>
       <Button
          style={{
            backgroundColor: "#F37C61",
            color: "#FFFFFF",
            textTransform: "none",
            borderRadius: mdUp ? 24 : 12,
            marginTop: smDown ? 20 : 0,
            marginBottom: smDown ? 20 : 10,
            fontSize: mdUp ? 20 : 14,
            padding: "8px 24px",
            marginRight: xsDown ? 24 : 10,
            marginLeft: xsDown ? 24 : 10,
            width:"95%"
          }}
          onClick={() => {
            
            //window.open("/service-providers", "_blank");
            window.open("/service-providers", "_blank");
          }}
        >
          <Grid className={WebStyles.hankrndFont}>Learn More</Grid>
        </Button>
       </Grid>
       </Grid>
     </Grid>
      
    </Grid>
  );
}

function ImageSection({
  xlUp,
  mdUp,
  mdDown,
  smDown,
  xsDown,})
  {
  return (
    
    <Grid item xs={12} md={12}
    style={{
      
      marginTop: mdUp ? 24 : 12,
    }}
    >
      {
        <iframe width="100%" height="450" src="https://www.youtube.com/embed/xdh48tFm4dM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      /* <img
       src="/images/real_estate_agent/good-agent.jpg"
        style={{
          // height: xlUp ? 625 : mdUp ? 313 : 219,
          width: "100%",
        }}
      /> */}
    </Grid>
  );
}

function IntroductionRealtor({
  smUp,
  smDown,
  mdUp,
  mdDown,
  lgUp,
  xlUp,
  commonPadding,
}) {
  const [showWaitListForm, setShowWaitListForm] = useState(false);
  const handleClose = () => {
    setShowWaitListForm(false);
  };
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        // backgroundImage: lgUp
        //   ? "url('/images/home_buyers/Green patch.svg')"
        //   : "none",
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "10%",
        padding: commonPadding,
      }}
    >

      <Container>
        <Container>
            
              <Grid container direction="row">
                <InfoSection
                  xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown}

                />
                <ImageSection xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown} />
              </Grid>
            

           </Container>
      </Container>
    </Grid>
  );
}

export default IntroductionRealtor;
