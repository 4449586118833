import logo from "./logo.svg";
import "./App.css";
import MainLayout from "./components/MainLayout";
import { IntercomProvider } from "react-use-intercom";
const INTERCOM_APP_ID = "v50av2d9";
function App() {
  return (
    <div className="App">
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <MainLayout></MainLayout>
      </IntercomProvider>
    </div>
  );
}

export default App;
