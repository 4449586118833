import React from "react";
import { Grid, Container } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import FeatureItem from "./FeatureItem";
import clsx from "clsx";

function Features({ smUp, smDown, mdUp, lgUp, lgDown, xlUp, commonPadding }) {
  const features = [
    {
      name: "Ditch the stress",
      color: "#72cac9",
      image: "/images/home_buyers/Illustration 14.svg",
      description:
        "Buying a home can be a stressful life decision - we designed Souqh to help eliminate some of that stress. We are the only end-to-end digital services platform that provides digital guidance and access to a trusted service provider network, all in one place. Buying a home has never been easier with Souqh",
    },
    {
      name: "Trusted Service Providers",
      color: "#72cac9",
      image: "/images/home_buyers/Trusted Service Providers.svg",
      description:
        "Worried about the right fit? Souqh helps you find trusted service providers across a pool of storefronts that match your unique needs, along with verified ratings and testimonials from home buyers just like you.",
    },
    {
      name: "Streamlined Documents",
      color: "#F37C61",
      image: "/images/home_buyers/Illustartion 15.svg",
      description:
        "We know it can be frustrating to manage all the documents required throughout your home buying journey. Souqh streamlines all your documents in a simple and secure portal, allowing you to create, share, e-sign and upload documents directly to service providers.",
    },
    {
      name: "Communicate Freely",
      color: "#F37C61",
      image: "/images/home_buyers/Illustration 16.svg",
      description:
        "Tired of calling, texting and overflown inboxes with missed attachments? Souqh simplifies all communications with service providers, right on the platform, with easy access and interaction history.",
    },
    {
      name: "Earn Credits",
      color: "#B872AF",
      image: "/images/home_buyers/Illustartion 17.svg",
      description:
        "Every time you interact on Souqh or refer a service provider, you earn credits that can be used for future services. It pays to transact on Souqh!",
    },
    {
      name: "Rest Easy",
      color: "#B872AF",
      image: "/images/home_buyers/Illustration 18.svg",
      description:
        "Rest easy knowing that you completely own and control your data. Our enterprise-grade cloud security, which runs on industry-leading Amazon cloud, protects your data using 256-bit SSL/TLS encryption.",
    },
  ];
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        backgroundImage: lgUp
          ? "url('/images/home_buyers/Purple patch.svg'), url('/images/home_buyers/orange patch_right_2.svg')"
          : "none",
        backgroundRepeat: "no-repeat, no-repeat, no-repeat",
        backgroundSize: "10%, 7%",
        backgroundPosition: "0% 50%, 100% 35%",
        marginTop: lgUp && 48,
        padding: commonPadding,
      }}
    >
      <Container>
        <Container>
          <Grid item xs={12} lg={12} container style={{ paddingBottom: 40 }}>
            <Grid
              item
              xs={12}
              style={{
                marginBottom: 48,
              }}
            >
              <Grid
                align="center"
                className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
                style={{
                  fontSize: mdUp ? 40 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                }}
              >
                So many great features to help your journey
              </Grid>
              <Grid
                align="center"
                className={clsx(WebStyles.paraBlackText, WebStyles.nunitoFont)}
                style={{
                  fontSize: mdUp ? 28 : 14,
                  lineHeight: mdUp ? "38px" : "19px",
                  marginBottom: mdUp ? 12 : 8,
                }}
              >
                The complete home buying journey is right at your fingertips,
                anywhere, anytime.
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {features.map((item, index) => {
                return (
                  <FeatureItem
                    key={index}
                    smUp={smUp}
                    mdUp={mdUp}
                    feature={item}
                    isEven={index % 2 === 0}
                    lgUp={lgUp}
                    lgDown={lgDown}
                    index={index}
                    smDown={smDown}
                  />
                );
              })}
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}

export default Features;
