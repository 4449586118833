import { Container, Grid, Switch } from "@material-ui/core";
import React, { useState } from "react";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import PricingBox from "./PricingBox";
import { Carousel } from "react-responsive-carousel";

export default function Pricing({
  smUp,
  mdUp,
  lgUp,
  mdDown,
  smDown,
  commonPadding,
}) {
  const [planType, setPlanType] = useState(false);

  const height = lgUp ? 420 : 360;
  const radius = height / 2;
  const topFactor = lgUp ? "-70%" : "-50%";
  const boxesData = [
    {
      name: "Pro",
      image: "/images/service_providers/Pro.svg",
      amountMonthly: "49",
      amountAnnually: "490",
      fraction: ".83",
      envelopes: 25,
      ticks: 6,
      saving: 100,
    },
    {
      name: "Plus",
      image: "/images/service_providers/Plus.svg",
      amountMonthly: "79",
      amountAnnually: "790",
      fraction: ".83",
      envelopes: 50,
      ticks: 8,
      saving: 160,
    },
    {
      name: "Premium",
      image: "/images/service_providers/Premium.svg",
      amountMonthly: "99",
      amountAnnually: "990",
      fraction: ".50",
      envelopes: 100,
      ticks: 12,
      saving: 200,
    },
  ];

  const pricingBoxes = boxesData.map((item, index) => {
    return (
      <Grid
        key={index}
        item
        container
        xs={12}
        lg={4}
        style={{
          justifyContent: "center",
          marginBottom: 32,
        }}
      >
        {lgUp ? (
          <div
            style={{
              position: "absolute",
              backgroundColor: "#72cac9",
              height: height,
              left: -2000,
              right: "-12%",
              bottom: 0,
              top: "30%",
              zIndex: -1,
              borderTopRightRadius: lgUp ? radius : "0px",
              borderBottomRightRadius: lgUp ? radius : "0px",
              transform: "rotate(10deg)",
              transformOrigin: "center",
              opacity: 0.2,
            }}
          ></div>
        ) : null}
        <PricingBox
          key={index}
          data={item}
          smUp={smUp}
          mdUp={mdUp}
          lgUp={lgUp}
          smDown={smDown}
          mdDown={mdDown}
          planType={planType}
        />
      </Grid>
    );
  });
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: commonPadding,
        marginTop: mdUp ? 48 : 16,
      }}
    >
      <Container>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <Grid
                className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
                style={{
                  fontSize: mdUp ? 60 : 30,
                  lineHeight: mdUp ? "74px" : "32px",
                  marginBottom: 16,
                  textAlign: "left",
                  fontWeight: 600,
                }}
              >
                Pricing
              </Grid>
            </Grid>
            <Grid
              item
              container
              xs={12}
              className={clsx(WebStyles.paraBlackText, WebStyles.nunitoFont)}
              style={{
                fontSize: mdUp ? 28 : 14,
                lineHeight: mdUp ? "38px" : "19px",
                textAlign: "left",
                marginBottom: mdUp ? 32 : 16,
                alignItems: "flex-start",
              }}
            >
              <Grid item container xs={12} md={12} lg={8}>
                <Grid item xs={10} md={7} lg={12}>
                  Find the right plan for your needs. Try it now, risk-free!
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={12}
                lg={4}
                style={{ alignItems: "center", justifyContent: "flex-end" }}
              >
                <Grid
                  style={{
                    marginRight: 8,
                    fontWeight: planType ? "500" : "600",
                  }}
                >
                  Monthly
                </Grid>
                <Switch
                  className={"sqGraySwitch"}
                  checked={planType}
                  onChange={(event) => {
                    setPlanType(event.target.checked);
                  }}
                />
                <Grid
                  style={{
                    marginLeft: 8,
                    fontWeight: planType ? "600" : "500",
                  }}
                >
                  Annually
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          className={"pricing-carousel"}
          style={{ position: "relative", zIndex: 1 }}
        >
          {lgUp ? (
            pricingBoxes
          ) : (
            <Carousel transitionTime={3000} autoPlay={false} showThumbs={false}>
              {pricingBoxes}
            </Carousel>
          )}
        </Grid>
      </Container>
    </Grid>
  );
}
