import React, { useState } from "react";
import { Grid, Button, TextField, Container, Hidden  } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";

function SectionThree({
  xlUp,
  mdUp,
  smUp,
  xsUp,
  mdDown,
  smDown,
  xsDown,
  setShowWaitListForm,
}) {
  return (
    <Container>
      <Container>
    <Grid container item xs={12}  md={12} alignContent="center" style={{
     paddingLeft: mdUp ? "10%" : smUp ? "5%" : "0",
       paddingRight: mdUp? "10%" : smUp ? "5%" : "0"
    }}>
      <Grid item xs={12}>
        <Grid
          align="left"
          className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 50 : 30,
           
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
            marginTop : mdUp ? 12:8,
          }}
        >
          In-depth market knowledge
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
     
         <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
        There is nothing that can really replace knowledge. The more thorough it is, the better.
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} md={4} style={{
          //paddingLeft: mdUp ? "2%" : smUp ? "1%" : "0",
          padding: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ?  35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
          Clients
        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
             lineHeight: 1.5,
            fontWeight: 600,
            marginBottom: xlUp ? 20 : mdUp ? 15 : 10,
          }}
        >
  A good real estate agent is well-versed in the trends of the local market and the layout of the particular neighbourhood they operate in.
  <Hidden mdUp lgUp smUp xsUp>
  A realtor with in-depth local knowledge can also attract clientele based on the perks of the location, such as proximity to local cafes, shops, markets, restaurants, offices, wildlife, and urban hotspots. Another point to consider is that real estate agents familiar with intricacies of the neighbourhood can also help you avoid shady areas and save you money and headaches in the long run.
  </Hidden>
</Grid>
         <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 18 : 16,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
     If they also know the locals they can advise you on the type of issues that might arise in that particular area and how much it would cost to fix them.
        </Grid> 
        </Grid>
        <Grid item xs={12} md={4} style={{
          padding: mdUp ? "2%" : smUp ? "1%" : "0",
          //paddingRight: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
          Market share
        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
             lineHeight: 1.5,
            fontWeight: 600,
            marginBottom: xlUp ? 20 : mdUp ? 15 : 10,
          }}
        >
      The market share and penetration of your company in the market: This lets you understand the reputation and profit margins of your company.

</Grid>
<Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 18 : 16,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
     Increasing market share would drive more traffic to your website, attract more clients, and help build a name for yourself and your company.   </Grid> 
        </Grid>
        <Grid item xs={12} md={4} style={{
          padding: mdUp ? "2%" : smUp ? "1%" : "0",
          //paddingRight: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
          Performance of local competitor
        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
             lineHeight: 1.5,
            fontWeight: 600,
            marginBottom: xlUp ? 20 : mdUp ? 15 : 10,
          }}
        >
     This will help you gauge how many agents you are competing with, their experience levels
     </Grid>
        
<Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 18 : 16,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
     how you rank against other agents in your area   
     </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} md={4} style={{
          //paddingLeft: mdUp ? "2%" : smUp ? "1%" : "0",
          padding: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ?  35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
          Demanding real estate services
        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
             lineHeight: 1.5,
            fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
 shift your focus and invest in marketing specific services that are more lucrative
</Grid>
     
        </Grid>
        <Grid item xs={12} md={4} style={{
          padding: mdUp ? "2%" : smUp ? "1%" : "0",
          //paddingRight: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
          Sales data
        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
             lineHeight: 1.5,
            fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
    Understand the factors fueling or stalling the market, and whether you are dealing with a buyers’ or sellers’ market.
      <Hidden mdUp smUp lgUp xsUp>You’ll be capable of managing clients’ expectations better and save time</Hidden>
</Grid>
        
        </Grid>
        <Grid item xs={12} md={4} style={{
          padding: mdUp ? "2%" : smUp ? "1%" : "0",
          //paddingRight: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
                  
            fontWeight: 600,
          }}
        >
          Community demographic
        </Grid>
        {/* <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
             lineHeight: 1.5,
            fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
      They determine the value of properties. Appraisers need to know acceptable principles of appraisal and related information
      <Hidden mdUp lgUp xsUp smUp>
      They need to have practical experience, technical education, good judgement and some knowledge of mathematics, accounting and economics.
      </Hidden>
</Grid> */}
        
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} md={4} style={{
          //paddingLeft: mdUp ? "2%" : smUp ? "1%" : "0",
          padding: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ?  35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
                  
            fontWeight: 600,
          }}
        >
          Economic, social, political, and environmental data
        </Grid>
        {/* <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
             lineHeight: 1.5,
            fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
 shift your focus and invest in marketing specific services that are more lucrative
</Grid> */}
     
        </Grid>
        </Grid>
      {/* <Grid item xs={12} md={6} style={{ marginTop: mdUp ? 20 : 10 }}>
      <TextField fullWidth placeholder="Your email"></TextField>
    </Grid> */}
    <Hidden mdUp lgUp smUp xsUp>
    <strong>Connections in the industry
    </strong>
    A network of connections and contacts in the industry is an essential quality in successful real estate agents. Whether you are buying or selling property, there are a lot of issues you need to keep in mind. You’ll most likely be contacting different contractors for projects like landscaping, painting, plumbing, and home staging. Rather than digging through reviews for every service you’re looking for, your agent can put you in touch with their network of qualified contractors and industry experts. If they have a good rapport with contractors and dealers you might be able to get unexpected discounts, too.

Beginners in the real estate business need to remember that strong local connections will help you build an authentic network that drives leads. This is how you can grow your client base organically. With strategic networking you also have the opportunity to meet other local business people who can refer clients.
</Hidden>




    </Grid>
    </Container>
    </Container>
  );
}



// function Introduction({
//   smUp,
//   smDown,
//   mdUp,
//   mdDown,
//   lgUp,
//   xlUp,
//   commonPadding,
// }) {
//   const [showWaitListForm, setShowWaitListForm] = useState(false);
//   const handleClose = () => {
//     setShowWaitListForm(false);
//   };
//   return (
//     <Grid
//       item
//       container
//       xs={12}
//       style={{
//         backgroundImage: lgUp
//           ? "url('/images/home_buyers/Green patch.svg')"
//           : "none",
//         backgroundRepeat: "no-repeat",
//         backgroundSize: "10%",
//         padding: commonPadding,
//       }}
//     >

//       <Container>
//         <Container>
//           <Grid container>
          
//               <Grid container direction="row">
//                 <SectionTwo
//                   xlUp={xlUp}
//                   mdUp={mdUp}
//                   mdDown={mdDown}
//                   smDown={smDown}

//                 />
                
//               </Grid>
          

//           </Grid>
//         </Container>
//       </Container>
//     </Grid>
//   );
// }

export default SectionThree;
