import { useMediaQuery, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import SubFooter from "../SubFooter";
import HomeJournerySimplified from "./HomeJournerySimplified";
import BusinessFeatures from "./BusinessFeatures";
import ExploreServiceProvider from "./ExploreServiceProvider";
import MoreGreatFeatures from "./MoreGreatFeatures";
import HomeBuyingFuture from "./HomeBuyingFuture";
import ReviewCarousel from "./ReviewCarousel";
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions";
import LatestUpdates from "./LatestUpdates";
import CompanyLogos from "../serviceProviders/CompanyLogos";
import ExploreSP from "./ExploreSP";

function HomePage() {
  const [selectedTab, setselectedTab] = useState(0);
  const title = "Ditch the stress. Join for free!";
  const subTitle =
    "We designed Souqh to help eliminate the stress in the home buying journey.";
  const btnText = "Sign up now for free!";
  const redirectUrl = "https://app.souqh.ca/";

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  // const commonPadding = lgUp ? "0px 10%" : "0px 2%";
  const commonPadding = 0;

  return (
    <div style={{ paddingBottom: 32, overflow: "hidden" }}>
      <ExploreSP lgUp={lgUp} mdUp={mdUp} xlUp={xlUp} />
      <HomeJournerySimplified
        smUp={smUp}
        smDown={smDown}
        mdUp={mdUp}
        mdDown={mdDown}
        lgUp={lgUp}
        xlUp={xlUp}
        commonPadding={commonPadding}
        selectedTab={selectedTab}
        setselectedTab={setselectedTab}
      ></HomeJournerySimplified>
      <ExploreServiceProvider
        selectedTab={selectedTab}
        smDown={smDown}
        mdUp={mdUp}
        mdDown={mdDown}
        lgUp={lgUp}
        lgDown={lgDown}
        xlUp={xlUp}
        commonPadding={commonPadding}
        smUp={smUp}
      ></ExploreServiceProvider>
      <BusinessFeatures
        smDown={smDown}
        mdUp={mdUp}
        lgUp={lgUp}
        lgDown={lgDown}
        xlUp={xlUp}
        smUp={smUp}
        commonPadding={commonPadding}
      ></BusinessFeatures>
      <MoreGreatFeatures
        smUp={smUp}
        mdUp={mdUp}
        lgUp={lgUp}
        xsDown={xsDown}
        commonPadding={commonPadding}
      ></MoreGreatFeatures>
      <HomeBuyingFuture
        mdUp={mdUp}
        lgUp={lgUp}
        smUp={smUp}
        smDown={smDown}
        commonPadding={commonPadding}
      ></HomeBuyingFuture>
      <ReviewCarousel
        smDown={smDown}
        mdUp={mdUp}
        mdDown={mdDown}
        lgUp={lgUp}
        lgDown={lgDown}
        xlUp={xlUp}
        commonPadding={commonPadding}
      ></ReviewCarousel>
      <CompanyLogos
        style={{ marginTop: xlUp ? 100 : mdUp ? 50 : 20 }}
        title={"Who is using Souqh"}
        smUp={smUp}
        mdUp={mdUp}
        mdDown={mdDown}
        lgUp={lgUp}
        lgDown={lgDown}
        xsDown={xsDown}
        commonPadding={commonPadding}
      />
      <FrequentlyAskedQuestions
        mdUp={mdUp}
        smUp={smUp}
        lgDown={lgDown}
        commonPadding={commonPadding}
        xlUp={xlUp}
      ></FrequentlyAskedQuestions>
      <LatestUpdates
        commonPadding={commonPadding}
        mdUp={mdUp}
        lgUp={lgUp}
      ></LatestUpdates>
      <div style={{ height: 100 }}></div>
      <SubFooter
        title={title}
        subTitle={subTitle}
        btnText={btnText}
        redirectUrl={redirectUrl}
      />
    </div>
  );
}

export default HomePage;
