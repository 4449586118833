import { Hidden } from "@material-ui/core";

export const HB_QUESTION_ANSWERS = [
  {
    id: 1,
    question: "Clients",
    answer:
    <p>Does your realtor understand your vision? Are they open to new ideas and constructive criticism? You should be able to clearly convey your wants, needs, budget, and any financial constraints without hesitation. Your real estate agent should be like a friend but also a professional business partner.</p>
  },
  {
    id: 2,
    question: "Future realtors",
    answer:<p>
    Perhaps the most crucial trait you need to develop is the ability to listen. Listen to your clients and try to understand their vision so you can figure out how to make it a reality. Be receptive to new ideas and accept criticism where it is valid. Polish your writing skills as well to appear more professional and deliver your point across to all types of clients. Most important of all, build your interpersonal communication skills with clients and other realtors. Connecting with people on a personal level builds bonds, fosters trust, and ensures the connection becomes permanent.</p>,
  },
  
];
