import React, { useState } from "react";
import { Grid, Button, TextField, Container, Hidden  } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import HomeWebForm from "../home/HomeWebForm";

function InfoSection({
  xlUp,
  mdUp,
  mdDown,
  smDown,
  xsDown,
  setShowWaitListForm,
}) {
  return (
    <Grid container item xs={12} md={8} alignContent="center">
      <Grid item xs={12}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: xlUp ? 80 : mdUp ? 60 : 40,
            lineHeight: 1,
            marginBottom: mdUp ? 24 : 12,
            fontWeight: 600,
          }}
        >
         What do real estate agents charge to sell your house
        </Grid>
      </Grid>
      <Grid item xs={12} md={10}>
         <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
          The decision to sell your house is a significant, and quite often life-changing, one.
           <Hidden xsUp mdUp xlUp smUp> 
           Putting any kind of property out on the market is already stressful; when it is the place you’ve lived in for quite some time, there’s also an emotional toll. With so many factors involved that can go awry, it is important that the sale itself goes smoothly.
           </Hidden>
        <br/>
        To save yourself the hassle, you need to <a href="https://souqh.ca/">find the right real estate agent for the job.</a> A realtor will help you enter the market at the right time, analyze market trends and ensure that you are selling your property at a good price
        <br/>
        <Hidden xsUp mdUp xlUp smUp> 
        As is the case with any high-quality professional, good realtors and brokers don’t come cheap. After all, you need to remember that the only way they earn a living is through a successful deal. To sell your house, real estate agents will charge a commission which will be a percentage of the price of your house, should the deal be successful. A realtor does not get paid wages by the hour or week unlike other professionals.
        </Hidden>
        </Grid>
      </Grid>
      
      <Grid
        container
        item
        xs={12}
        md={7}
        alignContent="center"
        justify={mdDown && "flex-end"}
      >
        <Button
          style={{
            backgroundColor: "#F37C61",
            color: "#FFFFFF",
            textTransform: "none",
            borderRadius: mdUp ? 24 : 12,
            marginTop: smDown ? 20 : 0,
            // marginLeft: lgUp ? 20 : 0,
            fontSize: mdUp ? 20 : 14,
            padding: "8px 24px",
            marginRight: xsDown && 24,
            marginBottom : 20,
          }}
          onClick={() => {
            // setShowWaitListForm(true);
            window.open("https://app.souqh.ca/homebuyersignup", "_blank");
          }}
        >
          <Grid className={WebStyles.hankrndFont}>Sign up now for free!</Grid>
        </Button>
      </Grid>
    </Grid>
  );
}

function ImageSection({
  xlUp,
  mdUp,
  mdDown,
  smDown,
  xsDown,})
  {
  return (
    
    <Grid item xs={12} md={4}
    style={{
      
      marginTop: mdUp ? 24 : 12,
    }}
    >
      {
        //<iframe width="100%" height="450" src="https://www.youtube.com/embed/xdh48tFm4dM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <img
       src="/images/home_page/Illustration 22.svg"
        style={{
           //height: xlUp ? 625 : mdUp ? 313 : 219,
          width: "100%",
        }}
      /> }
    </Grid>
  );
}

function Introduction({
  smUp,
  smDown,
  mdUp,
  mdDown,
  lgUp,
  xlUp,
  commonPadding,
}) {
  const [showWaitListForm, setShowWaitListForm] = useState(false);
  const handleClose = () => {
    setShowWaitListForm(false);
  };
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        backgroundImage: lgUp
          ? "url('/images/home_buyers/Green patch.svg')"
          : "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "10%",
        padding: commonPadding,
      }}
    >

      <Container>
        <Container>
          <Grid container>
            {mdUp ? (
              <Grid container direction="row">
                <InfoSection
                  xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown}

                />
                <ImageSection xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown} />
              </Grid>
            ) : (
              <Grid container direction="row">
                <ImageSection  xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown} />
                <InfoSection
                  xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown}
                />
              </Grid>
            )}


          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}

export default Introduction;
