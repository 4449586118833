import React, { useState } from "react";
import { Grid, Button, TextField, Container, Hidden  } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import HomeWebForm from "../home/HomeWebForm";

function SectionTwo({
  xlUp,
  mdUp,
  smUp,
  xsUp,
  mdDown,
  smDown,
  xsDown,
  setShowWaitListForm,
}) {
  return (
    <Container>
      <Container>
    <Grid container item xs={12}  md={12} alignContent="center" style={{
     paddingLeft: mdUp ? "10%" : smUp ? "5%" : "0",
       paddingRight: mdUp? "10%" : smUp ? "5%" : "0"
    }}>
      <Grid item xs={12}>
        <Grid
          align="left"
          className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 50 : 30,
           
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
            marginTop : mdUp ? 12:8,
          }}
        >
          Why Souqh Is The Right Place To Find Experienced Local Real Estate Lawyers

        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
     
         <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >

            <Hidden xsUp mdUp xlUp smUp>         
            To practise as a real estate lawyer, it is the law in most areas that you must have the right qualifications and license. But that still does not mean you can pick any lawyer and expect a specific level of expertise, experience, and professionalism.
<br/>
Some real estate lawyers will be better than others. And therein lies the challenge for prospective homebuyers looking for a capable lawyer to represent their interests. Souqh makes sure you don’t just take their word for it.

        </Hidden>
        <br/>
        By reviewing storefronts that real estate lawyers and other service providers maintain on Souqh, you can check how many years they have been in business. If that does not tell you enough about the content of their experience, maybe checking reviews left by their previous will.

        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} md={4} style={{
          //paddingLeft: mdUp ? "2%" : smUp ? "1%" : "0",
          padding: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ?  35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
          Easily connect, safely share documents, and corroborate information

        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 20 : 18,
             lineHeight: 1.5,
            //fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
          <Hidden xsUp xlUp lgUp mdUp smUp>
          If after checking their reviews and years in business you are still unsure about a specific lawyer, you can call or chat with them directly from within their store and ask them to fill in the gaps. Meanwhile, you are free to review and talk to other lawyers within Souqh, which is how we make it easy to compare quotes. 

          </Hidden>
  After it all comes together and you hire a real estate lawyer through Souqh, our platform makes it super easy to collaborate, share and sign documents, and send payments. It’s a safe place to do business where your personal information and documents are safely stored and only accessible to you.
 

</Grid>
        {/* <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 18 : 16,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
       For instance, the realtor was given incorrect information from a previous realtor or the homeowner and passed this on to the client. This is grounds to sue, however, you’re most likely to have only the damages compensated financially.
        </Grid> */}
        </Grid>
      </Grid>
     
   
    
    </Grid>
    </Container>
    </Container>
  );
}



// function Introduction({
//   smUp,
//   smDown,
//   mdUp,
//   mdDown,
//   lgUp,
//   xlUp,
//   commonPadding,
// }) {
//   const [showWaitListForm, setShowWaitListForm] = useState(false);
//   const handleClose = () => {
//     setShowWaitListForm(false);
//   };
//   return (
//     <Grid
//       item
//       container
//       xs={12}
//       style={{
//         backgroundImage: lgUp
//           ? "url('/images/home_buyers/Green patch.svg')"
//           : "none",
//         backgroundRepeat: "no-repeat",
//         backgroundSize: "10%",
//         padding: commonPadding,
//       }}
//     >

//       <Container>
//         <Container>
//           <Grid container>
          
//               <Grid container direction="row">
//                 <SectionTwo
//                   xlUp={xlUp}
//                   mdUp={mdUp}
//                   mdDown={mdDown}
//                   smDown={smDown}

//                 />
                
//               </Grid>
          

//           </Grid>
//         </Container>
//       </Container>
//     </Grid>
//   );
// }

export default SectionTwo;
