import React, { useEffect } from "react";
import Section1 from "./Section1";
import { useMediaQuery, useTheme } from "@material-ui/core";
import SubFooter from "../SubFooter";

function ContactUsPage() {
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const commonPadding = lgUp ? "0px 10%" : "0px 2%";

  const title = "Why Wait?";
  const subTitle =
    "We designed Souqh to help eliminate the stress in the home journey.";
  const btnText = "Sign up now for free!";
  const redirectUrl = "https://app.souqh.ca";
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div
      style={{
        position: "relative",
        marginBottom: 100,
        top: smUp ? (lgUp ? 65 : 60) : 78,
      }}
    >
      <Section1
        smDown={smDown}
        mdUp={mdUp}
        lgUp={lgUp}
        // commonPadding={commonPadding}
      ></Section1>
      <SubFooter
        title={title}
        subTitle={subTitle}
        btnText={btnText}
        redirectUrl={redirectUrl}
      />
    </div>
  );
}

export default ContactUsPage;
