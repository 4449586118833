import React, { useEffect } from "react";
import { Hidden, useMediaQuery, useTheme } from "@material-ui/core";
import SubFooter from "../SubFooter";
import ServicesLayout from "../ServicesLayout";

function MortgageBrokerLists() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const xsUp = useMediaQuery(theme.breakpoints.up("xs"));
  const title = "Get Started with Souqh";
  const subTitle =
    "If you are looking for the best realtors in your area anywhere in Canada, Souqh has the solution for you. Souqh is a marketplace for real estate service providers, from realtors, real estate lawyers, to contractors.";
  const btnText = "Sign Up Now!";
  const redirectUrl = "";

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const SP_QUESTION_ANSWERS = [];

  const HB_QUESTION_ANSWERS = [
    {
      id: 1,
      question: "Can A Mortgage Broker Get You Pre-Approved?",
      //answer:
      //"We know from our own experience how overwhelming and stressful the home journey can be and we were determined to make the process easier. Souqh is Canada’s only end-to-end digital services marketplace who supports home buyers looking for guidance with their home buying journey. Finding a trusted service provider, such as a realtor, mortgage broker, lawyer, or general contractor, has never been easier. From verified reviews, to documentation that is easy to access and locate all in one place, Souqh simplifies your home journey. And the best part? It’s all free!",
      link: "/reapproved-by-mortgage-broker",
    },
    {
      id: 2,
      question:
        "6 Digital Hacks For Mortgage Brokers To Stand Out In A Competitive Market",
      link: "https://blog.souqh.ca/6-digital-hacks-for-mortgage-brokers-to-stand-out-in-a-competitive-market/",
    },
  ];

  const features = [
    {
      name: "Skip the steep learning curve",
      color: "#72cac9",
      image: "/images/home_buyers/Illustration 14.svg",
      description:
        "A mortgage broker, on the other hand, knows the industry inside out. They can immediately match you with the right lender, saving you time and money in unnecessary expenses.",
    },
    {
      name: "Access exclusive deals and lower rates",
      color: "#72cac9",
      image: "/images/home_buyers/Trusted Service Providers.svg",
      description:
        " Having carefully studied your specific circumstances, including your credit history, a mortgage broker can call on connections they have built over time to negotiate the best terms and lowest possible rate for you.",
    },
    {
      name: "Get help filling documents",
      color: "#F37C61",
      image: "/images/home_buyers/Illustartion 15.svg",
      description:
        "Finding the right lender to finance your home isn’t the only benefit of using a mortgage broker. They will also take care of all the documentation you need to fill out and submit.",
    },
    {
      name: "Using a mortgage broker does not cost you any money",
      color: "#B872AF",
      image: "/images/home_buyers/Illustartion 17.svg",
      description:
        "Using a mortgage broker to finance your home purchase is a no-brainer. Between the free personalized advice and the lower rates, you get a done-for-you mortgage loan service.",
    },
  ];

  return (
    <div
      style={{
        overflow: "hidden",
        position: "relative",
        top: smUp ? (lgUp ? 55 : 60) : 78,
        marginBottom: 105,
      }}
    >
      <ServicesLayout
        smUp={smUp}
        mdUp={mdUp}
        lgUp={lgUp}
        lgDown={lgDown}
        xsUp={xsUp}
        HB_QUESTION_ANSWERS={HB_QUESTION_ANSWERS}
        SP_QUESTION_ANSWERS={SP_QUESTION_ANSWERS}
        features={features}
        btnText="Mortgage Broker"
        title="Mortgage Broker Services"
        serviceFeatureSubTitle="Find the right mortgage broker for your needs and leverage their experience and connections to access exclusive deals and discounts and save time. The top mortgage brokers in Canada, all on one platform."
        serviceName="Mortgage Broker / Agent"
      >
        {/* <Hidden xsUp lgUp mdUp xlUp smUp> */}
        A mortgage broker can get you pre-approved for a home loan only, but
        only in as far as they can submit the pre-approval application on your
        behalf. Ultimately it is the bank that pre-approves you for a mortgage.
        In fact, you have the option of submitting your request for your
        mortgage pre-approval directly at the bank or through a mortgage broker.
        <br />
        The benefit of using a broker for your mortgage pre-approval is they can
        help you get it faster. Since this is their day job, they know which
        information the lender requires and can submit it all the first time.
        They are likely to be more thorough and will know the exact steps and
        procedures for pre-approval, which saves time.
        <br />
        {/* </Hidden> */}
        <br />
        Mortgage brokers also know which lenders have the fastest pre-approval
        times, and the lowest rates. They’re not tied to any lender so they will
        recommend the lender that offers you the best rates and the smoothest
        home loan application process.
        <br />
        <br />
      </ServicesLayout>
      <SubFooter
        title={title}
        subTitle={subTitle}
        btnText={btnText}
        redirectUrl={redirectUrl}
      />
    </div>
  );
}

export default MortgageBrokerLists;
