import { Container, Grid, Hidden } from "@material-ui/core";
import React from "react";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";

function Section4({ smUp, mdUp, lgUp, commonPadding }) {
  const moreFeatures = [
    {
      name: "Reliable and trustable",
      description: <p>A good handyman is punctual and reliable. Just as important, they have to be honest with what they can and cannot do. Also, look for a handyman with a traceable record and not hire chancers looking for a quick buck. To protect yourself, do not work with a handyman who asks for payment upfront.
   </p>,
    },
    {
      name: "Safety-conscious",
      description:
      <p>Importantly, a good handyman does not compromise your family’s security and is conscious of yours and their safety. You are better off with your broken sink if the handyman you hire ends up putting your property’s security at risk. 
       <br/>   
      Your property’s security is important, and so is your family’s safety. So a good handyman should be skilled enough to know how to safely handle electricity as well as their equipment so that they don’t put your family’s safety at risk.
      </p>,
    },
    {
      name: "Tidy and thorough",
      description: <p>You are paying someone to do your home repairs because you want them done right the first time. So the handyman you hire has to be thorough. It is also not a good use of your money if you have to pay someone to come and clean up after the handyman is gone. 
        <br/>
      A good handyman is tidy and makes sure not to smear your walls and stain or dent your furniture as they work. They use the right equipment which they are always careful with and pack up after they are done. A handyman leaves your property looking better than they found it.
      </p>,

    },
    
  ];

  const firstThreeItems = moreFeatures.slice(0, 2);
  const lastThreeItems = moreFeatures.slice(2, 4);

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        backgroundImage: lgUp
          ? "url('/images/home_page/Purple strip.svg')"
          : "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "10%",
        padding: commonPadding,
        marginTop: 20,
      }}
    >
      <Container>
        <Container>
          <Grid container>
            <Grid
              item
              xs={12}
              className={clsx(WebStyles.purpleTxt, WebStyles.hankrndFont)}
              style={{
                fontSize: mdUp ? 40 : 20,
                fontWeight: 600,
                marginBottom: mdUp ? 20 : 10
              }}
            >
            What To Look For In A Handyman

            </Grid>
            
        
            <Grid 
              item
              xs={12}
              className={clsx(WebStyles.paraBlackText, WebStyles.nunitoFont)}
              style={{ marginBottom: mdUp ? 20 : 10, fontSize: mdUp ? 24 : 14, textAlign:"left" }}
            >
              
              A good handyman will have problem-solving skills. Whatever task you need help with around the home, you want to be confident that they will have a simple and effective fix. To do this, a handyman needs to be multi-skilled, among other qualities:


            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {firstThreeItems.map((item) => (
                <Grid
                  container
                  item
                  xs={12}
                  style={{ marginBottom: 24 }}
                  key={item.name}
                >
                  <Grid
                    item
                    xs={1}
                    style={{
                      backgroundColor: "#B872AF33",
                      height: lgUp ? 23 : 20,
                      maxWidth: lgUp ? 24 : 20,
                      borderRadius: "100%",
                      marginTop: lgUp ? 10 : 3,
                    }}
                  ></Grid>
                  <Grid
                    container
                    item
                    xs={11}
                    justify="flex-start"
                    style={{ paddingLeft: 20 }}
                  >
                    <Grid
                      item
                      xs={12}
                      className={clsx(
                        WebStyles.purpleTxt,
                        WebStyles.hankrndFont
                      )}
                      style={{
                        textAlign: "left",
                        fontSize: mdUp ? 28 : 14,
                        fontWeight: 600,
                      }}
                    >
                      {item.name}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={clsx(
                        WebStyles.paraBlackText,
                        WebStyles.nunitoFont
                      )}
                      style={{ textAlign: "left", fontSize: mdUp ? 20 : 10 }}
                    >
                      {item.description}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Hidden mdDown>
              <Grid item xs={4}>
                <img
                  src="/images/home_page/Illustration 22.svg"
                  style={{
                    height: 530,
                    // width: 300, marginTop: -51
                  }}
                />
              </Grid>
            </Hidden>
            <Grid item xs={12} md={6} lg={4}>
              {lastThreeItems.map((item) => (
                <Grid
                  container
                  item
                  xs={12}
                  style={{ marginBottom: 24 }}
                  key={item.name}
                >
                  <Grid
                    item
                    xs={1}
                    style={{
                      backgroundColor: "#B872AF33",
                      height: 23,
                      maxWidth: 24,
                      borderRadius: "100%",
                      marginTop: lgUp ? 10 : 0,
                    }}
                  ></Grid>
                  <Grid
                    container
                    item
                    xs={11}
                    justify="flex-start"
                    style={{ paddingLeft: 20 }}
                  >
                    <Grid
                      item
                      xs={12}
                      className={clsx(
                        WebStyles.purpleTxt,
                        WebStyles.hankrndFont
                      )}
                      style={{
                        textAlign: "left",
                        fontSize: mdUp ? 28 : 14,
                        fontWeight: 600,
                      }}
                    >
                      {item.name}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={clsx(
                        WebStyles.paraBlackText,
                        WebStyles.nunitoFont
                      )}
                      style={{ textAlign: "left", fontSize: mdUp ? 20 : 10 }}
                    >
                      {item.description}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}

export default Section4;
