import React, { useState } from "react";
import { Grid, Button, TextField, Container, Hidden  } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import HomeWebForm from "../home/HomeWebForm";

function InfoSection({
  xlUp,
  mdUp,
  mdDown,
  smDown,
  xsDown,
  setShowWaitListForm,
}) {
  return (
    <Grid container item xs={12} md={8} alignContent="center">
      <Grid item xs={12}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: xlUp ? 80 : mdUp ? 60 : 40,
            lineHeight: 1,
            marginBottom: mdUp ? 24 : 12,
            fontWeight: 600,
          }}
        >
          Handy Man Services
        </Grid>
      </Grid>
      <Grid item xs={12} md={10}>
         <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
           
        <br/>
        Whether you need a handyman in Toronto, Ottawa, Mississauga, Surrey, hamilton, Edmonton, or Guelph, Souqh is where you can get one that’s trustable and is closest to you. Find a handyman near you.
<br/>
<br/>
<Hidden xsUp mdUp xlUp smUp> 
You hire a handyman when you have home improvement tasks that you can’t do yourself but which are too small for a general contractor. Hiring a handyman allows you to take care of simple repairs before they develop into costlier, bigger problems. 

Handymen simplify home repair and maintenance, protecting your house’s value and curb appeal. To make sure the job is done right and at a reasonable cost, it is essential that you hire a local handyman whose credentials you can easily verify. 

Souqh, an online marketplace for service providers you need at every stage of your homeownership journey has made the process a local handyman easy and painless. We are the only one such platform in Canada that actually works.
<br/>

<strong>Common Handyman Services You Can Hire For Today
</strong>
<br/>
Handymen come in different forms. They will typically work alone and their skills often run the complete range of home improvement tasks. You could hire a small jobs handyman, a handyman plumber, a handyman painter, or - simply - a family handyman. 

Most handymen offer a broad range of home repair and maintenance services though some may specialize in specific tasks. They are usually self-trained but some have formal training. Among the simple home improvement tasks you can hire a handyman for are:
<ul>
<li>Assembling furniture,</li>
<li>Cleaning a clogged gutter,</li>
<li>Setting up your Christmas displays,</li>
<li>Setting up and connecting a new appliance,</li>
<li>Replacing rotted or broken fence or deck boards.</li>
<li>Painting a stained or scuffed wall or piece of furniture</li>
</ul>

        </Hidden>
        </Grid>
      </Grid>
      {/* <Grid item xs={12} md={6} style={{ marginTop: mdUp ? 20 : 10 }}>
      <TextField fullWidth placeholder="Your email"></TextField>
    </Grid> */}
    
    </Grid>
  );
}

function ImageSection({
  xlUp,
  mdUp,
  mdDown,
  smDown,
  xsDown,})
  {
  return (
    
    <Grid item xs={12} md={4}
    style={{
      
      marginTop: mdUp ? 24 : 12,
    }}
    >
      {
        //<iframe width="100%" height="450" src="https://www.youtube.com/embed/xdh48tFm4dM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <img
       src="/images/home_page/Illustration 22.svg"
        style={{
           //height: xlUp ? 625 : mdUp ? 313 : 219,
          width: "100%",
        }}
      /> }
    </Grid>
  );
}

function Introduction({
  smUp,
  smDown,
  mdUp,
  mdDown,
  lgUp,
  xlUp,
  commonPadding,
}) {
  const [showWaitListForm, setShowWaitListForm] = useState(false);
  const handleClose = () => {
    setShowWaitListForm(false);
  };
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        backgroundImage: lgUp
          ? "url('/images/home_buyers/Green patch.svg')"
          : "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "10%",
        padding: commonPadding,
      }}
    >

      <Container>
        <Container>
          <Grid container>
            {mdUp ? (
              <Grid container direction="row">
                <InfoSection
                  xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown}

                />
                <ImageSection xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown} />
              </Grid>
            ) : (
              <Grid container direction="row">
                <ImageSection  xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown} />
                <InfoSection
                  xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown}
                />
              </Grid>
            )}


          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}

export default Introduction;
