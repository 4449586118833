import React, { useState } from "react";
import { Grid, Button, TextField, Container, Hidden  } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import HomeWebForm from "../home/HomeWebForm";

function SectionTwo({
  xlUp,
  mdUp,
  smUp,
  xsUp,
  mdDown,
  smDown,
  xsDown,
  setShowWaitListForm,
}) {
  return (
    <Container>
      <Container>
    <Grid container item xs={12}  md={12} alignContent="center" style={{
     paddingLeft: mdUp ? "10%" : smUp ? "5%" : "0",
       paddingRight: mdUp? "10%" : smUp ? "5%" : "0"
    }}>
      <Grid item xs={12}>
        <Grid
          align="left"
          className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 50 : 30,
           
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
            marginTop : mdUp ? 12:8,
          }}
        >
          Why It’s Crucial To Get Pre-Approved
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
     
         <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >

            <Hidden xsUp mdUp xlUp smUp lgUp>    
            Obtaining a mortgage pre-approval demonstrates your seriousness to home sellers and real estate agents, some of whom will not even work with you without it. But there are other benefits to getting pre-approved.     
        </Hidden>
        <br/>
        
        Your home is one of your biggest investments, whose repair and maintenance you don’t want to entrust to chancers. The last thing you want to do is micro-manage your handyman because you are not sure if they know what they are doing.
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} md={4} style={{
          //paddingLeft: mdUp ? "2%" : smUp ? "1%" : "0",
          padding: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ?  35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
          Locks in your mortgage rate
        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 20 : 18,
             lineHeight: 1.5,
            //fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
  A pre-approval also locks in your mortgage rate for as long as four months. You can shop for your dream home for that long without worrying that you may end up getting an interest rate that is unsustainable for your financial position.

</Grid>
        {/* <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 18 : 16,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
       For instance, the realtor was given incorrect information from a previous realtor or the homeowner and passed this on to the client. This is grounds to sue, however, you’re most likely to have only the damages compensated financially.
        </Grid> */}
        </Grid>
        <Grid item xs={12} md={4} style={{
          padding: mdUp ? "2%" : smUp ? "1%" : "0",
          //paddingRight: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
          Protects your deposit

        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 20 : 18,
             lineHeight: 1.5,
            //fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
    It is a common practice that home sellers will ask for a deposit to gauge how serious you are as a buyer. Though this deposit is part of the purchase price, it will not be refunded if you don’t proceed with the purchase. A mortgage pre-approval helps to protect your deposit as it focuses your attention only on those properties you are likely to get a mortgage for.
</Grid>
        
        </Grid>
        <Grid item xs={12} md={4} style={{
          padding: mdUp ? "2%" : smUp ? "1%" : "0",
          //paddingRight: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
         Speeds up the actual loan approval

        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 20 : 18,
             lineHeight: 1.5,
          
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
          <Hidden mdDown xsDown lgDown xlDown smDown>
A mortgage pre-approval also cuts the time it takes for your mortgage application to be approved because you have already submitted all the required information.</Hidden><br/>

The beauty of using a mortgage broker for your mortgage pre-approval is they can obtain it from more than one lender. This way you can compare rates and terms and decide which one best serves your needs. And this is work that you don’t have to pay for as it is the lender that actually pays the mortgage broker in the end.
</Grid>
        
        </Grid>
      </Grid>
     
   
    
    </Grid>
    </Container>
    </Container>
  );
}



// function Introduction({
//   smUp,
//   smDown,
//   mdUp,
//   mdDown,
//   lgUp,
//   xlUp,
//   commonPadding,
// }) {
//   const [showWaitListForm, setShowWaitListForm] = useState(false);
//   const handleClose = () => {
//     setShowWaitListForm(false);
//   };
//   return (
//     <Grid
//       item
//       container
//       xs={12}
//       style={{
//         backgroundImage: lgUp
//           ? "url('/images/home_buyers/Green patch.svg')"
//           : "none",
//         backgroundRepeat: "no-repeat",
//         backgroundSize: "10%",
//         padding: commonPadding,
//       }}
//     >

//       <Container>
//         <Container>
//           <Grid container>
          
//               <Grid container direction="row">
//                 <SectionTwo
//                   xlUp={xlUp}
//                   mdUp={mdUp}
//                   mdDown={mdDown}
//                   smDown={smDown}

//                 />
                
//               </Grid>
          

//           </Grid>
//         </Container>
//       </Container>
//     </Grid>
//   );
// }

export default SectionTwo;
