import React from "react";
import { Grid, Container } from "@material-ui/core";
import WebStyles from "../WebStyles.module.css";
import ServicesFeatureItem from "./ServicesFeatureItem";
import clsx from "clsx";

function ServicesFeatures({
  smUp,
  smDown,
  mdUp,
  lgUp,
  lgDown,
  xlUp,
  commonPadding,
  features,
  subtitle,
}) {
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        // backgroundImage: lgUp
        //   ? "url('/images/home_buyers/Purple patch.svg'), url('/images/home_buyers/orange patch_right_2.svg')"
        //   : "none",
        backgroundRepeat: "no-repeat, no-repeat, no-repeat",
        backgroundSize: "10%, 7%",
        backgroundPosition: "0% 50%, 100% 35%",
        marginTop: lgUp && 48,
        padding: 0,
        //padding: commonPadding,
      }}
    >
      <Container>
        <Container>
          <Grid item xs={12} lg={12} container style={{ paddingBottom: 40 }}>
            <Grid
              item
              xs={12}
              style={{
                marginBottom: 48,
              }}
            >
              <Grid
                align="center"
                className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
                style={{
                  fontSize: mdUp ? 40 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                }}
              >
                So many great features to help your journey
              </Grid>
              <Grid
                align="center"
                className={clsx(WebStyles.paraBlackText, WebStyles.nunitoFont)}
                style={{
                  fontSize: mdUp ? 28 : 14,
                  lineHeight: mdUp ? "38px" : "19px",
                  marginBottom: mdUp ? 12 : 8,
                }}
              >
                {subtitle}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {features.map((item, index) => {
                return (
                  <ServicesFeatureItem
                    key={index}
                    smUp={smUp}
                    mdUp={mdUp}
                    feature={item}
                    isEven={index % 2 === 0}
                    lgUp={lgUp}
                    lgDown={lgDown}
                    index={index}
                    smDown={smDown}
                  />
                );
              })}
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}

export default ServicesFeatures;
