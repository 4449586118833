import React from "react";
import {
  Typography,
  Grid,
  Hidden,
  makeStyles,
  Container,
} from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({}));
function OurCompany({ smUp, mdUp, lgUp, xlUp, commonPadding }) {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        backgroundImage: lgUp
          ? "url('/images/aboutus/green_patch.svg')"
          : "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "12%",
        padding: commonPadding,
        backgroundPositionY: "70%",
        marginTop: xlUp ? 116 : mdUp ? 73 : 124,
      }}
    >
      <Container>
        <Grid
          container
          item
          xs={12}
          style={{
            paddingBottom: 48,
            flexDirection: smUp ? "row" : "column-reverse",
          }}
        >
          <Grid item xs={12} md={6} lg={7} style={{ paddingLeft: mdUp && 48 }}>
            <Grid
              className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
              style={{
                marginTop: 16,
                fontSize: xlUp ? 80 : mdUp ? 60 : 40,
                lineHeight: mdUp ? "74px" : "32px",
                textAlign: "left",
                marginBottom: 24,
                fontWeight: 600,
              }}
            >
              Our company
            </Grid>
            <Grid
              className={clsx(WebStyles.barlowFont)}
              align="left"
              style={{
                marginTop: 16,
                textAlign: "left",
                fontSize: mdUp ? 28 : 14,
                lineHeight: mdUp ? "38px" : "19px",
              }}
            >
              Souqh is Canada's real estate + home services marketplace - we're
              on a mission to simplify every step of your home buying and
              ownership journey. Simply search, connect, and transact with
              trusted service providers across your entire home journey as a
              buyer, owner, or seller - all in one digital platform.
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6} lg={5}>
            <img
              style={{ width: "100%" }}
              src="/images/aboutus/Illustration4.svg"
            ></img>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}

export default OurCompany;
