import { Grid, makeStyles, Typography, Container } from "@material-ui/core";
import React from "react";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  oval: {
    height: 28,
    width: 28,
    backgroundColor: "#72CAC9",
  },
  border: {
    width: "302px",
    height: "338px",
    border: "2px solid #F37C61",
    borderRadius: "41px",
  },
  numbers: {
    fontSize: "60px",
    fontWeight: 800,
  },
}));

function ConpanyOverview({ smUp, mdUp, lgUp, xlUp, commonPadding }) {
  const classes = useStyles();

  const height = lgUp ? 420 : 360;
  const radius = height / 2;
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: commonPadding,
        position: "relative",
        zIndex: 1,
      }}
    >
      {smUp ? (
        <div
          style={{
            position: "absolute",
            backgroundColor: "#F37C61",
            height: height,
            left: -2000,
            right: lgUp ? "10%" : "-15%",
            bottom: 0,
            top: lgUp ? "-40%" : "-60%",
            zIndex: -1,
            borderTopRightRadius: lgUp ? radius : "0px",
            borderBottomRightRadius: lgUp ? radius : "0px",
            transform: "rotate(10deg)",
            transformOrigin: "center",
            opacity: 0.2,
          }}
        />
      ) : null}

      <Container>
        <Grid
          container
          item
          xs={12}
          spacing={lgUp ? 10 : mdUp ? 5 : 5}
          style={{ padding: smUp ? "0px 0px 0px 48px" : 40, marginBottom: 70 }}
        >
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            justifyContent={smUp ? "center" : "flex-end"}
            style={{ marginBottom: 20 }}
          >
            <Grid container item xs={12} md={12} lg={12} justify="flex-end">
              <img
                style={{ height: 130 }}
                src="/images/landing_page/businessman.png"
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              style={{
                width: xlUp ? 302 : mdUp ? 171 : 132,
                height: xlUp ? 400 : mdUp ? 250 : 200,
                border: "2px solid #F37C61",
                borderRadius: xlUp ? 41 : mdUp ? 27 : 21,
              }}
              justify="center"
              alignItems="center"
              direction="column"
            >
              <Grid
                className={clsx(WebStyles.barlowFont)}
                style={{
                  margin: "0 20px",
                  fontSize: xlUp ? 45 : mdUp ? 28 : 24,
                  lineHeight: xlUp ? "55px" : mdUp ? "30px" : "24px",
                }}
              >
                Get connected with hundred of professionals vetted by Souqh.
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            justifyContent={smUp ? "center" : "flex-end"}
            style={{ marginBottom: 20 }}
          >
            <Grid container item xs={12} md={12} lg={12} justify="flex-end">
              <img
                style={{ height: 130 }}
                src="/images/landing_page/top-rated.png"
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              style={{
                width: xlUp ? 302 : mdUp ? 171 : 132,
                height: xlUp ? 400 : mdUp ? 250 : 200,
                border: "2px solid #F37C61",
                borderRadius: xlUp ? 41 : mdUp ? 27 : 21,
              }}
              justify="center"
              alignItems="center"
              direction="column"
            >
              <Grid
                className={clsx(WebStyles.barlowFont)}
                style={{
                  margin: "0 20px",
                  fontSize: xlUp ? 45 : mdUp ? 28 : 24,
                  lineHeight: xlUp ? "55px" : mdUp ? "30px" : "24px",
                }}
              >
                Our professionals are top rated in their markets.
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            justifyContent={smUp ? "center" : "flex-end"}
            style={{ marginBottom: 20 }}
          >
            <Grid container item xs={12} md={12} lg={12} justify="flex-end">
              <img
                style={{ height: 130 }}
                src="/images/landing_page/credit-card.png"
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={12}
              lg={12}
              style={{
                width: xlUp ? 302 : mdUp ? 171 : 132,
                height: xlUp ? 400 : mdUp ? 250 : 200,
                border: "2px solid #F37C61",
                borderRadius: xlUp ? 41 : mdUp ? 27 : 21,
              }}
              justify="center"
              alignItems="center"
              direction="column"
            >
              <Grid
                className={clsx(WebStyles.barlowFont)}
                style={{
                  margin: "0 20px",
                  fontSize: xlUp ? 45 : mdUp ? 28 : 24,
                  lineHeight: xlUp ? "55px" : mdUp ? "30px" : "24px",
                }}
              >
                Earn credits everytime you use Souqh for your project.
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}

export default ConpanyOverview;
