import { Container, Grid, Link as MaterialLink } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ServicesHeader from "./ServicesHeader";
import WebStyles from "../WebStyles.module.css";
import clsx from "clsx";
import ServicesIntroSection from "./ServicesIntroSection";
import ServicesFeatures from "./ServicesFeatures";
import ServicesHowItWorks from "./ServicesHowItWorks";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import ServicesFeaturedSF from "./ServicesFeaturedSF";
import { isMobileDevice, isTabletDevice } from "./WebUtils";

export default function ServicesLayout({
  commonPadding,
  mdUp,
  lgUp,
  smUp,
  xlUp,
  smDown,
  lgDown,
  HB_QUESTION_ANSWERS,
  SP_QUESTION_ANSWERS,
  features,
  title,
  children,
  btnText,
  serviceFeatureSubTitle,
  serviceName,
}) {
  const [questionAndAnswersRow1, setQuestionAndAnswersRow1] =
    useState(HB_QUESTION_ANSWERS);
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (value === 0) {
      setQuestionAndAnswersRow1(HB_QUESTION_ANSWERS);
    } else if (value === 1) {
      setQuestionAndAnswersRow1(SP_QUESTION_ANSWERS);
    }
  }, [value]);

  return (
    <Grid
      className={!isMobileDevice() && !isTabletDevice()}
      item
      container
      xs={12}
      md={12}
      style={{
        padding: commonPadding,
        marginTop: 20,
        backgroundImage:
          "url('/images/Explore-Section/blue_bg_shape.svg'), url('/images/Explore-Section/orange_shape.svg')",
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundPosition: isMobileDevice()
          ? "0% 10%,100% 30%"
          : "0% 5%,100% 95%",
        backgroundSize: isMobileDevice() ? "18%, 12%" : "8%, auto",
        top: 0,
      }}
    >
      {/* <HomeWebForm
        open={open}
        who={who}
        handleClose={handleClose}
      ></HomeWebForm> */}
      <Container
        style={
          {
            //backgroundImage:
            //"url('/images/home_page/question-answer-section.svg')",
            // backgroundRepeat: "no-repeat",
            // backgroundSize: smUp ? (mdUp ? "28%" : "60% 60%") : "50%",
            // backgroundPosition: "right 55px",
          }
        }
      >
        <Container>
          {/* <Grid
            align="left"
            item
            className={clsx(WebStyles.purpleTxt, WebStyles.hankrndFont)}
            style={{
              fontSize: mdUp ? 40 : 20,
              lineHeight: mdUp ? "40px" : "20px",
              marginBottom: 36,
              marginTop: 10,
              fontWeight: 600,
            }}
          >
            Moving Services
          </Grid> */}
          <ServicesHeader title={title}>{children}</ServicesHeader>

          <Grid container direction="column" justify="flex-start"></Grid>
          <Grid
            xs={12}
            lg={12}
            style={{
              marginTop: !isMobileDevice() && 36,
            }}
          >
            <Grid>
              <ServicesIntroSection btnText={btnText}>
                {children}
              </ServicesIntroSection>
              <ServicesHowItWorks />
              <ServicesFeatures
                smUp={smUp}
                smDown={smDown}
                mdUp={mdUp}
                lgUp={lgUp}
                lgDown={lgDown}
                xlUp={xlUp}
                features={features}
                subtitle={serviceFeatureSubTitle}
              />
              <Grid>
                <Container
                  commonPadding={commonPadding}
                  style={{
                    backgroundImage:
                      "url('/images/home_page/question-answer-section.svg')",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: smUp ? (mdUp ? "28%" : "50% 50%") : "40%",
                    backgroundPosition: "right -24px",
                    minHeight: mdUp ? "400" : "auto",
                    // paddingTop: "100px",
                  }}
                >
                  <Grid
                    align="left"
                    className={clsx(
                      WebStyles.paraBlackText,
                      WebStyles.hankrndFont
                    )}
                    style={{
                      fontSize: xlUp ? 50 : mdUp ? 40 : 24,
                      lineHeight: 1,
                      marginBottom: mdUp ? 24 : 12,
                      fontWeight: 600,
                    }}
                  >
                    Helpful Resources
                  </Grid>
                  <Container
                    style={{
                      minHeight: "150px",
                    }}
                  >
                    {questionAndAnswersRow1.map((data, index) => {
                      return (
                        <Grid
                          container
                          key={index}
                          item
                          xs={12}
                          direction="row"
                          align="left"
                          style={{ marginBottom: 10 }}
                        >
                          <Grid
                            className={clsx(
                              WebStyles.paraBlackText,
                              WebStyles.hankrndFont
                            )}
                            style={{
                              fontSize: mdUp ? 20 : 10,
                              lineHeight: mdUp ? "24px" : "12px",
                              marginBottom: 10,
                              fontWeight: 600,
                            }}
                            item
                            container
                            justify="flex-start"
                            alignContent="flex-start"
                            xs={12}
                            md={6}
                          >
                            {/* <Grid container item xs={1} alignContent="baseline">
                        <Grid item>
                          <ButtonBase onClick={() => handelExpandRow1(data.id)}>
                            {!data.expanded ? (
                              <AddIcon
                                style={{ fontSize: mdUp ? "1.5rem" : "1rem" }}
                                className={WebStyles.purpleTxt}
                              />
                            ) : (
                              <RemoveIcon
                                style={{ fontSize: mdUp ? "1.5rem" : "1rem" }}
                                className={WebStyles.purpleTxt}
                              />
                            )}
                          </ButtonBase>
                        </Grid>
                      </Grid> */}
                            <Grid container item xs={1} alignContent="baseline">
                              <ControlPointIcon
                                style={{ fontSize: mdUp ? "1.5rem" : "1rem" }}
                                className={WebStyles.purpleTxt}
                              />
                            </Grid>
                            <Grid item xs={10} container alignContent="center">
                              <MaterialLink
                                href={data.link}
                                style={{ color: "#2b3350" }}
                              >
                                {data.question}
                              </MaterialLink>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Container>
                </Container>
              </Grid>

              <ServicesFeaturedSF
                smUp={smUp}
                smDown={smDown}
                mdUp={mdUp}
                lgUp={lgUp}
                lgDown={lgDown}
                xlUp={xlUp}
                serviceName={serviceName}
              />
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}
