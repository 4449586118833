import { Grid, useTheme, Link as MaterialLink } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import WebStyles from "../WebStyles.module.css";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

export const APP_BAR_HEIGHT_DESKTOP = 215;
export const APP_BAR_HEIGHT_MOBILE = 162;

export default function ServicesMenu() {
  const [openMenu, setOpenMenu] = useState(false);
  const closeMenuBar = () => {
    setOpenMenu(false);
  };
  const [expand, setExpand] = useState(false);

  return (
    <Grid
      item
      container
      xs={12}
      className={WebStyles.paraBlackText}
      style={{
        fontSize: 24,
        padding: "12px 3px",
        borderBottom: "1px solid rgb(189 191 202)",
        cursor: "pointer",
        color: "white",
      }}
    >
      <Grid
        container
        item
        xs={12}
        onClick={() => setExpand(!expand)}
        style={{ paddingRight: 12 }}
      >
        <Grid container item xs={10} justifyContent="flex-start">
          <Link
            style={{
              textDecoration: "none",
              marginLeft: 10,
              color: "white",
            }}
          >
            Services
          </Link>
        </Grid>
        {expand ? <ExpandLess /> : <ExpandMore />}
      </Grid>
      {expand && (
        <Grid container>
          <Grid
            item
            container
            xs={12}
            className={WebStyles.paraBlackText}
            style={{
              fontSize: 16,
              padding: "12px 0px",
              cursor: "pointer",
              color: "white",
            }}
          >
            <MaterialLink
              href="/Real-Estate-Broker/"
              onClick={() => closeMenuBar()}
              style={{
                textDecoration: "none",
                marginLeft: 10,
                color: "white",
              }}
            >
              Realtors
            </MaterialLink>
          </Grid>
          <Grid
            item
            container
            xs={12}
            className={WebStyles.paraBlackText}
            style={{
              fontSize: 16,
              padding: "12px 0px",
              cursor: "pointer",
              color: "white",
            }}
          >
            <MaterialLink
              href="/Mortgage-Broker"
              onClick={() => closeMenuBar()}
              style={{
                textDecoration: "none",
                marginLeft: 10,
                color: "white",
              }}
            >
              Mortgage Broker / Agent
            </MaterialLink>
          </Grid>

          <Grid
            item
            container
            xs={12}
            className={WebStyles.paraBlackText}
            style={{
              fontSize: 16,
              padding: "12px 0px",
              cursor: "pointer",
              color: "white",
            }}
          >
            <MaterialLink
              href="/Real-Estate-Lawyer"
              onClick={() => closeMenuBar()}
              style={{
                textDecoration: "none",
                marginLeft: 10,
                color: "white",
              }}
            >
              Real Estate Lawyer
            </MaterialLink>
          </Grid>
          <Grid
            item
            container
            xs={12}
            className={WebStyles.paraBlackText}
            style={{
              fontSize: 16,
              padding: "12px 0px",
              cursor: "pointer",
              color: "white",
            }}
          >
            <MaterialLink
              href="https://app.souqh.ca/login"
              onClick={() => closeMenuBar()}
              style={{
                textDecoration: "none",
                marginLeft: 10,
                color: "white",
              }}
            >
              Property Inspector
            </MaterialLink>
          </Grid>
          <Grid
            item
            container
            xs={12}
            className={WebStyles.paraBlackText}
            style={{
              fontSize: 16,
              padding: "12px 0px",
              cursor: "pointer",
              color: "white",
            }}
          >
            <MaterialLink
              href="/Mover"
              onClick={() => closeMenuBar()}
              style={{
                textDecoration: "none",
                marginLeft: 10,
                color: "white",
              }}
            >
              Mover
            </MaterialLink>
          </Grid>
          <Grid
            item
            container
            xs={12}
            className={WebStyles.paraBlackText}
            style={{
              fontSize: 16,
              padding: "12px 0px",
              cursor: "pointer",
              color: "white",
            }}
          >
            <MaterialLink
              href="/Handyman-Services"
              onClick={() => closeMenuBar()}
              style={{
                textDecoration: "none",
                marginLeft: 10,
                color: "white",
              }}
            >
              General Contractor
            </MaterialLink>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
