import React, { useEffect, useState } from "react";
import { Hidden, useMediaQuery, useTheme } from "@material-ui/core";
import SubFooter from "../SubFooter";
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions";
import ServicesLayout from "../ServicesLayout";

function RealtorsList() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const xsUp = useMediaQuery(theme.breakpoints.up("xs"));
  const title = "Get Started with Souqh";
  const subTitle =
    "If you are looking for the best realtors in your area anywhere in Canada, Souqh has the solution for you. Souqh is a marketplace for real estate service providers, from realtors, real estate lawyers, to contractors.";
  const btnText = "Sign Up Now!";
  const redirectUrl = "";

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const SP_QUESTION_ANSWERS = [
    {
      id: 1,
      question: "What makes a good real estate agent",
      link: "/what-makes-a-good-real-estate-agent",
    },
    {
      id: 2,
      question: "How to become a real estate agent in Ontario",
      link: "/become-a-real-estate-agent",
    },
  ];

  const HB_QUESTION_ANSWERS = [
    {
      id: 1,
      question: "What do real estate agents charge to sell your house",
      //answer:
      //"We know from our own experience how overwhelming and stressful the home journey can be and we were determined to make the process easier. Souqh is Canada’s only end-to-end digital services marketplace who supports home buyers looking for guidance with their home buying journey. Finding a trusted service provider, such as a realtor, mortgage broker, lawyer, or general contractor, has never been easier. From verified reviews, to documentation that is easy to access and locate all in one place, Souqh simplifies your home journey. And the best part? It’s all free!",
      link: "/real-estate-agent-sells-your-house",
    },
    // {
    //   id: 2,
    // question:"Real Estate lawyers",
    // //answer:
    //   //"We know from our own experience how overwhelming and stressful the home journey can be and we were determined to make the process easier. Souqh is Canada’s only end-to-end digital services marketplace who supports home buyers looking for guidance with their home buying journey. Finding a trusted service provider, such as a realtor, mortgage broker, lawyer, or general contractor, has never been easier. From verified reviews, to documentation that is easy to access and locate all in one place, Souqh simplifies your home journey. And the best part? It’s all free!",
    //   link:
    //   "/real-estate-lawyers",

    // }
  ];

  const features = [
    {
      name: "Ditch the stress",
      color: "#72cac9",
      image: "/images/home_buyers/Illustration 14.svg",
      description:
        "Buying a home can be a stressful life decision - we designed Souqh to help eliminate some of that stress. We are the only end-to-end digital services platform that provides digital guidance and access to a trusted service provider network, all in one place. Buying a home has never been easier with Souqh",
    },
    {
      name: "Trusted Service Providers",
      color: "#72cac9",
      image: "/images/home_buyers/Trusted Service Providers.svg",
      description:
        "Worried about the right fit? Souqh helps you find trusted service providers across a pool of storefronts that match your unique needs, along with verified ratings and testimonials from home buyers just like you.",
    },
    {
      name: "Streamlined Documents",
      color: "#F37C61",
      image: "/images/home_buyers/Illustartion 15.svg",
      description:
        "We know it can be frustrating to manage all the documents required throughout your home buying journey. Souqh streamlines all your documents in a simple and secure portal, allowing you to create, share, e-sign and upload documents directly to service providers.",
    },
    {
      name: "Communicate Freely",
      color: "#F37C61",
      image: "/images/home_buyers/Illustration 16.svg",
      description:
        "Tired of calling, texting and overflown inboxes with missed attachments? Souqh simplifies all communications with service providers, right on the platform, with easy access and interaction history.",
    },
    {
      name: "Earn Credits",
      color: "#B872AF",
      image: "/images/home_buyers/Illustartion 17.svg",
      description:
        "Every time you interact on Souqh or refer a service provider, you earn credits that can be used for future services. It pays to transact on Souqh!",
    },
    {
      name: "Rest Easy",
      color: "#B872AF",
      image: "/images/home_buyers/Illustration 18.svg",
      description:
        "Rest easy knowing that you completely own and control your data. Our enterprise-grade cloud security, which runs on industry-leading Amazon cloud, protects your data using 256-bit SSL/TLS encryption.",
    },
  ];
  return (
    <div
      style={{
        overflow: "hidden",
        position: "relative",
        top: smUp ? (lgUp ? 55 : 60) : 78,
        marginBottom: 105,
      }}
    >
      {/* <FrequentlyAskedQuestions
        smUp={smUp}
        mdUp={mdUp}
        lgUp={lgUp}
        lgDown={lgDown}
        xsUp={xsUp}
      /> */}
      <ServicesLayout
        smUp={smUp}
        mdUp={mdUp}
        lgUp={lgUp}
        lgDown={lgDown}
        xsUp={xsUp}
        HB_QUESTION_ANSWERS={HB_QUESTION_ANSWERS}
        SP_QUESTION_ANSWERS={SP_QUESTION_ANSWERS}
        features={features}
        btnText="Realtor"
        title="Real Estate Services"
        serviceFeatureSubTitle="The complete home buying journey is right at your fingertips, anywhere, anytime."
        serviceName="Real Estate Broker / Agent"
      >
        {/* <Hidden xsUp lgUp mdUp xlUp smUp>
        A mortgage broker can get you pre-approved for a home loan only, but
        only in as far as they can submit the pre-approval application on your
        behalf. Ultimately it is the bank that pre-approves you for a mortgage.
        In fact, you have the option of submitting your request for your
        mortgage pre-approval directly at the bank or through a mortgage broker.
        <br />
        The benefit of using a broker for your mortgage pre-approval is they can
        help you get it faster. Since this is their day job, they know which
        information the lender requires and can submit it all the first time.
        They are likely to be more thorough and will know the exact steps and
        procedures for pre-approval, which saves time.
        <br />
        {/* </Hidden> */}
        {/* <br />
        Mortgage brokers also know which lenders have the fastest pre-approval
        times, and the lowest rates. They’re not tied to any lender so they will
        recommend the lender that offers you the best rates and the smoothest
        home loan application process.
        <br />
        <br />  */}
      </ServicesLayout>
      <SubFooter
        title={title}
        subTitle={subTitle}
        btnText={btnText}
        redirectUrl={redirectUrl}
      />
    </div>
  );
}

export default RealtorsList;
