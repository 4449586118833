import React, { useState } from "react";
import { Grid, Button, TextField, Container, Hidden  } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import HomeWebForm from "../home/HomeWebForm";

function SectionTwo({
  xlUp,
  mdUp,
  smUp,
  xsUp,
  mdDown,
  smDown,
  xsDown,
  setShowWaitListForm,
}) {
  return (
    <Container>
      <Container>
    <Grid container item xs={12}  md={12} alignContent="center" style={{
     paddingLeft: mdUp ? "10%" : smUp ? "5%" : "0",
       paddingRight: mdUp? "10%" : smUp ? "5%" : "0"
    }}>
      <Grid item xs={12}>
        <Grid
          align="left"
          className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 50 : 30,
           
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
            marginTop : mdUp ? 12:8,
          }}
        >
          How to get started
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
     
         <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
          <strong>Pre-registration: </strong>you have to complete mandatory pre-registration education courses and examinations. Enrol for the “Pre-Registration Learning Path” <a href="https://humber.ca/realestate/">offered by Humber College.</a> This phase comprises 5 courses, 4 exams and 2 simulation sessions.
            <Hidden xsUp mdUp xlUp smUp> 
          
            Candidates have to complete the program within 24 months. Learning materials are also accessible online, so remote learning is an option. To qualify for entry to the program, you must hold a Canadian secondary school diploma or GED certificate or an equivalent credential that the college accepts. The total cost of the entire pre-registration phase comes to $4,175 and candidates typically spend 208 hours completing it.<br/>
        </Hidden>
        <br/>
        The program is split into five courses:
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} md={4} style={{
          //paddingLeft: mdUp ? "2%" : smUp ? "1%" : "0",
          padding: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ?  35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
          Real Estate Essentials
        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 20 : 18,
             lineHeight: 1.5,
            //fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
  The course focuses on the role of a salesperson and important legal requirements based on the various codes and rules governing real estate in Ontario
</Grid>
        {/* <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 18 : 16,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
       For instance, the realtor was given incorrect information from a previous realtor or the homeowner and passed this on to the client. This is grounds to sue, however, you’re most likely to have only the damages compensated financially.
        </Grid> */}
        </Grid>
        <Grid item xs={12} md={4} style={{
          padding: mdUp ? "2%" : smUp ? "1%" : "0",
          //paddingRight: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
          Real Estate Transactions
        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 20 : 18,
             lineHeight: 1.5,
            //fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
     In this course you will learn the processes involved in listing, buying and selling residential real estate. Additionally, you’re expected to learn marketing strategies and real estate contract
</Grid>
        
        </Grid>
        <Grid item xs={12} md={4} style={{
          padding: mdUp ? "2%" : smUp ? "1%" : "0",
          //paddingRight: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
         Additional Real Estate Transactions
        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 20 : 18,
             lineHeight: 1.5,
          
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
    This course will instruct you about leasing and additional property types such as condos, new homes and rural properties.
</Grid>
        
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} md={4} style={{
          //paddingLeft: mdUp ? "2%" : smUp ? "1%" : "0",
          padding: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ?  35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
          Commercial Real Estate Transactions
        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 20 : 18,
             lineHeight: 1.5,
            //fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
  This course looks at the processes involved in buying, selling and listing commercial properties, and brokering the sale of a business.
</Grid>
        {/* <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 18 : 16,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
       For instance, the realtor was given incorrect information from a previous realtor or the homeowner and passed this on to the client. This is grounds to sue, however, you’re most likely to have only the damages compensated financially.
        </Grid> */}
        </Grid>
        <Grid item xs={12} md={4} style={{
          padding: mdUp ? "2%" : smUp ? "1%" : "0",
          //paddingRight: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
          Getting Started
        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 20 : 18,
             lineHeight: 1.5,
            //fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
 this course gives an overview on how to get started with a career in real estate once you have completed the theory part.
</Grid>
        
        </Grid>
        {/* <Grid item xs={12} md={4} style={{
          padding: mdUp ? "2%" : smUp ? "1%" : "0",
          //paddingRight: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        
        </Grid> */}
        <Hidden mdUp lgUp smUp xsUp>After each course, there is a mandatory examination which you need to pass to start the next course. While you take your courses you also need to attend two separate in-person simulation sessions that last a week each to understand how to apply the course knowledge in real-life scenarios.

The course needs to be taken in order and there are flexible schedules available, including online learning options. Because of the flexibility, completion time varies amongst candidates but, in general it takes nine months to a year to complete all the required parts of the program. Once you pass all your examinations and complete your simulation sessions, you will have successfully completed the program and can then move on to the next step.</Hidden>
      </Grid>
      {/* <Grid item xs={12} md={6} style={{ marginTop: mdUp ? 20 : 10 }}>
      <TextField fullWidth placeholder="Your email"></TextField>
    </Grid> */}
    
    </Grid>
    </Container>
    </Container>
  );
}



// function Introduction({
//   smUp,
//   smDown,
//   mdUp,
//   mdDown,
//   lgUp,
//   xlUp,
//   commonPadding,
// }) {
//   const [showWaitListForm, setShowWaitListForm] = useState(false);
//   const handleClose = () => {
//     setShowWaitListForm(false);
//   };
//   return (
//     <Grid
//       item
//       container
//       xs={12}
//       style={{
//         backgroundImage: lgUp
//           ? "url('/images/home_buyers/Green patch.svg')"
//           : "none",
//         backgroundRepeat: "no-repeat",
//         backgroundSize: "10%",
//         padding: commonPadding,
//       }}
//     >

//       <Container>
//         <Container>
//           <Grid container>
          
//               <Grid container direction="row">
//                 <SectionTwo
//                   xlUp={xlUp}
//                   mdUp={mdUp}
//                   mdDown={mdDown}
//                   smDown={smDown}

//                 />
                
//               </Grid>
          

//           </Grid>
//         </Container>
//       </Container>
//     </Grid>
//   );
// }

export default SectionTwo;
