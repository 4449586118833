import React, { useState } from "react";
import { Grid, Button, TextField, Container, Hidden  } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import HomeWebForm from "../home/HomeWebForm";

function InfoSection({
  xlUp,
  mdUp,
  mdDown,
  smDown,
  xsDown,
  setShowWaitListForm,
}) {
  return (
    <Grid container item xs={12} md={8} alignContent="center">
      <Grid item xs={12}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: xlUp ? 80 : mdUp ? 60 : 40,
            lineHeight: 1,
            marginBottom: mdUp ? 24 : 12,
            fontWeight: 600,
          }}
        >
        Can A Mortgage Broker Get You Pre-Approved?

        </Grid>
      </Grid>
      <Grid item xs={12} md={10}>
         <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
           <Hidden xsUp lgUp mdUp xlUp smUp> 
           A mortgage broker can get you pre-approved for a home loan only, but only in as far as they can submit the pre-approval application on your behalf. Ultimately it is the bank that pre-approves you for a mortgage. In fact, you have the option of submitting your request for your mortgage pre-approval directly at the bank or through a mortgage broker.
<br/>
The benefit of using a broker for your mortgage pre-approval is they can help you get it faster. Since this is their day job, they know which information the lender requires and can submit it all the first time. They are likely to be more thorough and will know the exact steps and procedures for pre-approval, which saves time.
<br/>


        </Hidden>

        <br/>
        Mortgage brokers also know which lenders have the fastest pre-approval times, and the lowest rates. They’re not tied to any lender so they will recommend the lender that offers you the best rates and the smoothest home loan application process.
<br/>
<br/>
        </Grid>
      </Grid>
      {/* <Grid item xs={12} md={6} style={{ marginTop: mdUp ? 20 : 10 }}>
      <TextField fullWidth placeholder="Your email"></TextField>
    </Grid> */}
    
    </Grid>
  );
}

function ImageSection({
  xlUp,
  mdUp,
  mdDown,
  smDown,
  xsDown,})
  {
  return (
    
    <Grid item xs={12} md={4}
    style={{
      
      marginTop: mdUp ? 24 : 12,
    }}
    >
      {
        //<iframe width="100%" height="450" src="https://www.youtube.com/embed/xdh48tFm4dM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <img
       src="/images/home_page/Illustration 22.svg"
        style={{
           //height: xlUp ? 625 : mdUp ? 313 : 219,
          width: "100%",
        }}
      /> }
    </Grid>
  );
}

function Introduction({
  smUp,
  smDown,
  mdUp,
  mdDown,
  lgUp,
  xlUp,
  commonPadding,
}) {
  const [showWaitListForm, setShowWaitListForm] = useState(false);
  const handleClose = () => {
    setShowWaitListForm(false);
  };
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        backgroundImage: lgUp
          ? "url('/images/home_buyers/Green patch.svg')"
          : "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "10%",
        padding: commonPadding,
      }}
    >

      <Container>
        <Container>
          <Grid container>
            {mdUp ? (
              <Grid container direction="row">
                <InfoSection
                  xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown}

                />
                <ImageSection xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown} />
              </Grid>
            ) : (
              <Grid container direction="row">
                
                <InfoSection
                  xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown}
                />
                 <ImageSection  xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown} />
              </Grid>
            )}


          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}

export default Introduction;
