import React, { useState } from "react";
import { Grid, Button, TextField, Container, Hidden } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import HomeWebForm from "../home/HomeWebForm";

function InfoSection({
  xlUp,
  mdUp,
  mdDown,
  smDown,
  xsDown,
  setShowWaitListForm,
}) {
  return (
    <Grid container item xs={12} md={8} alignContent="center">
      <Grid item xs={12}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: xlUp ? 80 : mdUp ? 60 : 40,
            lineHeight: 1,
            marginBottom: mdUp ? 24 : 12,
            fontWeight: 600,
          }}
        >
          Real Estate Lawyers
        </Grid>
      </Grid>
      <Grid item xs={12} md={10}>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
          <br />
          Invest in an experienced real estate lawyer that knows your area and
          local laws well to protect yourself, stay compliant, and get the most
          out of your real estate deals.
          <br />
          <br />
          <Hidden xsUp mdUp xlUp smUp>
            Real estate deals commit so much money it is essential to get
            qualified legal advice at every stage of the buying process. While a
            real estate agent helps you negotiate the best deal in a real estate
            transaction, a real estate lawyer makes sure the transaction passes
            all legal tests.
            <br />
            To protect your investment, you want to invest in a real estate
            lawyer that is familiar with local laws. If not to guide you through
            all the legal processes then to review your paperwork before you
            close your deal. Souqh is where real estate investors and homebuyers
            in Canada are going to find experienced local real estate lawyers.
            <br />
            The Souqh digital platform makes it easy to find the right real
            estate service provider for your needs, whether they be real estate
            attorneys, mortgage brokers, movers, or building contractors.
            <br />
            Using the Souqh marketplace, you can search for service providers by
            their locality, request and compare estimates, and connect with
            those that catch your eye. We have made it completely painless to
            find the right professionals to help you through your home journey.
          </Hidden>
        </Grid>
      </Grid>
      {/* <Grid item xs={12} md={6} style={{ marginTop: mdUp ? 20 : 10 }}>
      <TextField fullWidth placeholder="Your email"></TextField>
    </Grid> */}
    </Grid>
  );
}

function ImageSection({ xlUp, mdUp, mdDown, smDown, xsDown }) {
  return (
    <Grid
      item
      xs={12}
      md={4}
      style={{
        marginTop: mdUp ? 24 : 12,
      }}
    >
      {
        //<iframe width="100%" height="450" src="https://www.youtube.com/embed/xdh48tFm4dM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <img
          src="/images/home_page/Illustration 22.svg"
          style={{
            //height: xlUp ? 625 : mdUp ? 313 : 219,
            width: "100%",
          }}
        />
      }
    </Grid>
  );
}

function Introduction({
  smUp,
  smDown,
  mdUp,
  mdDown,
  lgUp,
  xlUp,
  commonPadding,
}) {
  const [showWaitListForm, setShowWaitListForm] = useState(false);
  const handleClose = () => {
    setShowWaitListForm(false);
  };
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        backgroundImage: lgUp
          ? "url('/images/home_buyers/Green patch.svg')"
          : "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "10%",
        padding: commonPadding,
      }}
    >
      <Container>
        <Container>
          <Grid container>
            {mdUp ? (
              <Grid container direction="row">
                <InfoSection
                  xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown}
                />
                <ImageSection
                  xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown}
                />
              </Grid>
            ) : (
              <Grid container direction="row">
                <InfoSection
                  xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown}
                />
                <ImageSection
                  xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown}
                />
              </Grid>
            )}
          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}

export default Introduction;
