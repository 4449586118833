import React, { useEffect } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import SubFooter from "../SubFooter";
import ServicesLayout from "../ServicesLayout";

function MoverServicesList() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const xsUp = useMediaQuery(theme.breakpoints.up("xs"));
  const title = "Get Started with Souqh";
  const subTitle =
    "If you are looking for the best realtors in your area anywhere in Canada, Souqh has the solution for you. Souqh is a marketplace for real estate service providers, from realtors, real estate lawyers, to contractors.";
  const btnText = "Sign Up Now!";
  const redirectUrl = "";

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const SP_QUESTION_ANSWERS = [];

  const HB_QUESTION_ANSWERS = [
    {
      id: 1,
      question: "Moving Services",
      //answer:
      //"We know from our own experience how overwhelming and stressful the home journey can be and we were determined to make the process easier. Souqh is Canada’s only end-to-end digital services marketplace who supports home buyers looking for guidance with their home buying journey. Finding a trusted service provider, such as a realtor, mortgage broker, lawyer, or general contractor, has never been easier. From verified reviews, to documentation that is easy to access and locate all in one place, Souqh simplifies your home journey. And the best part? It’s all free!",
      link: "/moving-services",
    },
    {
      id: 2,
      question: "Renovations That Add Value To Your Home",
      link: "https://blog.souqh.ca/renovations-that-add-value-to-your-home/",
    },
  ];

  const features = [
    {
      name: "Searchable Database Of Local Movers",
      color: "#72cac9",
      image: "/images/home_buyers/Illustration 14.svg",
      description:
        "Souqh offers a pre-vetted database of trusted local movers for you to review at your convenience, saving you hours of research. Movers on Souqh maintain storefronts where they list the different services they offer, their credentials and qualifications, and other information you need to decide if they fit your needs.",
    },
    {
      name: "Reputable Local Movers For Your Family’s Safety",
      color: "#72cac9",
      image: "/images/home_buyers/Trusted Service Providers.svg",
      description:
        "Souqh invests a great deal of effort in vetting service providers on the platform. We do this to make sure all service providers have the necessary licenses and insurance cover. This is why we added a reviews feature so users can share their experiences - good or bad - with others.",
    },
    {
      name: "Easily Compare Estimates",
      color: "#F37C61",
      image: "/images/home_buyers/Illustartion 15.svg",
      description:
        "With Souqh, you simply select ‘moving’ as your desired service on the home page and type in the first few letters of your local area into the search bar.",
    },
    {
      name: "Communicate Freely",
      color: "#F37C61",
      image: "/images/home_buyers/Illustration 16.svg",
      description:
        "Tired of calling, texting and overflown inboxes with missed attachments? Souqh simplifies all communications with service providers, right on the platform, with easy access and interaction history.",
    },
    {
      name: "Earn Credits",
      color: "#B872AF",
      image: "/images/home_buyers/Illustartion 17.svg",
      description:
        "Every time you interact on Souqh or refer a service provider, you earn credits that can be used for future services. It pays to transact on Souqh!",
    },
    {
      name: "Secure, Insured Transportation For Your Delicate And Expensive Objects",
      color: "#B872AF",
      image: "/images/home_buyers/Illustration 18.svg",
      description:
        " Some moving companies on Souqh even offer white-glove furniture disassembly and reassembly services that take care of some of the most exhausting parts of the moving process.",
    },
  ];

  return (
    <div
      style={{
        overflow: "hidden",
        position: "relative",
        top: smUp ? (lgUp ? 55 : 60) : 78,
        marginBottom: 105,
      }}
    >
      <ServicesLayout
        smUp={smUp}
        mdUp={mdUp}
        lgUp={lgUp}
        lgDown={lgDown}
        xsUp={xsUp}
        HB_QUESTION_ANSWERS={HB_QUESTION_ANSWERS}
        SP_QUESTION_ANSWERS={SP_QUESTION_ANSWERS}
        features={features}
        title="Moving Services"
        btnText="Mover"
        serviceFeatureSubTitle="Souqh makes it easy to choose the right mover for you, among other benefits."
        serviceName="Mover"
      >
        A professional mover takes the stress out of relocating to your new home
        and saves you valuable time. But a wrong choice can add to your stresses
        through delayed moves and lost or damaged property. Souqh matches you
        with the right local mover.
        <br />
        <br />
      </ServicesLayout>
      <SubFooter
        title={title}
        subTitle={subTitle}
        btnText={btnText}
        redirectUrl={redirectUrl}
      />
    </div>
  );
}

export default MoverServicesList;
