import React, { useEffect } from "react";

export default function MarketPlaceRedirection() {
  useEffect(() => {
    let pathParams = window.location.pathname.split("/");
    //get the storefront name which is last parameter in string
    window.location = `https://app.souqh.ca/storefront/${
      pathParams[pathParams.length - 1]
    }`;
  }, []);
  return <div>Loading..</div>;
}
