import React from "react";
import {
  Container,
  Divider,
  Grid,
  Hidden,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import WebStyles from "../WebStyles.module.css";

const HomeLinks = () => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Grid
      container
      item
      sm={2}
      xs={4}
      direction="column"
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Link
        style={{
          textDecoration: "none",
          fontSize: mdUp ? 16 : 8,
          color: "#2B3350",
          fontWeight: "bold",
        }}
        to="/"
      >
        <Grid
          className={WebStyles.nunitoFont}
          style={{ fontSize: mdUp ? 20 : 10 }}
        >
          Home
        </Grid>
      </Link>
      <Link
        style={{
          textDecoration: "none",
          fontSize: mdUp ? 16 : 8,
          color: "#2B3350",
        }}
        to="/home-buyers"
      >
        <Grid className={WebStyles.nunitoFont}>Home Buyers/Owners</Grid>
      </Link>
      <Link
        style={{
          textDecoration: "none",
          fontSize: mdUp ? 16 : 8,
          color: "#2B3350",
        }}
        to="/service-providers"
      >
        <Grid className={WebStyles.nunitoFont}>Service Providers</Grid>
      </Link>
      <Link
        style={{
          textDecoration: "none",
          fontSize: mdUp ? 16 : 8,
          color: "#2B3350",
        }}
        to="/about-us"
      >
        <Grid className={WebStyles.nunitoFont}>About us</Grid>
      </Link>
    </Grid>
  );
};

const ContactUsLinks = () => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Grid item sm={3} xs={3} container>
      <Grid item xs={12} container>
        <Link
          style={{
            textDecoration: "none",
            // fontSize: mdUp ? 16 : 8,
            color: "#2B3350",
            fontWeight: "bold",
          }}
          to="/contact-us"
        >
          <Grid
            style={{ fontSize: mdUp ? 20 : 10, fontWeight: "bold" }}
            className={WebStyles.nunitoFont}
          >
            Contact Us
          </Grid>
        </Link>
      </Grid>
      <Grid
        item
        xs={12}
        container
        style={{ fontSize: mdUp ? 16 : 8, color: "#2B3350" }}
        className={WebStyles.nunitoFont}
      >
        <a
          style={{ color: "#2B3350", textDecoration: "none" }}
          href="tel:+1 (647) 871 3786"
        >
          +1 (647) 871 3786
        </a>
      </Grid>
      <Grid
        item
        xs={12}
        container
        style={{ fontSize: mdUp ? 16 : 8, color: "#2B3350" }}
        className={WebStyles.nunitoFont}
      >
        <a
          style={{ color: "#2B3350", textDecoration: "none" }}
          href="mailto:hello@souqh.ca"
        >
          hello@souqh.ca
        </a>
      </Grid>
      <Grid
        item
        xs={12}
        container
        style={{ fontSize: mdUp ? 16 : 8, color: "#2B3350" }}
        className={WebStyles.nunitoFont}
      >
        Toronto, Ontario
      </Grid>

      <Grid sm={8} xs={12} item container style={{ marginTop: 5 }} spacing={2}>
        <Grid md={2} xs={2} item>
          <Link
            to={{
              pathname: "https://www.facebook.com/Souqh-105815918284357",
            }}
            target="_blank"
          >
            <img
              src="images/footer/facebook.svg"
              className={
                lgUp ? WebStyles.socialIcon : WebStyles.socialIconMobile
              }
            />
          </Link>
        </Grid>
        <Grid md={2} xs={2} item>
          <Link
            to={{
              pathname: "https://www.instagram.com/souqhteam/",
            }}
            target="_blank"
          >
            <img
              src="images/footer/Instagram.svg"
              className={
                lgUp ? WebStyles.socialIcon : WebStyles.socialIconMobile
              }
              style={{ marginLeft: 4 }}
            />
          </Link>
        </Grid>
        <Grid md={2} xs={2} item>
          <Link
            to={{
              pathname: "https://twitter.com/souqhteam",
            }}
            target="_blank"
          >
            <img
              src="images/footer/Twitter.svg"
              className={
                lgUp ? WebStyles.socialIcon : WebStyles.socialIconMobile
              }
              style={{ marginLeft: 8 }}
            />
          </Link>
        </Grid>
        <Grid md={2} xs={2} item>
          <Link
            to={{
              pathname: "https://www.linkedin.com/company/souqh/",
            }}
            target="_blank"
          >
            <img
              src="images/footer/LinkedIn.svg"
              className={
                lgUp ? WebStyles.socialIcon : WebStyles.socialIconMobile
              }
              style={{ marginLeft: 12 }}
            />
          </Link>
        </Grid>
        <Grid md={2} xs={2} item>
          <Link
            to={{
              pathname:
                "https://www.youtube.com/channel/UClH1brV9v85OAt32B5_uLTg",
            }}
            target="_blank"
          >
            <img
              src="images/footer/youtube.svg"
              className={
                lgUp ? WebStyles.socialIcon : WebStyles.socialIconMobile
              }
              style={{ marginLeft: 16 }}
            />
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

function Footer() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <Container>
      <Container>
        <Grid
          container
          style={{
            // paddingRight: smDown ? 40 : 0,
            marginTop: 20,
            lineHeight: "34px",
          }}
        >
          <Grid
            item
            sm={7}
            // xs={12}
            xs={4}
            container
            justifyContent="center"
            alignContent="center"
          >
            <Link to="/">
              <img
                style={{
                  height: mdUp ? 85 : 50,
                  width: mdUp ? 272 : 125,
                  marginTop: mdUp ? 20 : 10,
                  marginLeft: mdUp ? 20 : 0,
                }}
                // src="/images/footer/souqh_logo_withName.svg"
                src="/images/souqh logo tagline.svg"
              />
            </Link>
          </Grid>
          <Hidden smUp>
            <Grid xs={1}></Grid>
          </Hidden>
          <HomeLinks></HomeLinks>
          <ContactUsLinks></ContactUsLinks>
          <Hidden smUp>
            <Grid xs={2}></Grid>
          </Hidden>

          <Divider
            orientation="horizontal"
            // variant="fullWidth"
            style={{ width: "100%", margin: "10px 0", marginTop: 50 }}
          />

          <Grid container justifyContent="center" style={{ marginBottom: 10 }}>
            <Grid
              className={WebStyles.nunitoFont}
              style={{ fontSize: mdUp ? "15px" : "7.5px", color: "#0e1c4e" }}
            >
              © 2021 Souqh Technologies Inc. All Rights Reserved /{" "}
              <Link
                target="_blank"
                style={{ textDecoration: "none", color: "#0e1c4e" }}
                to={{ pathname: "https://app.souqh.ca/privacypolicy" }}
              >
                Privacy Policy
              </Link>{" "}
              /{" "}
              <Link
                target="_blank"
                style={{ textDecoration: "none", color: "#0e1c4e" }}
                to={{ pathname: "https://app.souqh.ca/termsAndPolicy" }}
              >
                Terms & Conditions
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{ height: 80, width: "100%" }}></Grid>
      </Container>
    </Container>
  );
}

export default Footer;
