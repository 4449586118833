import {
  Box,
  ButtonBase,
  Divider,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Tooltip,
  Typography,
  Container,
  Card,
  CardMedia,
  Hidden,
  Button,
  IconButton,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { matchSorter } from "match-sorter";
import { isEmpty, isEqual, filter } from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import clsx from "clsx";
import { useExplore } from "souqh-react-redux-hooks/homeBuyer/useExplore";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";
import WebStyles from "../../WebStyles.module.css";
import PublicIcon from "@material-ui/icons/Public";
import MessageIcon from "@material-ui/icons/Message";
import DateRangeIcon from "@material-ui/icons/DateRange";
import RoomIcon from "@material-ui/icons/Room";
import ShareIcon from "@material-ui/icons/Share";
import CircleIcon from "@mui/icons-material/Circle";
import Rating from "@material-ui/lab/Rating";
import useFeaturedStoreFront from "souqh-react-redux-hooks/homeBuyer/useFeaturedStoreFront";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#0e1c4e0a",
  },
  primarColor: {
    color: "#0e1c4e",
  },
  secondarColor: {
    color: "#F86C6B",
  },
  marginTop30: {
    marginTop: 30,
  },
  marginTop10: {
    marginTop: 10,
  },
  marginLeft: {
    marginLeft: 10,
  },
  inputs: {
    marginTop: 0,
    marginBottom: 0,
  },
  marginTop25: {
    marginTop: 25,
  },
  divider: {
    margin: "5px 20px",
  },
  disabledSearch: {
    color: "#17174C1F",
  },
  enabledButton: {
    backgroundColor: "#FA7E61",
  },
  textInput: {
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
  },
  bodyBackgroundImg: {
    // backgroundImage:
    //   "url('/images/Explore-Section/blue_bg_shape.svg'), url('/images/Explore-Section/orange_shape.svg')",
    // backgroundRepeat: "no-repeat, no-repeat",
    // backgroundPosition: isMobileDevice() ? "0% 10%,100% 30%" : "0% 5%,100% 95%",
    // backgroundSize: isMobileDevice() ? "18%, 12%" : "8%, auto",
    // top: 0,
    // overflow: "auto",
  },
  appBar: {
    backgroundColor: "#17174c",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  marginTop: {
    marginTop: 20,
  },
  cardBorder: {
    borderRadius: "8px 0 8px 8px",
    padding: 15,
  },
  media: {
    height: 0,
    paddingTop: "75%", // 4:3
    backgroundSize: "85% 100%",
  },
  reLativeCard: {
    position: "relative",
  },
  overlay: {
    top: -23,
    right: -29,
    position: "absolute",
    width: 120,
    height: 120,
  },
  clickableCard: {
    cursor: "pointer",
  },
  svgIcon: {
    color: "white",
    fill: "white",
    stroke: "#0e1c4e",
    width: "0.75rem !important",
    "&.selected": {
      fill: "#0e1c4e",
    },
  },
  svgIconButton: {
    padding: 6,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const isMobileDevice = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const isTabletDevice = () => {
  let check = false;
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    check = true;
  }

  return check;
};

function SearchFieldPaper({ children }) {
  return (
    <Paper
      //   elevation={isMobileDevice() ? 0 : 3}
      style={{
        fontStyle: "italic",
        paddingLeft: isMobileDevice() ? 16 : 25,
        paddingTop: 10,
        paddingBottom: isMobileDevice() && 10,
        border: isMobileDevice() && "1px solid #17174C1A",
      }}
    >
      {children}
    </Paper>
  );
}

function ServicesTextfield({
  services,
  searchedService,
  classes,
  setSearchedService,
  getAllStorefronts,
  storeFrontList,
}) {
  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };
  const updateServiceList = debounce((event) => {
    getAllStorefronts(event.target.value);
  }, 1000);
  return (
    <Autocomplete
      options={storeFrontList || []}
      value={searchedService}
      freeSolo
      getOptionLabel={(option) => option || ""}
      id="find-services"
      renderInput={(params) => (
        <TextField
          {...params}
          margin="normal"
          className={clsx(classes.inputs, classes.textInput)}
          placeholder="What service are you looking for?"
          onChange={(event, value) => {
            event.persist();
            updateServiceList(event);
            setSearchedService(event.target.value);
          }}
        />
      )}
      onChange={(event, value) => {
        setSearchedService(value);
      }}
      noOptionsText={"Type in to search"}
      getOptionSelected={(option, value) => {
        return option === value;
      }}
    />
  );
}

function CitiesTextfield({ cities, searchedCity, classes, setSearchedCity }) {
  return (
    <Autocomplete
      options={cities || []}
      value={searchedCity}
      getOptionLabel={(option) => option.name || ""}
      id="city"
      debug
      renderInput={(params) => (
        <TextField
          {...params}
          margin="normal"
          className={clsx(classes.inputs, classes.textInput)}
          placeholder="Which city?"
        />
      )}
      filterOptions={(options, { inputValue }) => {
        if (inputValue.length >= 2) {
          return matchSorter(options, inputValue, {
            keys: ["name"],
          });
        } else {
          return [];
        }
      }}
      onChange={(event, value) => {
        setSearchedCity(value);
      }}
      noOptionsText={"Type in to search"}
      getOptionSelected={(option, value) => {
        return option.name === value.name;
      }}
    />
  );
}

function SearchIconGrid({ searchedService, searchedCity, classes }) {
  return (
    <Grid
      container
      item
      xs={1}
      lg={2}
      justifyContent={isMobileDevice() ? "center" : "flex-end"}
      alignContent="center"
    >
      <Link to="store_front_search" spy={true} smooth={true} offset={-100}>
        {isMobileDevice() || isTabletDevice() ? (
          <ButtonBase
            disabled={isEmpty(searchedService) || isEmpty(searchedCity)}
            onClick={() => {
              window.open(
                `https://app.souqh.ca/marketplace?websiteSearchedServiceId=${searchedService}&websiteSearchedCityId=${searchedCity.id}`
              );
            }}
          >
            <SearchIcon
              className={clsx(classes.primarColor, {
                [classes.disabledSearch]:
                  isEmpty(searchedService) || isEmpty(searchedCity),
              })}
            />
          </ButtonBase>
        ) : (
          <Button
            disabled={isEmpty(searchedService) || isEmpty(searchedCity)}
            onClick={() => {
              window.open(
                `https://app.souqh.ca/marketplace?websiteSearchedServiceId=${searchedService}&websiteSearchedCityId=${searchedCity.id}`
              );
            }}
            className={WebStyles.navyBlueColorForce}
            endIcon={<SearchIcon />}
            style={{
              backgroundColor: "#66D7D1",
              textTransform: "capitalize",
              marginTop: "-10px",
              padding: "9px 12px",
              marginRight: "-15px",
            }}
          >
            Search
          </Button>
        )}
      </Link>
    </Grid>
  );
}

export default function ExploreSP({ lgUp, mdUp, xlUp }) {
  const classes = useStyles();
  const { query } = useSQQuery();

  const {
    searchedService,
    searchedCity,
    services,
    cities,
    getCitiesList,
    carouselLimit,
    displayServicesList,
    handleNext,
    handleBack,
    lastIndexServices,
    getServicesList,
    getFeaturedStorefrontsForWebsite,
    featuredStoreFrontsForWebsite,
    getAllStorefronts,
    storeFrontList,
    getFeaturedStorefrontsAddon,
    featuredStoreFrontsAddon,
    addImpression,
    actions: { setFilterActive, setSearchedService, setSearchedCity },
  } = useExplore();

  const [featuredStoreFronts, setFeaturedStoreFronts] = useState([]);

  const numberOfCards = isMobileDevice()
    ? 1
    : isTabletDevice()
    ? 2
    : xlUp
    ? 6
    : 4;

  const { shuffledItems, activeIndex, setActiveIndex } = useFeaturedStoreFront(
    featuredStoreFronts,
    numberOfCards
  );

  useEffect(() => {
    let storeFronts = [
      ...featuredStoreFrontsForWebsite,
      ...featuredStoreFrontsAddon,
    ];
    if (searchedService) {
      storeFronts = storeFronts.filter(
        (storeFront) => storeFront.businessTypes?.[0].name === searchedService
      );
    }
    setFeaturedStoreFronts(storeFronts);
  }, [
    featuredStoreFrontsForWebsite,
    featuredStoreFrontsAddon,
    searchedService,
  ]);

  useEffect(() => {
    getServicesList();
    getCitiesList();
    getFeaturedStorefrontsForWebsite();
    getFeaturedStorefrontsAddon();
    setSearchedCity({ id: 1, name: "Toronto" });
    addImpression("Website");
  }, []);

  useEffect(() => {
    const websiteSearchedServiceId = query.get("websiteSearchedServiceId");
    if (services && services.length && websiteSearchedServiceId) {
      setSearchedService(
        filter(services, (sp) => sp.id == websiteSearchedServiceId)[0]
      );
    }
  }, [services.length]);

  const searchedCityId = searchedCity && searchedCity.id;
  useEffect(() => {
    if (searchedService && searchedCity) {
      window.open(
        `https://app.souqh.ca/marketplace?websiteSearchedServiceId=${searchedService}&websiteSearchedCityId=${searchedCity.id}`
      );
    }
  }, [searchedService, searchedCityId]);

  // let splicedSFList = xlUp
  //   ? featuredStoreFronts.slice(0, 6)
  //   : isMobileDevice() || isTabletDevice()
  //   ? featuredStoreFronts.slice(0, 5)
  //   : featuredStoreFronts.slice(0, 4);

  // const [currentIndex, setCurrentIndex] = useState(0);
  // const sliceFactor = lgUp ? 0 : mdUp ? 2 : 1;
  // let formattedArray = sliceFactor
  //   ? splicedSFList.slice(currentIndex, currentIndex + sliceFactor)
  //   : splicedSFList;

  return (
    <Grid
      container
      item
      xs={12}
      className={
        !isMobileDevice() &&
        !isTabletDevice() &&
        clsx(classes.bodyBackgroundImg)
      }
    >
       

        <Grid
          item
          xs={12}
          style={{
            // padding: isMobileDevice()
            //   ? "10px 0px 0px 0px "
            //   : isTabletDevice()
            //   ? "0px 80px 20px 80px"
            //   : "0px 100px 20px 100px",
            paddingBottom: "50px",
            color: "#0e1c4e",
            width: !isMobileDevice() && "100%",
            maxWidth: window.screen.width,
          }}
        >
          <Grid item container xs={12}>
            <Grid item container xs={12}>
              {featuredStoreFronts.length ?<Grid
                className={clsx(
                  classes.primarColor,
                  WebStyles.hankrndFont,
                  WebStyles.samonHeaderTxt
                )}
                style={{
                  fontWeight: 700,
                  fontSize: isMobileDevice() ? "1rem" : "1.5rem",
                  lineHeight: 1.6,
                  marginBottom: isMobileDevice() ? 20 : 10,
                }}
              >
                Featured Storefronts
              </Grid> : null}
              <Grid
                container
                item
                xs={12}
                spacing={isMobileDevice() ? 0 : 3}
                className={classes.marginTop10}
              >
              
                <Grid container xs={12}>
                  {shuffledItems.length
                    ? shuffledItems.map((data, index) => {
                        let webAddress = JSON.parse(data.storeFrontContent)
                          .webPresence.companyWebsite;
                        return (
                          <Grid
                            key={index}
                            item
                            container={
                              (isMobileDevice() || isTabletDevice()) && true
                            }
                            xs={12}
                            md={6}
                            lg={3}
                            xl={2}
                            style={{
                              marginBottom: isMobileDevice() && 16,
                              justifyContent:
                                (isMobileDevice() || isTabletDevice()) &&
                                "center",
                              paddingRight: isMobileDevice() && 4,
                              padding: 12,
                            }}
                          >
                            <Card
                              raised
                              className={clsx(
                                classes.cardBorder,
                                classes.primarColor,
                                classes.reLativeCard,
                                classes.clickableCard
                              )}
                              style={{
                                backgroundColor: "#66D7D140",
                                width:
                                  (isMobileDevice() || isTabletDevice()) &&
                                  "100%",
                              }}
                              onClick={() => {
                                const storeFrontId = data.storeFrontId;
                                const businessName =
                                  data.businessName.replaceAll("/", "-");
                                if (storeFrontId && businessName) {
                                  let newPath = "";
                                  newPath = `https://app.souqh.ca/storefront/${data.storeFrontRouteName}`;
                                  window.open(newPath, "_blank");
                                }
                              }}
                            >
                              <div className={classes.overlay}>
                                {data.primarySubscriptionPlanName ===
                                "Premium" ? (
                                  <img
                                    style={{ width: "100%", height: "100%" }}
                                    src="/images/Premium-Partner.svg"
                                  />
                                ) : (
                                  <img
                                    style={{ width: "100%", height: "100%" }}
                                    src="/images/Verified-Partner.svg"
                                  />
                                )}
                              </div>
                              <Grid
                                item
                                // style={{ border: "1px solid #17174C1F" }}
                              >
                                <CardMedia
                                  className={classes.media}
                                  image={
                                    JSON.parse(data.storeFrontContent)
                                      .displayPicture || "/images/logo.svg"
                                  }
                                  title=""
                                />
                              </Grid>
                              <Grid
                                container
                                item
                                justifyContent="space-between"
                                style={{ marginTop: 10 }}
                              >
                                <Grid item>
                                  <PublicIcon
                                    style={{
                                      fill: webAddress
                                        ? "#0e1c4e"
                                        : "#0E1C4E42",
                                      cursor: webAddress
                                        ? "pointer"
                                        : "default",
                                    }}
                                    onClick={(event) => {
                                      event.stopPropagation();
                                      if (webAddress) {
                                        window.open(webAddress, "_blank");
                                      }
                                    }}
                                  />
                                </Grid>
                                <Grid item>
                                  <MessageIcon />
                                </Grid>
                                <Grid item>
                                  <DateRangeIcon />
                                </Grid>
                                <Grid item>
                                  <RoomIcon />
                                </Grid>
                                <Grid item>
                                  <ShareIcon
                                    className={WebStyles.navyBlueColor}
                                    style={{ cursor: "pointer" }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid item>
                                <Tooltip title={data.storeFrontName}>
                                  <Typography
                                    className={WebStyles.sqEllipsis}
                                    style={{
                                      fontSize: 16,
                                      fontWeight: 700,
                                      textAlign: "left",
                                    }}
                                  >
                                    {data.storeFrontName}
                                  </Typography>
                                </Tooltip>
                              </Grid>
                              <Grid item>
                                <Tooltip title={data.businessName}>
                                  <Typography
                                    className={clsx(
                                      WebStyles.font14,
                                      WebStyles.sqEllipsis
                                    )}
                                    style={{ textAlign: "left" }}
                                  >
                                    {data.businessName}
                                  </Typography>
                                </Tooltip>
                              </Grid>
                              <Grid item>
                                <Tooltip title={data.businessTypeName}>
                                  <Typography
                                    className={clsx(
                                      WebStyles.font14,
                                      WebStyles.sqEllipsis
                                    )}
                                    style={{ textAlign: "left" }}
                                  >
                                    {data.businessTypes[0].name}
                                  </Typography>
                                </Tooltip>
                              </Grid>
                              {/* <Grid
                              item
                              container
                              spacing={1}
                              alignItems="center"
                            >
                              <Grid item>
                                <Rating
                                  name="rating"
                                  value={0}
                                  precision={0.5}
                                  className={classes.primarColor}
                                  readOnly
                                />
                              </Grid>
                              <Grid item>
                                <Typography
                                  className={clsx(
                                    classes.secondarColor,
                                    WebStyles.font14
                                  )}
                                >
                                  {data.reviewsCount} Reviews
                                </Typography>
                              </Grid>
                            </Grid> */}
                            </Card>
                          </Grid>
                        );
                      })
                    : null}
                  {featuredStoreFronts.length &&
                  featuredStoreFronts.length / numberOfCards > 1 ? (
                    <Grid
                      style={{
                        justifyContent: "center",
                        marginTop: 10,
                      }}
                      container
                      item
                      xs={12}
                    >
                      {Array(
                        Math.ceil(featuredStoreFronts.length / numberOfCards)
                      )
                        .fill(1)
                        .map((item, index) => (
                          <IconButton
                            classes={{ root: classes.svgIconButton }}
                            onClick={() => setActiveIndex(index)}
                          >
                            <CircleIcon
                              classes={{ root: classes.svgIcon }}
                              className={
                                activeIndex === index ? "selected" : ""
                              }
                            />
                          </IconButton>
                        ))}
                    </Grid>
                  ) : null}
                </Grid>
                {/* <Hidden lgUp>
                  <Grid
                    item
                    xs={1}
                    container
                    style={{
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <ArrowForwardIosIcon
                      onClick={() => {
                        if (
                          currentIndex <
                          featuredStoreFronts.length - sliceFactor
                        ) {
                          setCurrentIndex(currentIndex + 1);
                        }
                      }}
                      style={{
                        width: 36,
                        height: 36,
                        fontSize: 36,
                        cursor:
                          currentIndex <
                          featuredStoreFronts.length - sliceFactor.length - 1
                            ? "pointer"
                            : "default",
                        fill: "#0e1c4e",
                        opacity:
                          currentIndex <
                          featuredStoreFronts.length - sliceFactor.length - 1
                            ? 1
                            : "0.5",
                      }}
                    />
                  </Grid>
                </Hidden> */}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
     
    </Grid>
  );
}
