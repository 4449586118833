import { Button, Grid, TextField, Typography } from "@material-ui/core";
import React from "react";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";

export default function BusinessTypeItem({ data, mdUp }) {
  return (
    <Grid
      item
      container
      xs={12}
      style={{ marginBottom: 24, justifyContent: "center" }}
    >
      <Grid
        item
        container
        xs={12}
        style={{ justifyContent: "center", height: "100px" }}
      >
        <img
          style={{ maxWidth: "100%", height: "100%" }}
          src={data.image}
          alt={data.name}
        ></img>
      </Grid>
      <Grid item container xs={12} style={{ justifyContent: "center" }}>
        <Grid
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 28 : 14,
            lineHeight: mdUp ? "34px" : "16px",
            textAlign: "center",
            whiteSpace: "pre",
            fontWeight: 600,
          }}
        >
          {data.name}
        </Grid>
      </Grid>
    </Grid>
  );
}
