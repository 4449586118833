import { cloneDeep, filter } from "lodash";
import {
  SET_DISPLAY_PICTURE,
  SET_BANNER_PICTURE,
  ADD_SOCIAL_LINK,
  REMOVE_SOCIAL_LINK,
  UPDATE_SOCIAL_LINK,
  SET_PRIMARY_COLOR,
  SET_SECONDARY_COLOR,
  SET_COMPETE_TEXT,
  SET_COMPANY_OVERVIEW,
  SET_COMPANY_WEBSITE,
  ADD_SERVICE,
  REMOVE_SERVICE,
  UPDATE_SERVICE,
  ADD_DOCUMENT,
  REMOVE_DOCUMENT,
  UPDATE_DOCUMENT,
  SET_REVIEW_FIELD,
  ADD_REVIEW,
  UPDATE_REVIEW,
  REMOVE_REVIEW,
  ADD_C_PAYMENT,
  REMOVE_C_PAYMENT,
  UPDATE_C_PAYMENT,
  SET_STOREFRONT_DATA,
  SET_STOREFRONT_ID,
  UPDATE_CREDIT_CARD_INFO,
  UPDATE_PAYPAL_INFO,
  UPDATE_WIRE_TRANSFER_INFO,
  SET_CUSTOMER_PAYMENTS,
  UPDATE_PAYMENTS_METHODS,
  UPDATE_E_TRANSFER_INFO,
  UPDATE_CHEQUE_INFO,
  RESET_INFO,
  UPDATE_OTHER_INFO,
  RESET_OTHER_INFO,
  ADD_OTHER_PAYMENT_METHOD,
  DELETE_OTHER_PAYMENT_METHOD,
  ADD_MARKETING_RELATED_FILES,
  DELETE_MARKETING_RELATED_FILE,
  SAVE_INFO,
  CLICK_EDIT,
  CLICK_DELETE,
  SAVE_OTHER_PAYMENT_METHOD,
  REORDER_SERVICE,
  SET_NUM_EMPLOYEES,
  SET_NUM_YRS_BUSINESS,
  SET_STORE_FRONT_ROUTE_NAME,
  INITIAL_STATE_STORE,
  SET_STORE_FRONT_NAME,
  SET_EXTERNAL_LINKS_SUPPORT,
  SET_EXTERNAL_REVIEW_SUPPORT,
} from "./actionConstants";

const defaultService = {
  name: "",
  image: "",
  description: "",
};
const defaultReview = { title: "", link: "" };
const defaultCustomerPayments = {
  creditCardInfo: {
    paymentMode: "Credit/Debit Card",
    visibility: false,
    config: {},
  },
  paypalInfo: {
    paymentMode: "Paypal",
    visibility: false,
    config: {},
  },
  wireTransferInfo: {
    paymentMode: "Wire Transfer",
    selected: false,
    config: {
      payableTo: "",
      firmAddress: "",
      institution: "",
      institutionNumber: "",
      canadianClearingCode: "",
      branchNumber: "",
      accountNumber: "",
      swiftCode: "",
    },
  },
  eTransferInfo: {
    paymentMode: "E Transfer",
    selected: false,
    config: {
      email: "",
      preferredPassword: "",
      maxAmount: "",
      mobileNumber: "",
    },
  },
  chequeInfo: {
    paymentMode: "Cheque",
    selected: false,
    config: {
      name: "",
      address: "",
    },
  },
  otherInfo: {
    paymentMode: "Other",
    selected: false,
    config: [],
  },
  paymentMethods: [],
};
const creditCard = {
  paymentMode: "Credit/Debit Card",
  visibility: false,
};
const initialState = {
  storeFrontId: null, // This is hack for now until we get exact store front id for service provider amongst list of storefronts array
  displayPicture: "",
  bannerPicture: "",
  primaryColor: "#0e1c4e",
  secondaryColor: "#FA7E61",
  competeText: "",
  companyOverview: "",
  webPresence: {
    companyWebsite: "",
    socialLinks: [],
  },
  services: [],
  documents: [],
  reviews: [{ ...defaultReview }],
  paymentsVisible: false,
  customerPayments: {
    ...defaultCustomerPayments,
  },
  marketingRelatedDocs: [],
  yearsInBusiness: "",
  numberOfEmployees: "",
  storeFrontRouteName: "",
  storefrontName: "",
  externalLinksSupport: false,
  externalReviewSupport: false,
};

const storefront = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case SET_STOREFRONT_DATA:
      return action.payload.storeFront;

    case SET_STOREFRONT_ID:
      newState = cloneDeep(state);
      newState.storeFrontId = action.payload.storeFrontId;
      return newState;

    case SET_DISPLAY_PICTURE:
      newState = cloneDeep(state);
      newState.displayPicture = action.payload.displayPicture;
      return newState;
    case SET_BANNER_PICTURE:
      newState = cloneDeep(state);
      newState.bannerPicture = action.payload.bannerPicture;
      return newState;
    case SET_PRIMARY_COLOR:
      newState = cloneDeep(state);
      newState.primaryColor = action.payload.primaryColor;
      return newState;
    case SET_SECONDARY_COLOR:
      newState = cloneDeep(state);
      newState.secondaryColor = action.payload.secondaryColor;
      return newState;
    case SET_COMPETE_TEXT:
      newState = cloneDeep(state);
      newState.competeText = action.payload.competeText;
      return newState;
    case SET_COMPANY_OVERVIEW:
      newState = cloneDeep(state);
      newState.companyOverview = action.payload.companyOverview;
      return newState;
    case SET_COMPANY_WEBSITE:
      newState = cloneDeep(state);
      newState.webPresence.companyWebsite = action.payload.companyWebsite;
      return newState;
    case ADD_SOCIAL_LINK:
      newState = cloneDeep(state);
      newState.webPresence.socialLinks.push(action.payload.link);
      return newState;
    case REMOVE_SOCIAL_LINK:
      newState = cloneDeep(state);
      newState.webPresence.socialLinks.splice(action.payload.index, 1);
      return newState;
    case UPDATE_SOCIAL_LINK:
      newState = cloneDeep(state);
      newState.webPresence.socialLinks[action.payload.index] =
        action.payload.link;
      return newState;
    case ADD_SERVICE:
      newState = cloneDeep(state);
      newState.services.push(action.payload.service);
      return newState;
    case REMOVE_SERVICE:
      newState = cloneDeep(state);
      newState.services.splice(action.payload.index, 1);
      return newState;
    case UPDATE_SERVICE:
      newState = cloneDeep(state);
      newState.services[action.payload.index] = {
        ...newState.services[action.payload.index],
        ...action.payload.service,
      };
      return newState;
    case REORDER_SERVICE:
      newState = cloneDeep(state);
      newState.services = action.payload;
      return newState;
    case ADD_DOCUMENT:
      newState = cloneDeep(state);
      newState.documents.push(action.payload.document);
      return newState;
    case REMOVE_DOCUMENT:
      newState = cloneDeep(state);
      newState.documents.splice(action.payload.index, 1);
      return newState;
    case UPDATE_DOCUMENT:
      newState = cloneDeep(state);
      newState.documents[action.payload.index] = action.payload.document;
      return newState;
    case SET_REVIEW_FIELD:
      newState = cloneDeep(state);
      const reviews = newState.reviews; //.push(action.payload.review);
      const { index, field, value } = action.payload;
      reviews[index][field] = value;
      return newState;
    case ADD_REVIEW:
      newState = cloneDeep(state);
      newState.reviews.push({ ...defaultReview });
      return newState;
    case REMOVE_REVIEW:
      newState = cloneDeep(state);
      newState.reviews.splice(action.payload.index, 1);
      return newState;
    case UPDATE_REVIEW:
      newState = cloneDeep(state);
      newState.reviews[action.payload.index] = {
        ...newState.reviews[action.payload.index],
        ...action.payload.review,
      };
      return newState;
    case ADD_C_PAYMENT:
      newState = cloneDeep(state);
      newState.customerPayments.push(action.payload.payment);
      return newState;
    case SET_CUSTOMER_PAYMENTS:
      newState = cloneDeep(state);
      newState.paymentsVisible = action.payload;
      if (!action.payload) {
        newState.customerPayments = defaultCustomerPayments;
      }
      return newState;
    case UPDATE_PAYMENTS_METHODS:
      newState = cloneDeep(state);
      newState.customerPayments.paymentMethods = action.payload;
      const finalArray = filter(newState.customerPayments, (value) =>
        value.hasOwnProperty("selected")
      );
      Object.keys(finalArray).map((key, index) => {
        finalArray[key].selected = false;
        action.payload.map((selectedMethod) => {
          if (selectedMethod.name === finalArray[key].paymentMode) {
            finalArray[key].selected = true;
            return;
          }
        });
      });
      return newState;
    case REMOVE_C_PAYMENT:
      newState = cloneDeep(state);
      newState.customerPayments.splice(action.payload.index, 1);
      return newState;
    case UPDATE_C_PAYMENT:
      newState = cloneDeep(state);

      const toUpdate = cloneDeep(
        newState.customerPayments[action.payload.index]
      );
      newState.customerPayments[action.payload.index] = {
        ...toUpdate,
        ...action.payload.customerPayment,
      };
      return newState;
    case UPDATE_CREDIT_CARD_INFO:
      newState = cloneDeep(state);
      const { creditCardInfo } = newState.customerPayments;
      const { field: cField, value: cValue } = action.payload;
      creditCardInfo[cField] = cValue;
      if (!cValue) {
        creditCardInfo.config = [];
      }
      return newState;
    case UPDATE_PAYPAL_INFO:
      newState = cloneDeep(state);
      const { paypalInfo } = newState.customerPayments;
      const { field: pField, value: pValue } = action.payload;
      paypalInfo[pField] = pValue;
      if (!pValue) {
        paypalInfo.config = { email: "" };
      }
      return newState;
    case UPDATE_WIRE_TRANSFER_INFO:
      newState = cloneDeep(state);
      const { wireTransferInfo } = newState.customerPayments;
      const { field: wField, value: wValue } = action.payload;
      if (wField === "config") {
        wireTransferInfo.config = { ...wireTransferInfo.config, ...wValue };
      } else {
        wireTransferInfo[wField] = wValue;
      }
      return newState;
    case UPDATE_E_TRANSFER_INFO:
      newState = cloneDeep(state);
      const { eTransferInfo } = newState.customerPayments;
      eTransferInfo.config = { ...eTransferInfo.config, ...action.payload };
      return newState;
    case SAVE_INFO:
      newState = cloneDeep(state);
      const otherPayments = filter(newState.customerPayments, (value) =>
        value.hasOwnProperty("selected")
      );
      Object.keys(otherPayments).map((key) => {
        if (action.payload.name === otherPayments[key].paymentMode) {
          otherPayments[key].config = {
            ...otherPayments[key].config,
            editMode: true,
          };
          return;
        }
      });
      return newState;
    case CLICK_EDIT:
      newState = cloneDeep(state);
      const payments = filter(newState.customerPayments, (value) =>
        value.hasOwnProperty("selected")
      );
      Object.keys(payments).map((key) => {
        if (action.payload.name === payments[key].paymentMode) {
          payments[key].config.editMode = false;
          return;
        }
      });
      return newState;
    case CLICK_DELETE:
      newState = cloneDeep(state);
      newState.customerPayments.paymentMethods =
        newState.customerPayments.paymentMethods.filter(
          (value) => value.id !== action.payload.id
        );
      const cPayments = filter(newState.customerPayments, (value) =>
        value.hasOwnProperty("selected")
      );
      Object.keys(cPayments).map((key) => {
        if (action.payload.name === cPayments[key].paymentMode) {
          cPayments[key].selected = false;
          Object.keys(cPayments[key].config).map(
            (nestedkey) => (cPayments[key].config[nestedkey] = "")
          );
          return;
        }
      });
      return newState;
    case UPDATE_CHEQUE_INFO:
      newState = cloneDeep(state);
      const { chequeInfo } = newState.customerPayments;
      chequeInfo.config = { ...chequeInfo.config, ...action.payload };
      return newState;
    case RESET_INFO:
      newState = cloneDeep(state);
      const resetPayments = filter(newState.customerPayments, (value) =>
        value.hasOwnProperty("selected")
      );
      Object.keys(resetPayments).map((key) => {
        if (action.payload.name === resetPayments[key].paymentMode) {
          Object.keys(resetPayments[key].config).map(
            (paymentKey) => (resetPayments[key].config[paymentKey] = "")
          );
          return;
        }
      });
      return newState;
    case UPDATE_OTHER_INFO:
      newState = cloneDeep(state);
      const { otherInfo } = newState.customerPayments;
      const { index: oIndex, methodName, methodDetails } = action.payload;
      otherInfo.config[oIndex] = { methodName, methodDetails };
      return newState;
    case RESET_OTHER_INFO:
      newState = cloneDeep(state);
      const { index: roIndex, value: roValue } = action.payload;
      newState.customerPayments.otherInfo.config[roIndex] =
        action.payload.roValue;
      return newState;
    case SAVE_OTHER_PAYMENT_METHOD:
      newState = cloneDeep(state);
      const { index: otherIndex } = action.payload;
      newState.customerPayments.otherInfo.config[otherIndex].editMode = true;
      return newState;
    case ADD_OTHER_PAYMENT_METHOD:
      newState = cloneDeep(state);
      newState.customerPayments.otherInfo.config.push({ ...action.payload });
      return newState;
    case DELETE_OTHER_PAYMENT_METHOD:
      newState = cloneDeep(state);
      newState.customerPayments.otherInfo.config.splice(action.payload, 1);
      return newState;
    case ADD_MARKETING_RELATED_FILES:
      newState = cloneDeep(state);
      if (!newState.marketingRelatedDocs) newState.marketingRelatedDocs = [];
      const { files, maxFileSize, maxFileLimit } = action.payload;
      if (!maxFileSize && !maxFileLimit) {
        Object.keys(files).map((key) => {
          newState.marketingRelatedDocs.push(files[key]);
        });
      }
      newState.maxFileSize = maxFileSize;
      newState.maxFileLimit = maxFileLimit;
      return newState;
    case DELETE_MARKETING_RELATED_FILE:
      newState = cloneDeep(state);
      newState.marketingRelatedDocs.splice(action.payload, 1);
      return newState;
    case SET_NUM_YRS_BUSINESS:
      newState = cloneDeep(state);
      newState.yearsInBusiness = action.payload;
      return newState;
    case SET_NUM_EMPLOYEES:
      newState = cloneDeep(state);
      newState.numberOfEmployees = action.payload;
      return newState;
    case SET_STORE_FRONT_ROUTE_NAME:
      newState = cloneDeep(state);
      newState.storeFrontRouteName = action.payload;
      return newState;
    case SET_STORE_FRONT_NAME:
      newState = cloneDeep(state);
      newState.storefrontName = action.payload;
      return newState;
    case SET_EXTERNAL_LINKS_SUPPORT:
      newState = cloneDeep(state);
      newState.externalLinksSupport = action.payload.check;
      return newState;
    case SET_EXTERNAL_REVIEW_SUPPORT:
      newState = cloneDeep(state);
      newState.externalReviewSupport = action.payload.check;
      return newState;
    case INITIAL_STATE_STORE:
      return initialState;
    default:
      return state;
  }
};
export default storefront;
