import React, { useEffect } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import SubFooter from "../SubFooter";
import OurCompany from "./OurCompany";
import OurTeam from "./OurTeam";
import ConpanyOverview from "./ConpanyOverview";
import InvestorsSection from "./InvestorsSection";

function AboutUsPage() {
  const title = "Why Wait?";
  const subTitle =
    "We designed Souqh to help eliminate the stress in the home journey.";
  const btnText = "Sign up now for free!";
  const redirectUrl = "https://app.souqh.ca/";
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));

  const commonPadding = lgUp ? "0px 10%" : "0px 2%";

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div style={{ paddingBottom: 32, overflow: "hidden" }}>
      <OurCompany
        smUp={smUp}
        mdUp={mdUp}
        lgUp={lgUp}
        xlUp={xlUp}
        commonPadding={commonPadding}
      />
      <OurTeam
        smUp={smUp}
        mdUp={mdUp}
        lgUp={lgUp}
        xlUp={xlUp}
        commonPadding={commonPadding}
      />
      <ConpanyOverview
        smUp={smUp}
        mdUp={mdUp}
        lgUp={lgUp}
        xlUp={xlUp}
        commonPadding={commonPadding}
      />
      <InvestorsSection
        smUp={smUp}
        mdUp={mdUp}
        lgUp={lgUp}
        xlUp={xlUp}
        smDown={smDown}
        mdDown={mdDown}
        commonPadding={commonPadding}
      />
      <SubFooter
        title={title}
        subTitle={subTitle}
        btnText={btnText}
        redirectUrl={redirectUrl}
      />
    </div>
  );
}

export default AboutUsPage;
