import * as moment from "moment";
import {
  APPROVED,
  DATE_COLUMNS_TO_SORT,
  DRAFT,
  PREMIUM,
  REQUESTED,
  SERVICE_PROVIDER_USER,
  TEAM_MEMBER_USER,
  PLUS,
  PRO,
  MARKETPLACE,
} from "../components/ServiceProvider/serviceProviderConstants";
import { REFERENCE_DOCUMENTS } from "../components/HomeBuyer/homeBuyerConstants";
import {
  ACTION_REQUIRED,
  ARCHIVED,
  COMPLETED,
  EXPIRING_SOON,
  HOME_BUYER_USER,
  MY_TEMPLATES,
  OTHER_DOCUMENTS,
  WAITING_FOR_OTHER,
} from "../components/ServiceProvider/serviceProviderConstants";
import { sortBy } from "lodash";

export const descComparator = (a, b, orderBy) => {
  if (DATE_COLUMNS_TO_SORT.includes(orderBy)) {
    return checkCondition(
      moment(b[orderBy]).format("yyyy-MM-DD"),
      moment(a[orderBy]).format("yyyy-MM-DD")
    );
  } else if (typeof a[orderBy] || typeof b[orderBy] === "string") {
    if (orderBy === "storeFrontName") {
      return checkCondition(
        b.storefrontDetails[orderBy].toLowerCase(),
        a.storefrontDetails[orderBy].toLowerCase()
      );
    } else if (orderBy === "starRating") {
      return checkCondition(b[orderBy], a[orderBy]);
    } else {
      return checkCondition(
        b[orderBy]?.toLowerCase(),
        a[orderBy]?.toLowerCase()
      );
    }
  } else if (typeof a[orderBy] || typeof b[orderBy] === "number") {
    return checkCondition(b[orderBy], a[orderBy]);
  }
};

const checkCondition = (condition1, condition2) => {
  if (condition1 < condition2) {
    return -1;
  }
  if (condition1 > condition2) {
    return 1;
  }
  return 0;
};

export const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

export const getComparator = (order, orderBy) => {
  return order === "desc"
    ? (a, b) => descComparator(a, b, orderBy)
    : (a, b) => -descComparator(a, b, orderBy);
};

export const getSelectedTabIndex = (userType, tabName) => {
  if (userType === HOME_BUYER_USER) {
    switch (tabName) {
      case ACTION_REQUIRED:
        return 0;
      case WAITING_FOR_OTHER:
        return 1;
      case EXPIRING_SOON:
        return 2;
      case COMPLETED:
        return 3;
      case ARCHIVED:
        return 4;
      case REFERENCE_DOCUMENTS:
        return 5;
      default:
        return 0;
    }
  } else {
    switch (tabName) {
      case ACTION_REQUIRED:
        return 0;
      case WAITING_FOR_OTHER:
        return 1;
      case EXPIRING_SOON:
        return 2;
      case COMPLETED:
        return 3;
      case ARCHIVED:
        return 4;
      case OTHER_DOCUMENTS:
        return 5;
      case MY_TEMPLATES:
        return 6;
      default:
        return 0;
    }
  }
};

export const setHBJourneyStatus = (stage) => {
  switch (stage) {
    case 0:
      return "FIND_YOUR_HOME";
    case 1:
      return "FUND_YOUR_HOME";
    case 2:
      return "CLOSE_YOUR_HOME";
    case 3:
      return "MOVE_INTO_YOUR_HOME";
    default:
      return "";
  }
};

export const parseFileName = (name) => {
  if (name) {
    const temp = (name || "").trim();
    if (temp.startsWith("http")) {
      return decodeURIComponent(temp).split("/").pop();
    }
    return temp.split("/").pop();
  }
  return "";
};

// Uplodeded document preview object for SP, client managemant
export const getUploadedDocuments = (docDetails) => {
  if (!docDetails) {
    return [];
  }
  let docName = docDetails.documentName
    ? docDetails.documentName
    : docDetails.docName;
  const fileName = decodeURIComponent(docName.replace(/^.*[\\\/]/, "")) || "";
  let docObj = {
    name: fileName,
    path: docDetails.documentPreviewUrl,
    type: fileName.split(".").pop(),
    documentId: docDetails.id,
  };
  return docObj;
};

export const getTimeSlotFromDate = (date) => {
  if (date && date.getTime) {
    let minutes = date.getMinutes();
    let hours = date.getHours();
    let noonString = "AM";

    if (hours === 12) {
      hours = hours;
      noonString = "PM";
    }
    if (hours === 0) {
      hours = 12;
      noonString = "AM";
    }
    if (hours > 12) {
      hours = hours - 12;
      noonString = "PM";
    }
    return hours + ":" + (minutes || "00") + " " + noonString;
  }
  return null;
};

export const getTimeAndHourFromSlot = (slot) => {
  if (!slot) {
    return {
      hours: 0,
      minutes: 0,
    };
  }
  // "12:00 AM"
  let temp = slot.split(" ");
  let timePart = (temp[0] || "").split(":");
  const dayPart = temp[1];
  let hours = +timePart[0] || 0;
  let minutes = +timePart[1] || 0;
  if ((dayPart || "").toUpperCase() === "PM") {
    if (hours < 12) {
      hours = 12 + hours;
    }
  } else {
    if (hours === 12) {
      hours = 0;
    }
  }
  return {
    hours,
    minutes,
  };
};

export const getTimeFromDate = (value) => {
  let timeString = "";
  if (value) {
    let date = value;
    if (!date.getTime) {
      date = new Date(value);
    }
    if (date.toString() === "Invalid Date") {
      return "";
    }
    let minutes = date.getMinutes();
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    let hours = date.getHours();
    let noonString = "am";
    if (hours === 12) {
      hours = hours;
      noonString = "pm";
    }
    if (hours === 0) {
      hours = 12;
      noonString = "am";
    }
    if (hours > 12) {
      hours = hours - 12;
      noonString = "pm";
    }
    timeString = hours + ":" + minutes + " " + noonString;
  }
  return timeString;
};

export const processDownloadFileResponse = (response) => {
  if (response) {
    let headerLine = response.headers["content-disposition"];
    let startFileNameIndex = headerLine.indexOf('"') + 1;
    let endFileNameIndex = headerLine.lastIndexOf('"');
    let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  }
};

export const isMobileDevice = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

export const isTabletDevice = () => {
  let check = false;
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    check = true;
  }

  return check;
};

export const viewClientDetails = (history, path, data) => {
  if (data.userId) {
    history.push(
      `${path}/details/${data.userId}?serviceRequestId=${data.serviceRequestId}`
    );
  }
};

export const getImgSrc = (row) => {
  return row.requestorSPDetails
    ? JSON.parse(row.requestorSPDetails.storeFrontContent).displayPicture
    : row.requestorHBDetails
    ? row.requestorHBDetails.avatarUrl
    : "/images/souqh_logo.svg";
};
export const getClientColumnValue = (row) => {
  return row.requestorSPDetails
    ? row.requestorSPDetails.storeFrontName
    : row.requestorHBDetails
    ? row.requestorHBDetails.name
    : "Souqh";
};

export const covertTimeStampToDate = (val) => {
  return moment(new Date(val)).format("MMM DD, YYYY");
};

export const getDateFromTimestamp = (val) => {
  return moment(new Date(val)).format("MMM DD");
};

export const getTimeFromTimestamp = (val) => {
  return moment(new Date(val)).format("hh:mm A");
};

export const covertTimeStampToDateTime = (val) => {
  return moment(new Date(val)).format("MMM DD, YYYY - hh:mm A");
};

export const covertTimeStampToDateTimeSeconds = (val) => {
  return moment(new Date(val)).format("MMM DD, YYYY - hh:mm:ss A");
};

export const abbreviateNumber = (number) => {
  const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

  // what tier? (determines SI symbol)
  var tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier == 0) return number;

  // get suffix and determine scale
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
};

export const hideItercomForMobileTablet = () => {
  const element = document.querySelector("body");
  // Hide intercom on Mobile and Tablet
  if (isMobileDevice() || isTabletDevice()) {
    if (element) {
      element.classList.add("hide-sq-intercom-icon");
    }
  } else {
    if (element) {
      element.classList.remove("hide-sq-intercom-icon");
    }
  }
};

export const isOnboardingInComplete = (userInfo) => {
  if (userInfo && userInfo.userType === TEAM_MEMBER_USER) {
    if (userInfo.subscriptionPlanName === PREMIUM) {
      return userInfo.spApprovalStatus !== APPROVED;
    }
  } else {
    if (userInfo && userInfo.userType === SERVICE_PROVIDER_USER) {
      return userInfo && userInfo.spApprovalStatus !== APPROVED;
    }
  }
};

export const isSpApprovalPending = (userInfo) => {
  return userInfo && userInfo.spApprovalStatus === REQUESTED;
};

export const formatContactNumber = (contactNumber) => {
  return contactNumber
    ? `${contactNumber && contactNumber.substring(0, 3)}-${
        contactNumber && contactNumber.substring(3, 6)
      }-${contactNumber && contactNumber.substring(6, contactNumber.length)}`
    : "-";
};

export const getDatesRange = (
  startDate,
  stopDate,
  steps,
  setAllArray,
  setSelectiveArray,
  isAllArray
) => {
  // const ONE_DAY = 24 * 3600 * 1000;
  const DAYS_GAP = steps * 24 * 3600 * 1000;
  var days_temp = [];
  var currentDate = new Date(startDate);
  while (currentDate <= stopDate) {
    days_temp.push(
      moment(new Date(currentDate)).format("MMM DD, YYYY").split(",")[0]
    );
    currentDate = currentDate - 1 + 1 + DAYS_GAP;
  }
  if (steps === 1 && isAllArray) {
    setAllArray(days_temp);
  } else {
    setSelectiveArray(days_temp);
  }
};

export const remove_AndFormValidString = (ele) => {
  return (
    ele &&
    ele
      .split("_")
      .join(" ")
      .replace(/\w/g, (l) => l.toLowerCase())
      .replace(/\b\w/g, (l) => l.toUpperCase())
  );
};

//for client details and SP details
export const getUploadedDocumentsForClientAndSPDetails = (docDetails) => {
  if (!docDetails) {
    return [];
  }

  const fileName =
    decodeURIComponent(docDetails.docName.replace(/^.*[\\\/]/, "")) || "";

  let docObj = {
    name: fileName,
    path: docDetails.documentPreviewUrl,
    type: fileName.split(".").pop(),
    isReferenceDocument: docDetails.isReferenceDocument,
    lastChange: docDetails.lastChange,
    documentId: docDetails.docId,
    signNowDocumentId: docDetails.signNowDocumentId,
  };
  return docObj;
};

export const isPlusPlan = (userInfo) => {
  if (userInfo.userType === SERVICE_PROVIDER_USER) {
    return userInfo.plan === PLUS;
  } else if (userInfo.userType === TEAM_MEMBER_USER)
    return userInfo.subscriptionPlanName === PLUS;
};

export const isProPlan = (userInfo) => {
  if (userInfo.userType === SERVICE_PROVIDER_USER) {
    return userInfo.plan === PRO;
  } else if (userInfo.userType === TEAM_MEMBER_USER)
    return userInfo.subscriptionPlanName === PRO;
};

export const isMarketplacePlan = (userInfo) => {
  if (userInfo.userType === SERVICE_PROVIDER_USER) {
    return userInfo.plan === MARKETPLACE;
  } else if (userInfo.userType === TEAM_MEMBER_USER)
    return userInfo.subscriptionPlanName === MARKETPLACE;
};
