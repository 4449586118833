import {
  Container,
  Grid,
  Popover,
  useMediaQuery,
  useTheme,
  Link as MaterialLink,
} from "@material-ui/core";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import WebStyles from "../WebStyles.module.css";
import clsx from "clsx";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

export const APP_BAR_HEIGHT_DESKTOP = 215;
export const APP_BAR_HEIGHT_MOBILE = 162;

export default function MegaMenuDropdown() {
  const theme = useTheme();
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Grid>
      <Grid container onClick={handleClick} style={{ marginRight: 20 }}>
        <Grid item>
          <MaterialLink
            className={clsx(WebStyles.nunitoFont)}
            // href="/services"
            style={{
              textDecoration: "none",
              color: "white",
              fontWeight: "bold",
              fontSize: 20,
              cursor: "pointer",
            }}
          >
            Services
          </MaterialLink>
        </Grid>
        <KeyboardArrowDownIcon style={{ color: "#FFF", marginTop: 2 }} />
      </Grid>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ paddingTop: 10 }}
      >
        <div style={{ padding: 15 }}>
          <Container>
            <Grid
              item
              container
              xs={12}
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                marginBottom: lgDown ? 24 : 0,
                position: "relative",

                zIndex: 1,
              }}
            >
              <Grid
                item
                container
                xs={12}
                md={6}
                style={{
                  paddingLeft: 20,
                  borderRight: "1px solid rgb(27 30 76 / 17%)",
                }}
                justify="flex-start"
                alignContent="center"
              >
                <Grid xs={12}>
                  <MaterialLink
                    href="/Real-Estate-Broker"
                    className={clsx(WebStyles.hankrndFont)}
                    style={{
                      color: "#1B1E4C",
                      fontSize: 16,
                      lineHeight: 2,
                      fontWeight: 400,
                    }}
                  >
                    Realtors
                  </MaterialLink>
                </Grid>
                <Grid xs={12}>
                  <MaterialLink
                    href="/Mortgage-Broker"
                    className={clsx(WebStyles.hankrndFont)}
                    style={{
                      color: "#1B1E4C",
                      fontSize: 16,
                      lineHeight: 2,
                      fontWeight: 400,
                    }}
                  >
                    Mortgage Broker / Agent
                  </MaterialLink>
                </Grid>
                <Grid xs={12}>
                  <MaterialLink
                    href="/Real-Estate-Lawyer"
                    className={clsx(WebStyles.hankrndFont)}
                    style={{
                      color: "#1B1E4C",
                      fontSize: 16,
                      lineHeight: 2,
                      fontWeight: 400,
                    }}
                  >
                    Real Estate Lawyer
                  </MaterialLink>
                </Grid>
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={6}
                style={{
                  paddingLeft: 20,
                }}
                justify="flex-start"
                alignContent="center"
              >
                <Grid
                  xs={12}
                  className={clsx(
                    WebStyles.paraBlackText,
                    WebStyles.nunitoFont
                  )}
                  style={{
                    color: "#1B1E4C",
                    fontSize: 16,
                    lineHeight: 2,
                    fontWeight: 400,
                  }}
                >
                  Property Inspector
                </Grid>
                <Grid xs={12}>
                  <MaterialLink
                    href="/Mover"
                    className={clsx(WebStyles.hankrndFont)}
                    style={{
                      color: "#1B1E4C",
                      fontSize: 16,
                      lineHeight: 2,
                      fontWeight: 400,
                    }}
                  >
                    Mover
                  </MaterialLink>
                </Grid>
                <Grid xs={12}>
                  <MaterialLink
                    href="/Handyman-Services"
                    className={clsx(WebStyles.hankrndFont)}
                    style={{
                      color: "#1B1E4C",
                      fontSize: 16,
                      lineHeight: 2,
                      fontWeight: 400,
                    }}
                  >
                    General Contractor
                  </MaterialLink>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      </Popover>
    </Grid>
  );
}
