import { Grid } from "@material-ui/core";
import React from "react";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import PricingBullet from "./PricingBullet";
import PriceAmountBox from "./PriceAmountBox";
import PlanRibbon from "./PlanRibbon";

export default function PricingBox({ mdUp, mdDown, data, smDown, planType }) {
  const envelopesCount = planType ? data.envelopes * 12 : data.envelopes;
  const bulletPoints = [
    "One-stop dashboard",
    "360º client management",
    "Chat/Video communication",
    "Built-in eCommerce",
    "Basic storefront",
    envelopesCount + " Document envelopes",
    "Calendar sync",
    "Priority support",
    "Badge",
    "Branded storefront",
    "Powerful data analytics",
    "Marketing campaigns",
  ];
  return (
    <Grid
      item
      container
      xs={11}
      style={{
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "3px 3px 10px #00000029",
        padding: "24px 16px",
      }}
    >
      <Grid
        item
        container
        xs={12}
        style={{
          justifyContent: "center",
          minHeight: mdDown ? 0 : 210,
          alignItems: "flex-end",
          marginBottom: 4,
        }}
      >
        <Grid
          item
          container
          xs={4}
          lg={12}
          style={{
            justifyContent: "center",
            height: "100%",
            alignItems: "flex-end",
          }}
        >
          <img
            src={data.image}
            style={
              mdDown
                ? {
                    width: "100%",
                    height: "100%",
                  }
                : {}
            }
          ></img>
        </Grid>
        {mdDown ? (
          <Grid
            item
            container
            xs={8}
            lg={12}
            className={clsx(WebStyles.barlowFont, WebStyles.paraBlackText)}
            style={{
              justifyContent: "flex-start",
              height: "100%",
              alignItems: "flex-start",
              display: "flex",
            }}
          >
            <Grid item xs={12}>
              <PlanRibbon data={data} smDown={smDown} />
            </Grid>
            <Grid item xs={12}>
              <PriceAmountBox
                data={data}
                mdUp={mdUp}
                mdDown={mdDown}
                planType={planType}
              />
            </Grid>
            <Grid item container xs={12} style={{ justifyContent: "center" }}>
              {planType ? (
                <Grid
                  style={{
                    fontSize: mdUp ? 21 : 11,
                    lineHeight: mdUp ? "25px" : "12px",
                    textAlign: "center",
                    backgroundColor: "#EBFAF9",
                    padding: "6px 32px",
                    borderRadius: 4,
                  }}
                >
                  {"Upto $" + data.saving + " Savings"}
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      {mdDown ? null : (
        <Grid
          item
          container
          justifyContent="center"
          xs={12}
          className={clsx(WebStyles.hankrndFont, WebStyles.paraBlackText)}
          style={{
            fontSize: mdUp ? 50 : 25,
            lineHeight: mdUp ? "60px" : "30px",
            marginBottom: mdUp ? 24 : 8,
            fontWeight: 600,
          }}
        >
          {data.name}
        </Grid>
      )}
      {/* {mdDown ? null : (
        <Grid
          item
          container
          xs={12}
          className={clsx(WebStyles.barlowFont, WebStyles.paraBlackText)}
          style={{
            fontSize: mdUp ? 21 : 25,
            lineHeight: mdUp ? "25px" : "30px",
            marginBottom: mdUp ? 12 : 8,
          }}
        >
          Starting at
        </Grid>
      )} */}

      <Grid
        item
        container
        xs={12}
        className={clsx(WebStyles.barlowFont, WebStyles.paraBlackText)}
        style={{ alignItems: "center", marginBottom: mdDown ? 0 : 32 }}
      >
        {mdDown ? null : (
          <PriceAmountBox
            data={data}
            mdUp={mdUp}
            mdDown={mdDown}
            planType={planType}
          />
        )}
        {planType && !mdDown ? (
          <Grid item container xs={12} style={{ justifyContent: "center" }}>
            <Grid
              style={{
                fontSize: mdUp ? 21 : 11,
                lineHeight: mdUp ? "25px" : "12px",
                textAlign: "center",
                backgroundColor: "#EBFAF9",
                padding: "6px 32px",
                borderRadius: 4,
              }}
            >
              {"Upto $" + data.saving + " Savings"}
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      <Grid item container xs={12}>
        {bulletPoints.map((item, index) => {
          return (
            <PricingBullet
              key={index}
              mdUp={mdUp}
              point={item}
              index={index}
              ticks={data.ticks}
            />
          );
        })}
      </Grid>
    </Grid>
  );
}
