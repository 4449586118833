import React, { useState } from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

export default function CompanyLogos({
  title,
  lgUp,
  smUp,
  mdUp,
  xsDown,
  commonPadding,
}) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const logoList = [
    {
      id: "1",
      imageUrl: "/images/service_providers/logo1.svg",
    },
    {
      id: "2",
      imageUrl: "/images/service_providers/logo2.svg",
    },
    {
      id: "3",
      imageUrl: "/images/service_providers/logo3.webp",
    },
    {
      id: "4",
      imageUrl: "/images/service_providers/logo4.svg",
    },
    {
      id: "5",
      imageUrl: "/images/service_providers/logo5.svg",
    },
    {
      id: "6",
      imageUrl: "/images/service_providers/logo6.svg",
    },
    {
      id: "7",
      imageUrl: "/images/service_providers/logo7.svg",
    },
  ];
  const sliceFactor = lgUp ? 3 : smUp ? 2 : 1;
  const formattedArray = logoList
    .slice(currentIndex, currentIndex + sliceFactor)
    .map((item, index) => {
      return (
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={4}
          key={index}
          style={{ padding: "0px 16px" }}
        >
          <img src={item.imageUrl} style={{ height: 100, width: "100%" }} />
        </Grid>
      );
    });
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: commonPadding,
      }}
    >
      <Container>
        <Container>
          <Grid
            item
            xs={12}
            lg={12}
            container
            style={{
              justifyContent: "center",
              paddingBottom: 28,
              alignItems: "center",
            }}
          >
            <div
              className={WebStyles.purpleTxt}
              style={{
                fontSize: mdUp ? 60 : 40,
                lineHeight: mdUp ? "74px" : "37px",
                textAlign: "center",
                marginBottom: 8,
                fontFamily: "sq-hank-rnd",
                fontWeight: 600,
              }}
            >
              {title}
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            container
            style={{ justifyContent: "center", paddingBottom: 40 }}
          >
            <img
              style={{ width: "121%" }}
              src="/images/home_page/who_is_using_souqh.png"
            />
          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}
