import {
  Grid,
  makeStyles,
  Tooltip,
  Typography,
  Card,
  CardMedia,
  IconButton,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { matchSorter } from "match-sorter";
import { isEmpty, isEqual, filter } from "lodash";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import clsx from "clsx";
import { useExplore } from "souqh-react-redux-hooks/homeBuyer/useExplore";
import { useSQQuery } from "souqh-react-redux-hooks/common/useSQQuery";
import WebStyles from "../WebStyles.module.css";
import PublicIcon from "@material-ui/icons/Public";
import MessageIcon from "@material-ui/icons/Message";
import DateRangeIcon from "@material-ui/icons/DateRange";
import RoomIcon from "@material-ui/icons/Room";
import ShareIcon from "@material-ui/icons/Share";
import CircleIcon from "@mui/icons-material/Circle";
import Rating from "@material-ui/lab/Rating";
import useFeaturedStoreFront from "souqh-react-redux-hooks/homeBuyer/useFeaturedStoreFront";
import { isMobileDevice, isTabletDevice } from "./WebUtils";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#0e1c4e0a",
  },
  primarColor: {
    color: "#0e1c4e",
  },
  secondarColor: {
    color: "#F86C6B",
  },
  marginTop10: {
    marginTop: 10,
  },
  bodyBackgroundImg: {
    // backgroundImage:
    //   "url('/images/Explore-Section/blue_bg_shape.svg'), url('/images/Explore-Section/orange_shape.svg')",
    // backgroundRepeat: "no-repeat, no-repeat",
    // backgroundPosition: isMobileDevice() ? "0% 10%,100% 30%" : "0% 5%,100% 95%",
    // backgroundSize: isMobileDevice() ? "18%, 12%" : "8%, auto",
    // top: 0,
    // overflow: "auto",
  },
  cardBorder: {
    borderRadius: "8px 0 8px 8px",
    padding: 15,
  },
  media: {
    height: 0,
    paddingTop: "75%", // 4:3
    backgroundSize: "85% 100%",
  },
  reLativeCard: {
    position: "relative",
  },
  overlay: {
    top: -23,
    right: -29,
    position: "absolute",
    width: 120,
    height: 120,
  },
  clickableCard: {
    cursor: "pointer",
  },
  svgIcon: {
    color: "white",
    fill: "white",
    stroke: "#0e1c4e",
    width: "0.75rem !important",
    "&.selected": {
      fill: "#0e1c4e",
    },
  },
  svgIconButton: {
    padding: 6,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
}));

export default function ServicesFeaturedSF({ lgUp, mdUp, xlUp, serviceName }) {
  const classes = useStyles();
  const { query } = useSQQuery();

  const {
    carouselLimit,
    displayServicesList,
    handleNext,
    handleBack,
    lastIndexServices,
    getServicesList,
    getFeaturedStorefrontsForWebsite,
    featuredStoreFrontsForWebsite,
    getAllStorefronts,
    storeFrontList,
    getFeaturedStorefrontsAddon,
    featuredStoreFrontsAddon,
    addImpression,
  } = useExplore();

  const [featuredStoreFronts, setFeaturedStoreFronts] = useState([]);

  const numberOfCards = isMobileDevice()
    ? 1
    : isTabletDevice()
    ? 2
    : xlUp
    ? 6
    : 4;

  const { shuffledItems, activeIndex, setActiveIndex } = useFeaturedStoreFront(
    featuredStoreFronts,
    numberOfCards
  );

  useEffect(() => {
    let storeFronts = [
      ...featuredStoreFrontsForWebsite,
      ...featuredStoreFrontsAddon,
    ];
    if (serviceName) {
      storeFronts = storeFronts.filter(
        (storeFront) => storeFront.businessTypes?.[0].name === serviceName
      );
    }

    setFeaturedStoreFronts(storeFronts);
  }, [
    featuredStoreFrontsForWebsite,
    featuredStoreFrontsAddon,
    // searchedService,
  ]);

  useEffect(() => {
    // getServicesList();
    // getCitiesList();
    getFeaturedStorefrontsForWebsite();
    getFeaturedStorefrontsAddon();
    // setSearchedCity({ id: 1, name: "Toronto" });
    addImpression("Website");
  }, []);

  return (
    <Grid
      container
      item
      xs={12}
      className={
        !isMobileDevice() &&
        !isTabletDevice() &&
        clsx(classes.bodyBackgroundImg)
      }
    >
      <Grid
        item
        xs={12}
        style={{
          // padding: isMobileDevice()
          //   ? "10px 0px 0px 0px "
          //   : isTabletDevice()
          //   ? "0px 80px 20px 80px"
          //   : "0px 100px 20px 100px",
          paddingBottom: "50px",
          color: "#0e1c4e",
          width: !isMobileDevice() && "100%",
          maxWidth: window.screen.width,
        }}
      >
        <Grid item container xs={12}>
          <Grid item container xs={12}>
            {featuredStoreFronts.length ? (
              <Grid
                className={clsx(
                  classes.primarColor,
                  WebStyles.hankrndFont,
                  WebStyles.samonHeaderTxt
                )}
                style={{
                  fontWeight: 700,
                  fontSize: isMobileDevice() ? "1rem" : "1.5rem",
                  lineHeight: 1.6,
                  marginBottom: isMobileDevice() ? 20 : 10,
                }}
              >
                Featured Storefronts
              </Grid>
            ) : null}
            <Grid
              container
              item
              xs={12}
              spacing={isMobileDevice() ? 0 : 3}
              className={classes.marginTop10}
            >
              <Grid container xs={12}>
                {shuffledItems.length
                  ? shuffledItems.map((data, index) => {
                      let webAddress = JSON.parse(data.storeFrontContent)
                        .webPresence.companyWebsite;
                      return (
                        <Grid
                          key={index}
                          item
                          container={
                            (isMobileDevice() || isTabletDevice()) && true
                          }
                          xs={12}
                          md={6}
                          lg={3}
                          xl={2}
                          style={{
                            marginBottom: isMobileDevice() && 16,
                            justifyContent:
                              (isMobileDevice() || isTabletDevice()) &&
                              "center",
                            paddingRight: isMobileDevice() && 4,
                            padding: 12,
                          }}
                        >
                          <Card
                            raised
                            className={clsx(
                              classes.cardBorder,
                              classes.primarColor,
                              classes.reLativeCard,
                              classes.clickableCard
                            )}
                            style={{
                              backgroundColor: "#66D7D140",
                              width:
                                (isMobileDevice() || isTabletDevice()) &&
                                "100%",
                            }}
                            onClick={() => {
                              const storeFrontId = data.storeFrontId;
                              const businessName = data.businessName.replaceAll(
                                "/",
                                "-"
                              );
                              if (storeFrontId && businessName) {
                                let newPath = "";
                                newPath = `https://app.souqh.ca/storefront/${data.storeFrontRouteName}`;
                                window.open(newPath, "_blank");
                              }
                            }}
                          >
                            <div className={classes.overlay}>
                              {data.primarySubscriptionPlanName ===
                              "Premium" ? (
                                <img
                                  style={{ width: "100%", height: "100%" }}
                                  src="/images/Premium-Partner.svg"
                                />
                              ) : (
                                <img
                                  style={{ width: "100%", height: "100%" }}
                                  src="/images/Verified-Partner.svg"
                                />
                              )}
                            </div>
                            <Grid
                              item
                              // style={{ border: "1px solid #17174C1F" }}
                            >
                              <CardMedia
                                className={classes.media}
                                image={
                                  JSON.parse(data.storeFrontContent)
                                    .displayPicture || "/images/logo.svg"
                                }
                                title=""
                              />
                            </Grid>
                            <Grid
                              container
                              item
                              justifyContent="space-between"
                              style={{ marginTop: 10 }}
                            >
                              <Grid item>
                                <PublicIcon
                                  style={{
                                    fill: webAddress ? "#0e1c4e" : "#0E1C4E42",
                                    cursor: webAddress ? "pointer" : "default",
                                  }}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    if (webAddress) {
                                      window.open(webAddress, "_blank");
                                    }
                                  }}
                                />
                              </Grid>
                              <Grid item>
                                <MessageIcon />
                              </Grid>
                              <Grid item>
                                <DateRangeIcon />
                              </Grid>
                              <Grid item>
                                <RoomIcon />
                              </Grid>
                              <Grid item>
                                <ShareIcon
                                  className={WebStyles.navyBlueColor}
                                  style={{ cursor: "pointer" }}
                                />
                              </Grid>
                            </Grid>
                            <Grid item>
                              <Tooltip title={data.storeFrontName}>
                                <Typography
                                  className={WebStyles.sqEllipsis}
                                  style={{
                                    fontSize: 16,
                                    fontWeight: 700,
                                    textAlign: "left",
                                  }}
                                >
                                  {data.storeFrontName}
                                </Typography>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Tooltip title={data.businessName}>
                                <Typography
                                  className={clsx(
                                    WebStyles.font14,
                                    WebStyles.sqEllipsisOneLine
                                  )}
                                  style={{ textAlign: "left" }}
                                >
                                  {data.businessName}
                                </Typography>
                              </Tooltip>
                            </Grid>
                            <Grid item>
                              <Tooltip title={data.businessTypeName}>
                                <Typography
                                  className={clsx(
                                    WebStyles.font14,
                                    WebStyles.sqEllipsis
                                  )}
                                  style={{ textAlign: "left" }}
                                >
                                  {data.businessTypes[0].name}
                                </Typography>
                              </Tooltip>
                            </Grid>
                            {/* <Grid
                                item
                                container
                                spacing={1}
                                alignItems="center"
                              >
                                <Grid item>
                                  <Rating
                                    name="rating"
                                    value={0}
                                    precision={0.5}
                                    className={classes.primarColor}
                                    readOnly
                                  />
                                </Grid>
                                <Grid item>
                                  <Typography
                                    className={clsx(
                                      classes.secondarColor,
                                      WebStyles.font14
                                    )}
                                  >
                                    {data.reviewsCount} Reviews
                                  </Typography>
                                </Grid>
                              </Grid> */}
                          </Card>
                        </Grid>
                      );
                    })
                  : null}
                {featuredStoreFronts.length &&
                featuredStoreFronts.length / numberOfCards > 1 ? (
                  <Grid
                    style={{
                      justifyContent: "center",
                      marginTop: 10,
                    }}
                    container
                    item
                    xs={12}
                  >
                    {Array(
                      Math.ceil(featuredStoreFronts.length / numberOfCards)
                    )
                      .fill(1)
                      .map((item, index) => (
                        <IconButton
                          classes={{ root: classes.svgIconButton }}
                          onClick={() => setActiveIndex(index)}
                        >
                          <CircleIcon
                            classes={{ root: classes.svgIcon }}
                            className={activeIndex === index ? "selected" : ""}
                          />
                        </IconButton>
                      ))}
                  </Grid>
                ) : null}
              </Grid>
              {/* <Hidden lgUp>
                    <Grid
                      item
                      xs={1}
                      container
                      style={{
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <ArrowForwardIosIcon
                        onClick={() => {
                          if (
                            currentIndex <
                            featuredStoreFronts.length - sliceFactor
                          ) {
                            setCurrentIndex(currentIndex + 1);
                          }
                        }}
                        style={{
                          width: 36,
                          height: 36,
                          fontSize: 36,
                          cursor:
                            currentIndex <
                            featuredStoreFronts.length - sliceFactor.length - 1
                              ? "pointer"
                              : "default",
                          fill: "#0e1c4e",
                          opacity:
                            currentIndex <
                            featuredStoreFronts.length - sliceFactor.length - 1
                              ? 1
                              : "0.5",
                        }}
                      />
                    </Grid>
                  </Hidden> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
