import {
  Button,
  Container,
  Grid,
  TextField,
  Hidden,
  makeStyles,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";
import { useExplore } from "souqh-react-redux-hooks/homeBuyer/useExplore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { matchSorter } from "match-sorter";
import { isEmpty } from "lodash";

const useStyles = makeStyles((theme) => ({
  inputs: {
    marginTop: 0,
    marginBottom: 0,
  },
  textInput: {
    "& .MuiInput-underline:before": {
      borderBottom: "none",
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Section1({ smDown, mdUp, lgUp, commonPadding }) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const {
    searchedCity,
    cities,
    getCitiesList,
    actions: { setSearchedCity },
  } = useExplore();

  useEffect(() => {
    getCitiesList();
  }, []);
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: 50,
        marginBottom: 50,
        background: "#fbe7f0",
      }}
    >
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Alert
          style={{ backgroundColor: "#1b1e4c" }}
          onClose={() => {
            setOpen(false);
          }}
          severity="success"
        >
          Message submitted successfuly
        </Alert>
      </Snackbar>
      <Container>
        <Container>
          <Grid
            container
            style={{
              padding: lgUp ? 40 : mdUp ? 40 : smDown ? 20 : 10,
              backgroundColor: "#ffffff",
            }}
          >
            <Grid container item xs={12} style={{ marginTop: smDown ? 20 : 0 }}>
              <Hidden mdDown>
                <Grid container item xs={12} md={6} lg={1}></Grid>
              </Hidden>
              <Grid
                item
                xs={12}
                style={{
                  marginBottom: 12,
                }}
              >
                <Grid
                  className={clsx(
                    WebStyles.samonHeaderTxt,
                    WebStyles.hankrndFont
                  )}
                  style={{
                    fontSize: mdUp ? 30 : 24,
                    lineHeight: mdUp ? "50px" : "32px",
                    textAlign: "center",
                    color: "#D81869",
                    marginBottom: 8,
                    fontWeight: 600,
                  }}
                >
                  Where do you need the home remodeling professional?
                </Grid>
                <Grid
                  className={clsx(
                    WebStyles.paraBlackText,
                    WebStyles.nunitoFont
                  )}
                  style={{
                    fontSize: mdUp ? 28 : 14,
                    lineHeight: mdUp ? "38px" : "19px",
                    marginBottom: 24,
                    textAlign: "center",
                  }}
                >
                  Get started in three simple steps
                </Grid>
              </Grid>
              <Grid item xs={12} lg={12}>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  item
                  xs={12}
                  lg={12}
                  style={{ marginTop: smDown ? 0 : 16 }}
                >
                  <Grid item xs={12} lg={4}>
                    <Autocomplete
                      options={cities || []}
                      value={searchedCity}
                      getOptionLabel={(option) => option.name || ""}
                      id="city"
                      debug
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="normal"
                          className={clsx(classes.inputs, classes.textInput)}
                          placeholder="Which city?"
                        />
                      )}
                      filterOptions={(options, { inputValue }) => {
                        if (inputValue.length >= 2) {
                          return matchSorter(options, inputValue, {
                            keys: ["name"],
                          });
                        } else {
                          return [];
                        }
                      }}
                      onChange={(event, value) => {
                        setSearchedCity(value);
                      }}
                      noOptionsText={"Type in to search"}
                      getOptionSelected={(option, value) => {
                        return option.name === value.name;
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <Button
                      style={{
                        backgroundColor: "#d81869",
                        color: "#FFFFFF",
                        textTransform: "none",
                        borderRadius: mdUp ? 24 : 12,
                        marginTop: 20,
                        marginLeft: lgUp ? 20 : 0,
                        padding: "8px 20px",
                        opacity: isEmpty(searchedCity) ? 0.5 : 1,
                      }}
                      disabled={isEmpty(searchedCity)}
                      onClick={() => {
                        window.open("https://app.souqh.ca/userlogin");
                      }}
                    >
                      <Grid
                        className={WebStyles.hankrndFont}
                        style={{ fontSize: mdUp ? 20 : 10 }}
                      >
                        Submit Details
                      </Grid>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}

export default Section1;
