import React from "react";
import { Container, Grid } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";

export default function FeatureItem({
  mdUp,
  smUp,
  lgUp,
  lgDown,
  feature,
  isEven,
  index,
  smDown,
}) {
  const height = lgUp ? 420 : 360;
  const radius = height / 2;
  const commonPadding = lgUp ? "0px 10%" : "0px 2%";

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: commonPadding,
        position: "relative",
        zIndex: 1,
      }}
    >
      {index === 0 && smUp ? (
        <div
          style={{
            position: "absolute",
            backgroundColor: "#72cac9",
            height: height,
            right: -2000,
            left: lgUp ? "-12%" : "-28%",
            bottom: 0,
            top: lgUp ? "-40%" : "-70%",
            zIndex: -1,
            borderTopLeftRadius: lgUp ? radius : "0px",
            borderBottomLeftRadius: lgUp ? radius : "0px",
            transform: "rotate(-10deg)",
            transformOrigin: "center",
            opacity: 0.25,
          }}
        />
      ) : null}

      {index === 2 && smUp ? (
        <div
          style={{
            position: "absolute",
            backgroundColor: "#F37C61",
            height: height,
            left: -2000,
            right: lgUp ? "6%" : "-30%",
            bottom: 0,
            top: "-20%",
            zIndex: -1,
            borderTopRightRadius: lgUp ? radius : "0px",
            borderBottomRightRadius: lgUp ? radius : "0px",
            transform: "rotate(10deg)",
            transformOrigin: "center",
            opacity: 0.2,
          }}
        ></div>
      ) : null}

      {index === 5 && smUp ? (
        <div
          style={{
            position: "absolute",
            backgroundColor: "#B872AF",
            height: 370,
            right: -2000,
            left: lgUp ? "-10%" : "-35%",
            bottom: 0,
            top: lgUp ? "-35%" : "-33%",
            zIndex: -1,
            borderTopLeftRadius: lgUp ? radius : "0px",
            borderBottomLeftRadius: lgUp ? radius : "0px",
            transform: "rotate(-10deg)",
            transformOrigin: "center",
            opacity: 0.1,
          }}
        ></div>
      ) : null}
      <Container style={{ padding: smDown && 0 }}>
        <Grid
          item
          container
          xs={12}
          style={{
            flexDirection: isEven ? "row" : "row-reverse",
            justifyContent: "flex-end",
          }}
        >
          <Grid
            item
            container
            alignContent="center"
            xs={6}
            style={{
              alignContent: "center",
              paddingLeft: isEven ? 0 : 32,
              paddingRight: isEven ? 32 : 0,
            }}
          >
            <Grid
              className={clsx(WebStyles.hankrndFont)}
              style={{
                fontSize: mdUp ? 40 : 20,
                lineHeight: mdUp ? "48px" : "24px",
                textAlign: "left",
                marginBottom: mdUp ? 12 : 8,
                color: feature.color,
                fontWeight: 600,
              }}
            >
              {feature.name}
            </Grid>
            <Grid
              className={clsx(WebStyles.nunitoFont, WebStyles.paraBlackText)}
              style={{
                fontSize: mdUp ? 28 : 14,
                lineHeight: mdUp ? "38px" : "19px",
                textAlign: "left",
                marginBottom: mdUp ? 12 : 8,
              }}
            >
              {feature.description}
            </Grid>
          </Grid>
          <Grid item xs={6} style={{ display: "flex", alignItems: "center" }}>
            <img
              style={{
                height: mdUp ? feature.height : "auto",
                maxWidth: "100%",
              }}
              src={feature.image}
              alt={feature.name}
            ></img>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
