import React, { useState } from "react";
import { Grid, Button, TextField, Container, Hidden  } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";

function SectionThree({
  xlUp,
  mdUp,
  smUp,
  xsUp,
  mdDown,
  smDown,
  xsDown,
  setShowWaitListForm,
}) {
  return (
    <Container>
      <Container>
    <Grid container item xs={12}  md={12} alignContent="center" style={{
     paddingLeft: mdUp ? "10%" : smUp ? "5%" : "0",
       paddingRight: mdUp? "10%" : smUp ? "5%" : "0"
    }}>
      <Grid item xs={12}>
        <Grid
          align="left"
          className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 50 : 30,
           
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
            marginTop : mdUp ? 12:8,
          }}
        >
Why You Need A Real Estate Lawyer
        </Grid>
        <Hidden xsUp lgUp mdUp smUp xlUp>
        <Grid item xs={12}
          align="left"
          className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 24 : 18,
           
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
            marginTop : mdUp ? 12:8,
          }}
        >
        It is a mistake to think that you must hire a real estate lawyer only when the law says you must. You must hire a lawyer to protect your interests while negotiating contracts, managing disputes, and closing deals among many other instances.
    <br/>
    Yes, the law in Canada requires that you hire a real estate lawyer to represent you when buying or selling ‘real property’, which according to the law is land and anything attached to it, like a house or other permanent structure. But, if anything, the legal requirement to hire a lawyer only emphasizes the level of risk involved in real estate. A capable lawyer, thus, makes sure you don’t expose yourself to too much risk.

    </Grid>
    </Hidden>
      </Grid>
      
      <Grid container item xs={12}>
        <Grid item xs={12} md={4} style={{
          //paddingLeft: mdUp ? "2%" : smUp ? "1%" : "0",
          padding: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ?  35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
          
          Ensure your contracts are sound at law

        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
             lineHeight: 1.5,
            fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
  Lawyers are specially trained to review contracts, not only to ascertain that they are legally sound but also to ensure you are fully aware of all the terms. Some contracts may indeed seem straightforward, but where you are committing substantial amounts of money, you’re best served to have a lawyer look through your documents and contracts. 

  <Hidden mdUp lgUp smUp xsUp xlUp>
  There are also complicated transactions like foreclosures, short sales, and investment property purchases for which you absolutely must get a lawyer. The same is true for zoning issues. 

  </Hidden>
</Grid>
        {/* <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 18 : 16,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
       For instance, the realtor was given incorrect information from a previous realtor or the homeowner and passed this on to the client. This is grounds to sue, however, you’re most likely to have only the damages compensated financially.
        </Grid> */}
        </Grid>
        <Grid item xs={12} md={4} style={{
          padding: mdUp ? "2%" : smUp ? "1%" : "0",
          //paddingRight: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
         Get the long end of the deal

        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
             lineHeight: 1.5,
            fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
      A real estate lawyer also has the duty to investigate the property you are buying to check if it is fully compliant with all applicable laws and that it has no liabilities. Once the sale closes and you have signed the purchase agreement, it can be hard to go back and address issues that were overlooked during the transaction. 
      <Hidden mdUp smUp lgUp xsUp xlUp>
      Where a case can be proven for misrepresentation or fraud and the sale is reversed, there may still be costs that you cannot fully recover. You certainly can’t recover the wasted time. Involving a real estate lawyer right through or at critical stages of the transaction will protect you from all these losses.

      </Hidden>
</Grid>
        
        </Grid>
        <Grid item xs={12} md={4} style={{
          padding: mdUp ? "2%" : smUp ? "1%" : "0",
          //paddingRight: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
          Trusted Platfrom
        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
             lineHeight: 1.5,
            fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
      An online platform targeted specifically at home buyers and homeowners, Souqh has features that make it easy and effortless to vet the qualifications, experience, and skills of service providers.

      <Hidden mdUp lgUp xsUp smUp>
            </Hidden>
</Grid>
        
        </Grid>
      </Grid>
     

    </Grid>
    </Container>
    </Container>
  );
}



// function Introduction({
//   smUp,
//   smDown,
//   mdUp,
//   mdDown,
//   lgUp,
//   xlUp,
//   commonPadding,
// }) {
//   const [showWaitListForm, setShowWaitListForm] = useState(false);
//   const handleClose = () => {
//     setShowWaitListForm(false);
//   };
//   return (
//     <Grid
//       item
//       container
//       xs={12}
//       style={{
//         backgroundImage: lgUp
//           ? "url('/images/home_buyers/Green patch.svg')"
//           : "none",
//         backgroundRepeat: "no-repeat",
//         backgroundSize: "10%",
//         padding: commonPadding,
//       }}
//     >

//       <Container>
//         <Container>
//           <Grid container>
          
//               <Grid container direction="row">
//                 <SectionTwo
//                   xlUp={xlUp}
//                   mdUp={mdUp}
//                   mdDown={mdDown}
//                   smDown={smDown}

//                 />
                
//               </Grid>
          

//           </Grid>
//         </Container>
//       </Container>
//     </Grid>
//   );
// }

export default SectionThree;
