import { isMobileDevice, isTabletDevice } from "../../utils/AppUtils";

export const MAX_SUMMARY_ITEMS = 100;

export const WAITING_FOR_OTHER = "Waiting for other";
export const ACTION_REQUIRED = "Action Required";
export const EXPIRING_SOON = "Expiring Soon";
export const COMPLETED = "Completed";
export const ARCHIVED = "Archived";
export const OTHER_DOCUMENTS = "Other Documents";
export const MY_TEMPLATES = "My Templates";

export const DOCUMENTS_TABS = [
  ACTION_REQUIRED,
  WAITING_FOR_OTHER,
  EXPIRING_SOON,
  COMPLETED,
  ARCHIVED,
  OTHER_DOCUMENTS,
  MY_TEMPLATES,
];

export const DOCUMENTS_TABLE_HEADERS = [
  {
    id: "docName",
    label: "Document Name",
    width: isTabletDevice() || isMobileDevice() ? "15em" : "25%",
  },
  {
    id: "sharedWith",
    label: "Client Name",
    width: isTabletDevice() || isMobileDevice() ? "13em" : "23%",
  },
  {
    id: "docStatus",
    label: "Status",
    width: isTabletDevice() || isMobileDevice() ? "13em" : "15%",
  },
  {
    id: "lastChange",
    label: "Last Changed",
    width: isTabletDevice() || isMobileDevice() ? "15em" : "17%",
  },
  {
    id: "quickActions",
    label: "Quick Actions",
    width: isTabletDevice() || isMobileDevice() ? "15em" : "15%",
  },
];

export const TEMPLATE_DOCUMENTS_TABLE_HEADERS = [
  {
    id: "docName",
    label: "Document Name",
    width: isTabletDevice() || isMobileDevice() ? "15em" : "30%",
  },
  {
    id: "sharedWith",
    label: "",
    width: isTabletDevice() || isMobileDevice() ? "1em" : "1%",
  },
  {
    id: "docStatus",
    label: "",
    width: isTabletDevice() || isMobileDevice() ? "1em" : "1%",
  },
  {
    id: "lastChange",
    label: "Last Change",
    width: isTabletDevice() || isMobileDevice() ? "15em" : "38%",
  },
  {
    id: "quickActions2",
    label: "Action",
    width: isTabletDevice() || isMobileDevice() ? "15em" : "27%",
  },
];

export const EMAIL_PATTERN =
  /(^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)|(^$)/i;

export const ALPHA_NUMERIC_PATTERN = /^[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/;
export const NUMERIC_PATTERN = /^[0-9]*$/;

export const REFERRAL_INVITE_STATUS = [
  { key: "IN_PROGRESS", value: "In progress" },
  { key: "EXPIRED", value: "Expired" },
  { key: "COMPLETED", value: "Completed" },
];

export const PRIMARY_APPLICANT_USER = "Signer 1";
export const SECONDARY_APPLICANT_USER = "Signer 2";

export const VIEW_MAX_WIDTH = 1720;
export const CHAT_PANEL_MAX_WIDTH = 1000;

export const HOME_BUYER_USER = "HOME_BUYER";
export const SERVICE_PROVIDER_USER = "SERVICE_PROVIDER";
export const TEAM_MEMBER_USER = "TEAM_MEMBER";

export const DATE_COLUMNS_TO_SORT = [
  "lastChange",
  "inviteDate",
  "acceptedDate",
  "completionDate",
  "requestedDate",
  "completeDate",
  "archiveInActiveDate",
  "requestDate",
  "closingDate",
];

export const MESSAGE_GROUP_NAME_PREFIX = "@sqgrp";

export const HELP_CENTER_LINK = "https://intercom.help/souqh/en";

export const MANDATORY_DETAILS_MESSAGE = "Please fill all mandatory details";
export const MEETING_READ_MODE = "read";
export const MEETING_CREATE_MODE = "create";
export const MEETING_EDIT_MODE = "edit";

export const SQ_TIME_SLOTS = [
  "12:00 AM",
  "12:30 AM",
  "1:00 AM",
  "1:30 AM",
  "2:00 AM",
  "2:30 AM",
  "3:00 AM",
  "3:30 AM",
  "4:00 AM",
  "4:30 AM",
  "5:00 AM",
  "5:30 AM",
  "6:00 AM",
  "6:30 AM",
  "7:00 AM",
  "7:30 AM",
  "8:00 AM",
  "8:30 AM",
  "9:00 AM",
  "9:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "1:00 PM",
  "1:30 PM",
  "2:00 PM",
  "2:30 PM",
  "3:00 PM",
  "3:30 PM",
  "4:00 PM",
  "4:30 PM",
  "5:00 PM",
  "5:30 PM",
  "6:00 PM",
  "6:30 PM",
  "7:00 PM",
  "7:30 PM",
  "8:00 PM",
  "8:30 PM",
  "9:00 PM",
  "9:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM",
];

export const TEAM_MEMBER_ROLES = ["Admin", "Team Member"];

export const PRO = "Pro";
export const PLUS = "Plus";
export const PREMIUM = "Premium";
export const MARKETPLACE = "Marketplace";

// Status
export const APPROVED = "APPROVED";
export const DRAFT = "DRAFT";
export const REQUESTED = "REQUESTED";

//Error Msgs
export const HB_SIGN_NOW_ACCOUNT_EXIST =
  "Please use the link in the email from SignNow to sign and complete this document. You are unable to open this document from signature within Souqh as you already have a SignNow account. You can contact support@souqh.ca for assistance with migrating your SignNow account into Souqh. ";
export const SP_SIGN_NOW_ACCOUNT_EXIST =
  "As you already have a SignNow account. You can contact support@souqh.ca for assistance with migrating your SignNow account into Souqh. ";

export const STRIPE_KEY =
  "pk_test_51IXRrOLgdzRBtabsR7De40JetdQelaGHR97U8x9BPUPnQ3YzrjPo3vVnboTip0o5EYsAke9YLE3HuHGxALLsICct00IVBtgLtL";

export const PA_TAG_TEAM_MEMBER = "PA_TAG_TEAM_MEMBER";
export const PA_TAG_SOMEONE = "PA_TAG_SOMEONE";

export const BUSINESS_TYPE_OTHER = "Other";
export const BUSINESS_TYPE_HOME_DEPOSIT = "Home Deposit";

export const USER_TYPES = [
  { id: 0, name: "Home Buyer", value: "HOME_BUYER" },
  { id: 1, name: "Home Owner", value: "HOME_OWNER" },
];

export const BILLING_CYCLE = [
  { label: "Monthly", value: "MONTHLY" },
  { label: "Annually", value: "YEARLY" },
];