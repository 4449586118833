import React, { useState } from "react";
import { Grid, Button, TextField, Container, Hidden  } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import HomeWebForm from "../home/HomeWebForm";

function InfoSection({
  xlUp,
  mdUp,
  mdDown,
  smDown,
  xsDown,
  setShowWaitListForm,
}) {
  return (
    <Grid container item xs={12} md={8} alignContent="center">
      <Grid item xs={12}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: xlUp ? 80 : mdUp ? 60 : 40,
            lineHeight: 1,
            marginBottom: mdUp ? 24 : 12,
            fontWeight: 600,
          }}
        >
          Why It’s Important To Fix Plumbing Emergencies Fast
        </Grid>
      </Grid>
      <Grid item xs={12} md={10}>
         <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
          Even though it is easy to neglect, the plumbing system is arguably the most important utility system in a home. It is responsible for the efficient distribution of water and the safe disposal of waste. A well-functioning plumbing system is central to a clean, sanitary, and safe home.
<br/>
Just from a comfort standpoint, a plumbing emergency could mean having to do without hot water in winter. No one likes to even imagine the prospect of a cold shower in the dead of winter. So plumbers are on the frontline when it comes to protecting our health and well-being.
<br/>
While we want to access the cost of plumbing failures in dollar terms, there are other effects that should cause as much concern. Blocked and leaking toilets, for example, puts your entire family’s health at risk. So, if truth be told, you need to have a reliable plumber on speed dial as plumbing failures happen without notice, especially with older homes.
<br/>
        </Grid>
      </Grid>

       <Grid item xs={12}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: xlUp ? 50 : mdUp ? 30 : 24,
            lineHeight: 1,
            marginBottom: mdUp ? 24 : 12,
            fontWeight: 600,
          }}
        >
         Common Services Plumbers Typically Offer
        </Grid>
      </Grid>
      <Grid item xs={12} md={10}>
         <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
         Plumbing encompasses many tasks. Essentially, where there is a system of pipes for water supply, heating, or waste disposal, it’s a plumber’s domain. 

So, among other home emergencies, you will have to call a plumber if:
<ul>
<li>Your toilet backs up,</li>
<li>You need to install a new boiler,</li>
<li>You need to replace a leaking faucet,</li>
<li>Your drain is blocked and needs cleaning.</li>
</ul>
The good news is plumbers undergo extensive apprenticeship programs to be trade certified. A certified and experienced plumber will be well-served with most areas of the job. Your job, in this case, is to find such a plumber and make them your go-to person every time a pipe breaks, your toilet backs up….

        </Grid>
      </Grid>
    </Grid>
  );
}

function ImageSection({
  xlUp,
  mdUp,
  mdDown,
  smDown,
  xsDown,})
  {
  return (
    
    <Grid item xs={12} md={4}
    style={{
      
      marginTop: mdUp ? 24 : 12,
    }}
    >
      {
        //<iframe width="100%" height="450" src="https://www.youtube.com/embed/xdh48tFm4dM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      <img
       src="/images/home_page/Illustration 22.svg"
        style={{
           //height: xlUp ? 625 : mdUp ? 313 : 219,
          width: "100%",
        }}
      /> }
    </Grid>
  );
}

function SectionOne({
  smUp,
  smDown,
  mdUp,
  mdDown,
  lgUp,
  xlUp,
  commonPadding,
}) {
  const [showWaitListForm, setShowWaitListForm] = useState(false);
  const handleClose = () => {
    setShowWaitListForm(false);
  };
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        backgroundImage: lgUp
          ? "url('/images/home_buyers/Green patch.svg')"
          : "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "10%",
        padding: commonPadding,
      }}
    >

      <Container>
        <Container>
          <Grid container>
            {mdUp ? (
              <Grid container direction="row">
                <InfoSection
                  xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown}

                />
                <ImageSection xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown} />
              </Grid>
            ) : (
              <Grid container direction="row">
                <ImageSection  xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown} />
                <InfoSection
                  xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown}
                />
              </Grid>
            )}


          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}

export default SectionOne;
