import {
  ButtonBase,
  Container,
  Grid,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { cloneDeep, forEach } from "lodash";
import { HB_QUESTION_ANSWERS } from "./homePageConstants";


export default function FrequentlyAskedQuestions({
  commonPadding,
  mdUp,
  lgUp,
  smUp,
  xlUp,
}) {
  const [questionAndAnswersRow1, setQuestionAndAnswersRow1] =
    useState(HB_QUESTION_ANSWERS);
  const [value, setValue] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [who, setWho] = React.useState("");

  const handleClickOpen = (who) => {
    setWho(who);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(() => {
  //   if (value === 0) {
  //     setQuestionAndAnswersRow1(HB_QUESTION_ANSWERS);
  //   } else if (value === 1) {
  //     setQuestionAndAnswersRow1(SP_QUESTION_ANSWERS);
  //   }
  // }, [value]);

  const handelExpandRow1 = (index) => {
    let tempData = cloneDeep(questionAndAnswersRow1);

    tempData.forEach((data, i) => {
      if (index === data.id) {
        data.expanded = !data.expanded;
      } else {
        data.expanded = false;
      }
    });
    setQuestionAndAnswersRow1(tempData);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const AntTabs = withStyles({
    root: {
      borderBottom: "1px solid #e8e8e8",
    },
    indicator: {
      backgroundColor: "#F37C61",
    },
  })(Tabs);

  const AntTab = withStyles((theme) => ({
    root: {
      textTransform: "none",
      minWidth: 72,
      fontWeight: 700,
      marginRight: theme.spacing(4),
      fontFamily: ["Nunito Sans", "sans-serif"].join(","),
      color: "#72CAC9",
      "&:hover": {
        color: "#F37C61",
        opacity: 1,
      },
      "&$selected": {
        color: "#F37C61",
        fontWeight: 700,
      },
      "&:focus": {
        color: "#F37C61",
      },
    },
    selected: {},
  }))((props) => <Tab disableRipple {...props} />);

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: commonPadding,
        marginTop: 20,
        marginBottom: 20,
      }}
    >
      
      <Container
       
      >
        <Container>
          <Grid
            align="left"
            item
            className={clsx(WebStyles.purpleTxt, WebStyles.hankrndFont)}
            style={{
              fontSize: mdUp ? 40 : 20,
              lineHeight: mdUp ? "40px" : "20px",
              marginBottom: 36,
              marginTop: 10,
              fontWeight: 600,
            }}
          >
            Strong communication and people skills
          </Grid>
          {/* <AntTabs
            value={value}
            onChange={handleChange}
            aria-label="ant example"
          >
            <AntTab label="Home Buyers/Owners" />
            <AntTab label="Service Providers" />
          </AntTabs> */}

          <Grid
            container
            style={{
              marginTop: 36,
            }}
          >
            {/* <Grid item lg={6} md={12} xs={12}>
              {questionAndAnswersRow1.map((data, index) => {
                                return (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    align="left"
                    style={{ marginBottom: 10 }}
                  >
                    <Grid
                      className={clsx(
                        WebStyles.paraBlackText,
                        WebStyles.hankrndFont
                      )}
                      style={{
                        fontSize: mdUp ? 20 : 10,
                        lineHeight: mdUp ? "24px" : "12px",
                        marginBottom: 10,
                        fontWeight: 600,
                      }}
                      item
                      container
                      justify="flex-start"
                      alignContent="flex-start"
                      xs={12}
                    >
                      <Grid container item xs={1} alignContent="baseline">
                        <Grid item>
                          <ButtonBase onClick={() => handelExpandRow1(data.id)}>
                            {!data.expanded ? (
                              <AddIcon
                                style={{ fontSize: mdUp ? "1.5rem" : "1rem" }}
                                className={WebStyles.purpleTxt}
                              />
                            ) : (
                              <RemoveIcon
                                style={{ fontSize: mdUp ? "1.5rem" : "1rem" }}
                                className={WebStyles.purpleTxt}
                              />
                            )}
                          </ButtonBase>
                        </Grid>
                      </Grid>
                      <Grid item xs={10} container alignContent="center">
                        {data.question}
                      </Grid>
                    </Grid>
                    {data.expanded && (
                      <Grid
                        item
                        xs={12}
                        className={clsx(
                          WebStyles.captionGray,
                          WebStyles.nunitoFont
                        )}
                        style={{
                          fontSize: mdUp ? 20 : 10,
                          lineHeight: mdUp ? "32px" : "16px",
                          paddingLeft: lgUp ? 50 : mdUp ? 60 : 30,
                          paddingRight: 20,
                        }}
                      >
                        {!isJoinReplace ? data.answer : splits}
                      </Grid>
                    )}
                  </Grid>
                );
              })}
            </Grid> */}
            <Grid item lg={12} md={12} xs={12}>
              {questionAndAnswersRow1.map((data, index) => (
                <Grid
                  key={index}
                  item
                  xs={12}
                  align="left"
                  style={{ marginBottom: 10 }}
                >
                  <Grid
                    className={clsx(
                      WebStyles.paraBlackText,
                      WebStyles.hankrndFont
                    )}
                    style={{
                      fontSize: mdUp ? 20 : 18,
                      lineHeight: mdUp ? "24px" : "20px",
                      marginBottom: 10,
                      fontWeight: 600,
                    }}
                    item
                    container
                    justify="flex-start"
                    alignContent="flex-start"
                    xs={12}
                  >
                    <Grid container item xs={1} alignContent="baseline">
                      <Grid item>
                        <ButtonBase onClick={() => handelExpandRow1(data.id)}>
                          {!data.expanded ? (
                            <AddIcon
                              style={{ fontSize: mdUp ? "1.5rem" : "1rem" }}
                              className={WebStyles.purpleTxt}
                            />
                          ) : (
                            <RemoveIcon
                              style={{ fontSize: mdUp ? "1.5rem" : "1rem" }}
                              className={WebStyles.purpleTxt}
                            />
                          )}
                        </ButtonBase>
                      </Grid>
                    </Grid>
                    <Grid item xs={10} container alignContent="center">
                      {data.question}
                    </Grid>
                  </Grid>
                  {data.expanded && (
                    <Grid
                      item
                      xs={12}
                      className={clsx(
                        WebStyles.captionGray,
                        WebStyles.nunitoFont
                      )}
                      style={{
                        fontSize: mdUp ? 20 : 14,
                        lineHeight: mdUp ? "32px" : "18px",
                        paddingLeft: "8.33%",
                        paddingRight: 20,
                      }}
                    >
                      {data.answer}
                    </Grid>
                  )}
                </Grid>
              ))}
            </Grid>
            {mdUp ? <Grid item lg={2}></Grid> : null}
          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}
