import React from "react";
import {
  Grid,
  Button,
  Container,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";
import WebStyles from "../WebStyles.module.css";
import clsx from "clsx";
import { isMobileDevice, isTabletDevice } from "./WebUtils";

function InfoSection({
  xlUp,
  mdUp,
  mdDown,
  smDown,
  xsDown,
  btnText,
  children,
}) {
  return (
    <Grid container item xs={12} md={12} alignContent="center">
      <Grid item xs={12}>
        <Grid
          align="center"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: xlUp ? 80 : mdUp ? 60 : 32,
            lineHeight: 1,
            marginBottom: mdUp ? 24 : 12,
            fontWeight: 600,
          }}
        >
          What is Souqh?
        </Grid>
      </Grid>
      {/* <Grid item xs={12}>
        <Grid
          align="center"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 20 : 10,
          }}
        >
          {children}
        </Grid>
      </Grid> */}
      <Grid
        item
        xs={12}
        md={12}
        style={{
          marginTop: mdUp ? 24 : 12,
          marginBottom: 24,
        }}
      >
        {
          <iframe
            width="100%"
            height="450"
            src="https://www.youtube.com/embed/f_gb6_f0iig"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        }
      </Grid>

      <Grid
        container
        item
        xs={12}
        md={12}
        align="center"
        alignContent="center"
        justifyContent="center"
      >
        <Grid
          item
          container
          md={btnText === "Mortgage Broker" || btnText === "Handyman" ? 9 : 8}
          xs={12}
          justifyContent="center"
        >
          <Grid
            xs={12}
            md={btnText === "Mortgage Broker" ? 6 : 7}
            style={{ maxWidth: mdUp && "51%" }}
          >
            <Button
              style={{
                backgroundColor: "#F37C61",
                color: "#FFFFFF",
                textTransform: "none",
                borderRadius: mdUp ? 24 : 12,
                marginTop: smDown ? 20 : 0,
                marginBottom: smDown ? 20 : 10,
                // marginLeft: lgUp ? 20 : 0,
                fontSize: mdUp ? 20 : 14,
                padding: "8px 24px",
                marginRight: xsDown ? 24 : 10,
                marginLeft: xsDown ? 24 : 10,
                width: "auto",
              }}
              onClick={() => {
                window.open("https://app.souqh.ca/userlogin");
              }}
            >
              <Grid className={WebStyles.hankrndFont}>
                Sign up as Home Buyer/Owner
              </Grid>
            </Button>
          </Grid>
          <Grid xs={12} md={btnText === "Mortgage Broker" ? 6 : 5}>
            <Button
              style={{
                backgroundColor: "#F37C61",
                color: "#FFFFFF",
                textTransform: "none",
                borderRadius: mdUp ? 24 : 12,
                marginTop: smDown ? 20 : 0,
                marginBottom: smDown ? 20 : 10,
                // marginLeft: lgUp ? 20 : 0,
                fontSize: mdUp ? 20 : 14,
                padding: "8px 24px",
                marginRight: xsDown ? 24 : 10,
                marginLeft: xsDown ? 24 : 10,
                width: isMobileDevice() ? "45%" : "90%",
              }}
              onClick={() => {
                window.open("https://app.souqh.ca/login");
              }}
            >
              <Grid className={WebStyles.hankrndFont}>
                Sign up as a {btnText}
              </Grid>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function ImageSection({ mdUp }) {
  return (
    <Grid
      item
      xs={12}
      md={12}
      style={{
        marginTop: mdUp ? 24 : 12,
      }}
    >
      {
        <iframe
          width="100%"
          height="450"
          src="https://www.youtube.com/embed/f_gb6_f0iig"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      }
    </Grid>
  );
}

function ServicesIntroSection({ btnText, children }) {
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  //   const commonPadding = lgUp ? "0px 6%" : "0px 2%";
  return (
    <Grid
      item
      container
      xs={12}
      style={
        {
          // backgroundImage: lgUp
          //   ? "url('/images/home_buyers/Green patch.svg')"
          //   : "none",
          // backgroundRepeat: "no-repeat",
          // backgroundSize: "10%",
          // padding: commonPadding,
        }
      }
    >
      <Container>
        <Container>
          <Grid container direction="row">
            <InfoSection
              xlUp={xlUp}
              mdUp={mdUp}
              mdDown={mdDown}
              smDown={smDown}
              btnText={btnText}
              children={children}
            />
            {/* <ImageSection mdUp={mdUp} /> */}
          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}

export default ServicesIntroSection;
