export const SP_QUESTION_ANSWERS = [
  {
    id: 1,
    question: "What makes a good real estate agent",
    link:
     "/what-makes-a-good-real-estate-agent",
  },
  {
    id: 2,
    question: "How to become a real estate agent in Ontario",
    link:
      "/become-a-real-estate-agent",
  },
  
];

export const HB_QUESTION_ANSWERS = [
  {
    id: 1,
    question:"What do real estate agents charge to sell your house",
    //answer:
      //"We know from our own experience how overwhelming and stressful the home journey can be and we were determined to make the process easier. Souqh is Canada’s only end-to-end digital services marketplace who supports home buyers looking for guidance with their home buying journey. Finding a trusted service provider, such as a realtor, mortgage broker, lawyer, or general contractor, has never been easier. From verified reviews, to documentation that is easy to access and locate all in one place, Souqh simplifies your home journey. And the best part? It’s all free!",
      link:
      "/real-estate-agent-sells-your-house",
    },
    // {
    //   id: 2,
    // question:"Real Estate lawyers",
    // //answer:
    //   //"We know from our own experience how overwhelming and stressful the home journey can be and we were determined to make the process easier. Souqh is Canada’s only end-to-end digital services marketplace who supports home buyers looking for guidance with their home buying journey. Finding a trusted service provider, such as a realtor, mortgage broker, lawyer, or general contractor, has never been easier. From verified reviews, to documentation that is easy to access and locate all in one place, Souqh simplifies your home journey. And the best part? It’s all free!",
    //   link:
    //   "/real-estate-lawyers",
    
    // }
    ];
