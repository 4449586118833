import { useMediaQuery, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import SubFooter from "../SubFooter";
import ExploreSP from "./ExploreSP";

function ServicesPage() {
  const [selectedTab, setselectedTab] = useState(0);
  const title = "Ditch the stress. Join for free!";
  const subTitle =
    "We designed Souqh to help eliminate the stress in the home buying journey.";
  const btnText = "Sign up now for free!";
  const redirectUrl = "https://app.souqh.ca/";

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  // const commonPadding = lgUp ? "0px 10%" : "0px 2%";
  const commonPadding = 0;

  return (
    <div style={{ paddingBottom: 32, overflow: "hidden" }}>
      <ExploreSP lgUp={lgUp} mdUp={mdUp} xlUp={xlUp} />
      <div style={{ height: 100 }}></div>
      <SubFooter
        title={title}
        subTitle={subTitle}
        btnText={btnText}
        redirectUrl={redirectUrl}
      />
    </div>
  );
}

export default ServicesPage;
