import { Container, Grid, Hidden } from "@material-ui/core";
import React from "react";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";

function Section4({ smUp, mdUp, lgUp, commonPadding }) {
  const moreFeatures = [
    {
      name: "Residential realtor",
      description: <p>They help people buy and sell homes. <Hidden mdUp lgUp smUp xsUp>
        Agents are experts in the process of buying and selling property, financing options, and relocation services</Hidden></p>,
    },
    {
      name: "Commercial realtor",
      description:
      <p>They specialize in income-producing properties, such as apartment and office buildings, retail stores and warehouses, shopping centers and industrial parks. <Hidden mdUp lgUp smUp xsUp>
      Commercial brokers need in-depth knowledge of the property market, local economic trends, current income tax regulations and purchasing arrangements that give the buyer a greater return on investment.</Hidden></p>,
    },
    {
      name: "Real estate appraisals",
      description: <p>They determine the value of properties. Appraisers need to know acceptable principles of appraisal and related information <Hidden mdUp lgUp smUp xsUp>
      They need to have practical experience, technical education, good judgement and some knowledge of mathematics, accounting and economics.</Hidden></p>,

    },
    {
      name: "Property management",
      description:
      <p>They maintain and upgrade properties in order to produce the highest possible financial return over the longest period of time. <Hidden mdUp lgUp smUp xsUp>
      They are responsible for protecting the owner’s investment. Managed commercial properties are likely to be office buildings and shopping centers.</Hidden></p>,

    },
    {
      name: "Property developers",
      description: <p>They turn land into profitable, marketable developments of all kinds. Site selection is the first decision developers must make. <Hidden mdUp lgUp smUp xsUp>
      Developers analyze all costs and arrange the financing. Then they contract for the physical structures and supervise construction. Finally, developers promote the finished project to either sell or lease buildings or portions of the development.</Hidden></p>,


    },
   
    {
      name: "Financing",
      description:
      <p>This is for those interested in the financial aspect of real estate, banks or mortgage lenders, developers, and investment funds offer a variety of career opportunities. <Hidden mdUp lgUp smUp xsUp>
      You’ll need to develop expertise in tax law, financial regulations, and accounting and business practices. In residential real estate, the professional must know financing or mortgage options; in commercial situations these professionals will also be asked about income opportunities and productive uses of different kinds of properties.</Hidden></p>,

    },
    
  ];

  const firstThreeItems = moreFeatures.slice(0, 3);
  const lastThreeItems = moreFeatures.slice(3, 7);

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        backgroundImage: lgUp
          ? "url('/images/home_page/Purple strip.svg')"
          : "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "10%",
        padding: commonPadding,
        marginTop: 20,
      }}
    >
      <Container>
        <Container>
          <Grid container>
            <Grid
              item
              xs={12}
              className={clsx(WebStyles.purpleTxt, WebStyles.hankrndFont)}
              style={{
                fontSize: mdUp ? 40 : 20,
                fontWeight: 600,
              }}
            >
             Is a career in real estate a good match for you?
            </Grid>
            
            <Grid 
              item
              xs={12}
              className={clsx(WebStyles.paraBlackText, WebStyles.nunitoFont)}
              style={{fontSize: mdUp ? 28 : 14 }}
            >
              
              Before you embark on the journey, it’s important to know the career options you have in real estate.
            </Grid>
            <Grid 
              item
              xs={12}
              className={clsx(WebStyles.paraBlackText, WebStyles.nunitoFont)}
              style={{ marginBottom: mdUp ? 20 : 10, fontSize: mdUp ? 24 : 14, textAlign:"left" }}
            >
              
              <strong>Prerequisites</strong>
              <ul style={{ marginLeft:20}}>
                <li >Canadian resident</li>
                <li >Minimum age: 18 years</li>
                <li>An Ontario secondary school diploma or equivalent</li>
              </ul>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {firstThreeItems.map((item) => (
                <Grid
                  container
                  item
                  xs={12}
                  style={{ marginBottom: 24 }}
                  key={item.name}
                >
                  <Grid
                    item
                    xs={1}
                    style={{
                      backgroundColor: "#B872AF33",
                      height: lgUp ? 23 : 20,
                      maxWidth: lgUp ? 24 : 20,
                      borderRadius: "100%",
                      marginTop: lgUp ? 10 : 3,
                    }}
                  ></Grid>
                  <Grid
                    container
                    item
                    xs={11}
                    justify="flex-start"
                    style={{ paddingLeft: 20 }}
                  >
                    <Grid
                      item
                      xs={12}
                      className={clsx(
                        WebStyles.purpleTxt,
                        WebStyles.hankrndFont
                      )}
                      style={{
                        textAlign: "left",
                        fontSize: mdUp ? 28 : 14,
                        fontWeight: 600,
                      }}
                    >
                      {item.name}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={clsx(
                        WebStyles.paraBlackText,
                        WebStyles.nunitoFont
                      )}
                      style={{ textAlign: "left", fontSize: mdUp ? 20 : 10 }}
                    >
                      {item.description}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Hidden mdDown>
              <Grid item xs={4}>
                <img
                  src="/images/home_page/Illustration 22.svg"
                  style={{
                    height: 530,
                    // width: 300, marginTop: -51
                  }}
                />
              </Grid>
            </Hidden>
            <Grid item xs={12} md={6} lg={4}>
              {lastThreeItems.map((item) => (
                <Grid
                  container
                  item
                  xs={12}
                  style={{ marginBottom: 24 }}
                  key={item.name}
                >
                  <Grid
                    item
                    xs={1}
                    style={{
                      backgroundColor: "#B872AF33",
                      height: 23,
                      maxWidth: 24,
                      borderRadius: "100%",
                      marginTop: lgUp ? 10 : 0,
                    }}
                  ></Grid>
                  <Grid
                    container
                    item
                    xs={11}
                    justify="flex-start"
                    style={{ paddingLeft: 20 }}
                  >
                    <Grid
                      item
                      xs={12}
                      className={clsx(
                        WebStyles.purpleTxt,
                        WebStyles.hankrndFont
                      )}
                      style={{
                        textAlign: "left",
                        fontSize: mdUp ? 28 : 14,
                        fontWeight: 600,
                      }}
                    >
                      {item.name}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={clsx(
                        WebStyles.paraBlackText,
                        WebStyles.nunitoFont
                      )}
                      style={{ textAlign: "left", fontSize: mdUp ? 20 : 10 }}
                    >
                      {item.description}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}

export default Section4;
