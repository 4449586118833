import { Card, Container, Grid, Hidden } from "@material-ui/core";
import React from "react";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import { Link } from "react-router-dom";

export default function LatestUpdates({ commonPadding, mdUp }) {
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: commonPadding,
        marginBottom: 30,
      }}
    >
      <Container>
        <Container>
          <Grid container spacing={2} xs={12} item>
            <Hidden lgUp>
              <Grid item xs={12} style={{ marginTop: 48 }}>
                <Grid
                  item
                  xs={12}
                  align="left"
                  className={clsx(
                    WebStyles.hankrndFont,
                    WebStyles.blueHeaderTxt
                  )}
                  style={{
                    fontSize: mdUp ? 40 : 20,
                    lineHeight: mdUp ? "56px" : "28px",
                  }}
                >
                  Latest Updates
                </Grid>
                <Grid
                  item
                  xs={12}
                  align="left"
                  className={clsx(
                    WebStyles.nunitoFont,
                    WebStyles.paraBlackText
                  )}
                  style={{
                    fontSize: mdUp ? 28 : 10,
                    // lineHeight: mdUp ? "32px" : "16px",
                  }}
                >
                  Here are some helpful resources for you to get started with
                  Souqh
                </Grid>
              </Grid>
            </Hidden>
            <Hidden mdDown>
              <Grid item lg={6}>
                <Grid item xs={12}>
                  <img
                    style={{ width: 541, height: 468, marginLeft: -300 }}
                    src="/images/home_page/latest_updates.svg"
                  />
                </Grid>
                <Grid
                  item
                  align="left"
                  className={clsx(
                    WebStyles.hankrndFont,
                    WebStyles.blueHeaderTxt
                  )}
                  style={{
                    fontSize: 40,
                    lineHeight: "56px",
                    fontWeight: 600,
                    marginTop: 36,
                  }}
                >
                  Latest Updates
                </Grid>
                <Grid
                  item
                  align="left"
                  className={clsx(
                    WebStyles.nunitoFont,
                    WebStyles.paraBlackText
                  )}
                  style={{ fontSize: 20, marginTop: 16 }}
                >
                  Here are some helpful resources for you to get started with
                  Souqh
                </Grid>
              </Grid>
            </Hidden>
            <Grid container item lg={3} md={6} xs={6} alignContent="flex-end">
              <Link
                to={{
                  pathname:
                    "https://intercom.help/souqh/en/collections/2994726-getting-started-guide-service-providers",
                }}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Card
                  elevation={0}
                  style={{
                    border: "1px solid #E2E2E2",
                    borderRadius: 4,
                  }}
                >
                  <Grid
                    item
                    style={{ padding: 30, backgroundColor: "#72CAC9" }}
                  >
                    <img
                      style={{
                        width: mdUp ? 140 : 70,
                        height: mdUp ? 140 : 70,
                      }}
                      src="/images/home_page/Article.svg"
                    />
                  </Grid>
                  <Grid item style={{ padding: "30px 20px" }}>
                    <Grid
                      item
                      align="left"
                      style={{
                        fontSize: mdUp ? 12 : 7,
                        lineHeight: mdUp ? "17px" : "8px",
                      }}
                      className={clsx(
                        WebStyles.hankrndFont,
                        WebStyles.paraBlackText
                      )}
                    >
                      2nd April, 2021
                    </Grid>
                    <Grid
                      item
                      align="left"
                      className={clsx(
                        WebStyles.hankrndFont,
                        WebStyles.paraBlackText
                      )}
                      style={{
                        fontSize: mdUp ? 16 : 12,
                        lineHeight: mdUp ? "25px" : "17.5px",
                        marginTop: 5,
                        fontWeight: 600,
                      }}
                    >
                      Getting Started Guide for Service Providers
                    </Grid>
                  </Grid>
                </Card>
              </Link>
            </Grid>
            <Grid container item lg={3} md={6} xs={6} alignContent="flex-end">
              <Link
                to={{
                  pathname:
                    "https://intercom.help/souqh/en/collections/2994749-getting-started-guide-home-buyers",
                }}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Card
                  elevation={0}
                  style={{
                    border: "1px solid #E2E2E2",
                    borderRadius: 4,
                  }}
                >
                  <Grid
                    item
                    style={{ padding: 30, backgroundColor: "#72CAC9" }}
                  >
                    <img
                      style={{
                        width: mdUp ? 140 : 70,
                        height: mdUp ? 140 : 70,
                      }}
                      src="/images/home_page/Article.svg"
                    />
                  </Grid>
                  <Grid item align="left" style={{ padding: "30px 20px" }}>
                    <Grid
                      className={clsx(
                        WebStyles.hankrndFont,
                        WebStyles.paraBlackText
                      )}
                      style={{
                        fontSize: mdUp ? 12 : 7,
                        lineHeight: mdUp ? "17px" : "8px",
                      }}
                      item
                    >
                      3rd April, 2021
                    </Grid>
                    <Grid
                      style={{
                        fontSize: mdUp ? 16 : 12,
                        lineHeight: mdUp ? "25px" : "17.5px",
                        marginTop: 5,
                        fontWeight: 600,
                      }}
                      className={clsx(
                        WebStyles.hankrndFont,
                        WebStyles.paraBlackText
                      )}
                      item
                      align="left"
                    >
                      Getting Started Guide for Home Buyers
                    </Grid>
                  </Grid>
                </Card>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}
