import React from "react";
import { Grid, Container } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import FeatureItem from "./FeatureItem";
import clsx from "clsx";

function Features({ smUp, smDown, mdUp, lgUp, lgDown, xlUp, commonPadding }) {
  const features = [
    {
      name: "Find A Trustable Local Plumber",
      color: "#72cac9",
      image: "/images/home_buyers/Illustration 14.svg",
      description:
        "At Souqh we believe, no validation is as important as that provided by people who have actually used a service. While we have several tools that allow you to call and connect with a service provider without leaving the platform, we consider client feedback to be a key part of every storefront.",
    },
    {
      name: "Trusted Service Providers",
      color: "#72cac9",
      image: "/images/home_buyers/Trusted Service Providers.svg",
      description:
        "Worried about the right fit? Souqh helps you find trusted service providers across a pool of storefronts that match your unique needs, along with verified ratings and testimonials from home buyers just like you.",
    },
    {
      name: "Simplify Your Home Ownership Journey",
      color: "#F37C61",
      image: "/images/home_buyers/Illustartion 15.svg",
      description:
        "We believe that Souqh is the only such service in Canada that actually works. Simplify your homeownership journey, starting with finding the right local plumber today.",
    },
    {
      name: "Communicate Freely",
      color: "#F37C61",
      image: "/images/home_buyers/Illustration 16.svg",
      description:
        "Tired of calling, texting and overflown inboxes with missed attachments? Souqh simplifies all communications with service providers, right on the platform, with easy access and interaction history.",
    },
    {
      name: "Earn Credits",
      color: "#B872AF",
      image: "/images/home_buyers/Illustartion 17.svg",
      description:
        "Every time you interact on Souqh or refer a service provider, you earn credits that can be used for future services. It pays to transact on Souqh!",
    },
    
  ];
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        // backgroundImage: lgUp
        //   ? "url('/images/home_buyers/Purple patch.svg'), url('/images/home_buyers/orange patch_right_2.svg')"
        //   : "none",
        backgroundRepeat: "no-repeat, no-repeat, no-repeat",
        backgroundSize: "10%, 7%",
        backgroundPosition: "0% 50%, 100% 35%",
        marginTop: lgUp && 48,
        padding: commonPadding,
      }}
    >
      <Container>
        <Container>
          <Grid item xs={12} lg={12} container style={{ paddingBottom: 40 }}>
            <Grid
              item
              xs={12}
              style={{
                marginBottom: 48,
              }}
            >
              <Grid
                align="center"
                className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
                style={{
                  fontSize: mdUp ? 40 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                }}
              >
                So many great features to help your journey
              </Grid>
              <Grid
                align="center"
                className={clsx(WebStyles.paraBlackText, WebStyles.nunitoFont)}
                style={{
                  fontSize: mdUp ? 28 : 14,
                  lineHeight: mdUp ? "38px" : "19px",
                  marginBottom: mdUp ? 12 : 8,
                }}
              >
                The complete home buying journey is right at your fingertips,
                anywhere, anytime.
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {features.map((item, index) => {
                return (
                  <FeatureItem
                    key={index}
                    smUp={smUp}
                    mdUp={mdUp}
                    feature={item}
                    isEven={index % 2 === 0}
                    lgUp={lgUp}
                    lgDown={lgDown}
                    index={index}
                    smDown={smDown}
                  />
                );
              })}
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}

export default Features;
