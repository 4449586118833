import React from "react";
import { Container, Grid, Typography } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import StepBox from "./StepBox";
import clsx from "clsx";

export default function HowItWorks({ mdUp, lgUp, lgDown, commonPadding }) {
  const steps = [
    {
      name: "Enter Your Project Location",
      description: "",
      image: "/images/service_providers/laptop.svg",
      numImg: "/images/service_providers/one.svg",
    },
    {
      name: "Tell Us More About Your Project",
      description: "",
      image: "/images/service_providers/search.svg",
      numImg: "/images/service_providers/two.svg",
    },
    {
      name: "Get Matched With Hundreds Of Professionals For Free",
      description: "Get Connected With A Professional For Free!",
      image: "/images/service_providers/hand_cell.svg",
      numImg: "/images/service_providers/three.svg",
    },
  ];
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: commonPadding,
      }}
    >
      <Container>
        <Grid
          item
          xs={12}
          lg={12}
          container
          style={{ marginTop: 72, paddingBottom: 104 }}
        >
          <Grid
            item
            xs={12}
            style={{
              marginBottom: 12,
            }}
          >
            <Grid
              className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
              style={{
                fontSize: mdUp ? 40 : 30,
                lineHeight: mdUp ? "50px" : "37px",
                textAlign: "center",
                marginBottom: 8,
                fontWeight: 600,
              }}
            >
              Let's help you find the right professional for your home reno
              project
            </Grid>
            <Grid
              className={clsx(WebStyles.paraBlackText, WebStyles.nunitoFont)}
              style={{
                fontSize: mdUp ? 28 : 14,
                lineHeight: mdUp ? "38px" : "19px",
                marginBottom: 24,
                textAlign: "center",
              }}
            >
              Get started in three simple steps
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            container
            style={{
              marginBottom: 16,
            }}
          >
            {steps.map((item, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  md={4}
                  container
                  key={index}
                  style={{
                    marginBottom: 16,
                  }}
                >
                  <StepBox
                    index={index}
                    data={item}
                    mdUp={mdUp}
                    lgUp={lgUp}
                    lgDown={lgDown}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
