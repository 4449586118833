import React, { useState } from "react";
import { Grid, Container, IconButton, Card, Hidden } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Link } from "react-router-dom";
const investorsList = [
  {
    name: "Zarifa Bahran",
    // designation: "RE/MAX – Moe Peyawary",
    designation: "Realtor",
    // companyName: "Real Estate Team",
    companyName: "RE/MAX – Moe Peyawary Real Estate",
    imageUrl: "/images/aboutus/zarifa.png",
    linkedIn:
      "https://www.linkedin.com/in/zarifa-bahran-106902185/?originalSubdomain=ca",
  },
  {
    name: "Youssef Azad",
    designation: "Mortgage Broker",
    companyName: "Dominion Lending",
    imageUrl: "/images/aboutus/youssef.png",
    linkedIn: "https://www.linkedin.com/in/azad-mortgages-inc-7978b0126/",
  },
  {
    name: "Mujir Muneeruddin",
    // designation: "EVP, M&A and Partnerships, FaceDrive Founding Partner",
    designation: "EVP, FaceDrive",
    // companyName: "Abrahams LLP",
    companyName: "Founding Partner, Abrahams LLP",
    imageUrl: "/images/aboutus/mujir.png",
    linkedIn:
      "https://www.linkedin.com/in/mujir-a-muneeruddin-j-d-ll-m-27567520/",
  },
  {
    name: "Tanweer Abbas",
    designation: "CFO,",
    companyName: "Branthaven Homes",
    imageUrl: "/images/aboutus/tanweer.png",
    linkedIn: "https://www.linkedin.com/in/tanweerabbas/",
  },
  {
    name: "Omar Khalifa",
    designation: "Managing Director",
    companyName: "Fengate Asset Management",
    imageUrl: "/images/aboutus/omar.png",
    linkedIn: "https://www.linkedin.com/in/omar-khalifa-1911362b/",
  },
  {
    name: "Akif Siddiqui",
    designation: "Partner ",
    companyName: "PwC",
    imageUrl: "/images/aboutus/akif.png",
    linkedIn: "https://www.linkedin.com/in/akifsiddiqui/",
  },
  {
    name: "Feroz Jessani",
    designation: "President",
    companyName: "Royal Canadian Steel",
    imageUrl: "/images/aboutus/feroz.png",
    linkedIn: "https://www.linkedin.com/in/feroz-jessani-58997b5/",
  },
];
function InvevstorComponent({ data, smUp, mdUp, lgUp, xlUp }) {
  return (
    <Grid
      container
      direction="column"
      style={{
        marginBottom: xlUp ? 119 : mdUp ? 65 : 31,
      }}
    >
      <Card
        elevation={0}
        style={{
          padding: xlUp ? "10px 20px" : 0,
          height: xlUp ? 480 : 395,
          width: xlUp ? 274 : lgUp ? 235 : "98%",
          // width: xlUp ? 274 : "100%",
          border: "1px solid #E2E2E2",
          borderRadius: 12,
          padding: 5,
          // padding: xlUp ? 5 : 0,
        }}
      >
        <Grid
          container
          item
          xs={12}
          justifyContent="center"
          style={{ height: "100%" }}
        >
          <Grid container item xs={12} justifyContent="center">
            <img
              src={data.imageUrl}
              style={{ width: xlUp ? 232 : 195, height: xlUp ? 232 : 196 }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              fontSize: mdUp ? 26 : 24,
              lineHeight: "48px",
              fontWeight: 600,
            }}
            className={clsx(WebStyles.captionViolet, WebStyles.hankrndFont)}
          >
            {data.name}
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              fontSize: lgUp ? 18 : 16,
              lineHeight: lgUp ? "24px" : "20px",
            }}
            className={clsx(WebStyles.hankrndFont)}
          >
            {data.designation}
          </Grid>
          <Grid
            style={{
              fontSize: lgUp ? 18 : 16,
            }}
            className={clsx(WebStyles.hankrndFont)}
          >
            {data.companyName}
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            <Link to={{ pathname: data.linkedIn }} target="_blank">
              <IconButton>
                <img
                  src="/images/aboutus/linkedIn.svg"
                  style={{ width: 30, height: 30 }}
                />
              </IconButton>
            </Link>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
function InvestorsSection({ smUp, mdUp, lgUp, xlUp, commonPadding }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sliceFactor = lgUp ? 0 : mdUp ? 2 : 1;
  let formattedArray = sliceFactor
    ? investorsList.slice(currentIndex, currentIndex + sliceFactor)
    : investorsList;
  formattedArray = formattedArray.map((item, index) => {
    return (
      <Grid
        container
        item
        xs={12}
        sm={5}
        md={5}
        lg={3}
        key={index}
        style={{ padding: "0px 16px" }}
      >
        <InvevstorComponent
          data={item}
          smUp={smUp}
          mdUp={mdUp}
          lgUp={lgUp}
          xlUp={xlUp}
        />
      </Grid>
    );
  });
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: commonPadding,
        position: "relative",
        backgroundImage: lgUp
          ? "url('/images/aboutus/purple_patch.svg')"
          : "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "10%",
        backgroundPosition: "0 25%",
      }}
    >
      <div
        style={
          lgUp
            ? {
                zIndex: 1,
                backgroundImage: "url('/images/aboutus/blue_patch_right.svg')",
                backgroundRepeat: "no-repeat",
                height: "500px",
                width: "400px",
                position: "absolute",
                right: "0px",
                bottom: "19%",
                backgroundSize: "33%",
                backgroundPosition: "right",
              }
            : {}
        }
      />
      <Container>
        <Grid
          container
          style={{
            marginTop: xlUp ? 142 : mdUp ? 105 : 51,
          }}
          justifyContent="center"
        >
          <Grid
            item
            xs={12}
            sm={12}
            lg={12}
            xl={12}
            className={clsx(WebStyles.captionViolet, WebStyles.hankrndFont)}
            style={{
              fontSize: xlUp ? 80 : mdUp ? 60 : 40,
              lineHeight: xlUp ? "74px" : mdUp ? "64px" : "42px",
              fontWeight: 600,
            }}
          >
            Key Advisors/Investors
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            lg={12}
            xl={12}
            className={clsx(WebStyles.barlowFont)}
            style={{
              marginTop: xlUp ? 23 : mdUp ? 11 : 16,
              fontSize: mdUp ? 28 : 14,
              lineHeight: "38px",
            }}
          >
            Meet some of our Investors and Advisors
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          style={{
            justifyContent: "center",
            paddingBottom: 40,
            marginTop: xlUp ? 105 : mdUp ? 56 : 31,
          }}
        >
          <Hidden lgUp>
            <Grid
              item
              xs={1}
              container
              style={{ alignItems: "center", justifyContent: "flex-start" }}
            >
              <ArrowBackIosIcon
                disabled={currentIndex < 1}
                onClick={() => {
                  if (currentIndex > 0) {
                    setCurrentIndex(currentIndex - 1);
                  }
                }}
                style={{
                  width: 36,
                  height: 36,
                  fontSize: 36,
                  cursor: currentIndex > 0 ? "pointer" : "default",
                  fill: "#B872AF",
                  opacity: currentIndex > 0 ? 1 : "0.5",
                }}
              />
            </Grid>
          </Hidden>
          <Grid
            item
            xs={10}
            md={10}
            sm={10}
            lg={12}
            xl={12}
            spacing={lgUp ? 4 : null}
            container
            style={{ justifyContent: "center" }}
          >
            {formattedArray}
          </Grid>
          <Hidden lgUp>
            <Grid
              item
              xs={1}
              container
              style={{ alignItems: "center", justifyContent: "flex-end" }}
            >
              <ArrowForwardIosIcon
                onClick={() => {
                  if (currentIndex < investorsList.length - sliceFactor) {
                    setCurrentIndex(currentIndex + 1);
                  }
                }}
                style={{
                  width: 36,
                  height: 36,
                  fontSize: 36,
                  cursor:
                    currentIndex < investorsList.length - 3
                      ? "pointer"
                      : "default",
                  fill: "#B872AF",
                  opacity: currentIndex < investorsList.length - 3 ? 1 : "0.5",
                }}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    </Grid>
  );
}

export default InvestorsSection;
