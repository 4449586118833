import React, { useState } from "react";
import { Grid, Button, TextField, Container } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import HomeWebForm from "../home/HomeWebForm";

function InfoSection({
  xlUp,
  mdUp,
  mdDown,
  smDown,
  xsDown,
  setShowWaitListForm,
}) {
  return (
    <Grid container item xs={12} md={6} alignContent="center">
      <Grid item xs={12}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: xlUp ? 80 : mdUp ? 60 : 40,
            lineHeight: 1,
            marginBottom: mdUp ? 24 : 12,
            fontWeight: 600,
          }}
        >
          Souqh is your one-stop connection
        </Grid>
      </Grid>
      <Grid item xs={12} md={10}>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 28 : 14,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
          Managing your home journey has never been easier with Souqh.
        </Grid>
      </Grid>
      {/* <Grid item xs={12} md={6} style={{ marginTop: mdUp ? 20 : 10 }}>
      <TextField fullWidth placeholder="Your email"></TextField>
    </Grid> */}
      <Grid
        container
        item
        xs={12}
        md={7}
        alignContent="center"
        justifyContent={mdDown && "flex-end"}
      >
        <Button
          style={{
            backgroundColor: "#F37C61",
            color: "#FFFFFF",
            textTransform: "none",
            borderRadius: mdUp ? 24 : 12,
            marginTop: smDown ? 20 : 0,
            // marginLeft: lgUp ? 20 : 0,
            fontSize: mdUp ? 20 : 14,
            padding: "8px 24px",
            marginRight: xsDown && 24,
          }}
          onClick={() => {
            // setShowWaitListForm(true);
            window.open("https://app.souqh.ca/homebuyersignup", "_blank");
          }}
        >
          <Grid className={WebStyles.hankrndFont}>Sign up now for free!</Grid>
        </Button>
      </Grid>
    </Grid>
  );
}

function ImageSection() {
  return (
    <Grid item xs={12} md={6}>
      <img
        src="/images/home_buyers/One stop connection.svg"
        style={{
          // height: xlUp ? 625 : mdUp ? 313 : 219,
          width: "100%",
        }}
      />
    </Grid>
  );
}

function Introduction({
  smUp,
  smDown,
  mdUp,
  mdDown,
  lgUp,
  xlUp,
  commonPadding,
}) {
  const [showWaitListForm, setShowWaitListForm] = useState(false);
  const handleClose = () => {
    setShowWaitListForm(false);
  };
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        backgroundImage: lgUp
          ? "url('/images/home_buyers/Green patch.svg')"
          : "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "10%",
        padding: commonPadding,
      }}
    >
      <HomeWebForm
        open={showWaitListForm}
        who={"homeBuyer"}
        handleClose={handleClose}
      ></HomeWebForm>
      <Container>
        <Container>
          <Grid container>
            {mdUp ? (
              <Grid container direction="row">
                <InfoSection
                  xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown}
                  setShowWaitListForm={setShowWaitListForm}
                />
                <ImageSection />
              </Grid>
            ) : (
              <Grid container direction="row">
                <ImageSection />
                <InfoSection
                  xlUp={xlUp}
                  mdUp={mdUp}
                  mdDown={mdDown}
                  smDown={smDown}
                  setShowWaitListForm={setShowWaitListForm}
                />
              </Grid>
            )}

            <Grid item xs={12} style={{ marginTop: 30, marginBottom: 20 }}>
              {/* <iframe  style={{
                  width: lgUp ? "70%" : "80%",
                   height: lgUp ? 500 : mdUp ? 315 : 201,
                }}  src="https://www.youtube.com/embed/gxnCg5rdNzA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}

              <video
                // src="https://dl.dropboxusercontent.com/s/p2yvsy9hu3yzbez/Souqh_video_draft03.mp4"
                src="/images/Souqh_video_Type1_HD03.mp4"
                preload="auto"
                controls
                poster="/images/0IXGLLk0.png"
                // autoPlay
                muted
                style={{
                  width: lgUp ? "70%" : "80%",
                  // height: lgUp ? 764 : mdUp ? 459 : 201,
                }}
              >
                Video not supported
              </video>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}

export default Introduction;
