import React, { Suspense } from "react";
import {
  Toolbar,
  useMediaQuery,
  useTheme,
  CssBaseline,
  Container,
} from "@material-ui/core";
import Header, {
  APP_BAR_HEIGHT_DESKTOP,
  APP_BAR_HEIGHT_MOBILE,
} from "./Header";
import Footer from "./Footer";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import AboutUsPage from "./aboutUs";
import ContactUsPage from "./contactUs";
import HomePage from "./home";
import HomeBuyersPage from "./homeBuyers";
import LandingPage from "./landingPage";
import GoodRealAgentPage from "./goodRealAgent";
import ServiceProvidersPage from "./serviceProviders";
import WebsiteIntercomWrapper from "../WebsiteIntercomWrapper";
import MarketPlaceRedirection from "./MarketPlaceRedirection";
import RealtorsList from "./realtorsList";
import RealEstateLawyerList from "./realEstateLaywerList";
import RealEstateAgentSellsYourHouse from "./realEstateAgentSellsYourHouse";
import BecomeRealEstateAgent from "./becomeRealEstateAgent";
import ServicesPage from "./services";
import MortgageBrokerLists from "./mortageBrokerList";
import HandyManServices from "./handyManServices";
import MovingServices from "./movingServices";
import MoverServicesList from "./moverServicesList";
import ReapprovedMortgageBroker from "./reapprovedMortgageBroker";
import PlumbingServices from "./plumbingService";
import RealEstateLawyers from "./realEstateLawyers";
import PlumberServicesList from "./plumbingServicesList";
import HandyManServicesList from "./handyManServicesList";
//import SueRealEstateAgent from "./SueRealEstateAgentForNegligenceInOntario"
export default function MainLayout(props) {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const xlUp = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <React.Fragment>
      <Container maxWidth={false} style={{ padding: 0 }}>
        <WebsiteIntercomWrapper>
          <Router>
            <Suspense fallback={<div>Loading...</div>}>
              <CssBaseline />
              <Header></Header>
              <Toolbar
                style={{
                  height: xlUp
                    ? APP_BAR_HEIGHT_DESKTOP
                    : mdUp
                    ? 192
                    : APP_BAR_HEIGHT_MOBILE,
                }}
              />
              <Switch>
                <Route path="/contact-us">
                  <ContactUsPage />
                </Route>
                <Route path="/homeowner-signup">
                  <LandingPage />
                </Route>
                <Route path="/landing-page">
                  <LandingPage />
                </Route>
                {/* <Route path="/landing-page-contact">
                                    <LandingPageContact />
                                </Route> */}
                <Route path="/what-makes-a-good-real-estate-agent">
                  <GoodRealAgentPage />
                </Route>
                <Route path="/about-us">
                  <AboutUsPage />
                </Route>
                <Route path="/home-buyers">
                  <HomeBuyersPage />
                </Route>
                <Route path="/service-providers">
                  <ServiceProvidersPage />
                </Route>
                <Route path="/marketplace">
                  <MarketPlaceRedirection />
                </Route>

                <Route path="/Real-Estate-Broker">
                  <RealtorsList />
                </Route>
                <Route path="/Real-Estate-Lawyer">
                  <RealEstateLawyerList />
                </Route>
                <Route path="/real-estate-agent-sells-your-house">
                  <RealEstateAgentSellsYourHouse />
                </Route>
                <Route path="/become-a-real-estate-agent">
                  <BecomeRealEstateAgent />
                </Route>
                <Route path="/services">
                  <ServicesPage />
                </Route>
                <Route exact path="/">
                  <HomePage />
                </Route>
                <Route path="/services">
                  <ServicesPage />
                </Route>
                <Route path="/Mortgage-Broker">
                  <MortgageBrokerLists />
                </Route>
                <Route path="/Mover">
                  <MoverServicesList />
                </Route>
                <Route path="/Plumbing">
                  <PlumberServicesList />
                </Route>
                <Route path="/handy-man-services">
                  <HandyManServices />
                </Route>
                <Route path="/Handyman-Services">
                  <HandyManServicesList />
                </Route>
                <Route path="/moving-services">
                  <MovingServices />
                </Route>
                <Route path="/reapproved-by-mortgage-broker">
                  <ReapprovedMortgageBroker />
                </Route>
                <Route path="/plumbing-services">
                  <PlumbingServices />
                </Route>
                <Route path="/real-estate-lawyers">
                  <RealEstateLawyers />
                </Route>

                <Route path="*" render={() => <Redirect to="/" />}></Route>
              </Switch>
              <Footer></Footer>
            </Suspense>
          </Router>
        </WebsiteIntercomWrapper>
      </Container>
    </React.Fragment>
  );
}
