import React, { useEffect } from "react";
import { useMediaQuery, useTheme } from "@material-ui/core";
import SubFooter from "../SubFooter";
import ServicesLayout from "../ServicesLayout";

function HandyManServicesList() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const xsUp = useMediaQuery(theme.breakpoints.up("xs"));
  const title = "Get Started with Souqh";
  const subTitle =
    "If you are looking for the best realtors in your area anywhere in Canada, Souqh has the solution for you. Souqh is a marketplace for real estate service providers, from realtors, real estate lawyers, to contractors.";
  const btnText = "Sign Up Now!";
  const redirectUrl = "";

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const SP_QUESTION_ANSWERS = [];

  const HB_QUESTION_ANSWERS = [
    {
      id: 1,
      question: "Finding a handy man",
      //answer:
      //"We know from our own experience how overwhelming and stressful the home journey can be and we were determined to make the process easier. Souqh is Canada’s only end-to-end digital services marketplace who supports home buyers looking for guidance with their home buying journey. Finding a trusted service provider, such as a realtor, mortgage broker, lawyer, or general contractor, has never been easier. From verified reviews, to documentation that is easy to access and locate all in one place, Souqh simplifies your home journey. And the best part? It’s all free!",
      link: "/handy-man-services",
    },
  ];

  const features = [
    {
      name: "Reliable and trustable",
      color: "#72cac9",
      image: "/images/home_buyers/Illustration 14.svg",
      description:
        "ook for a handyman with a traceable record and not hire chancers looking for a quick buck. To protect yourself, do not work with a handyman who asks for payment upfront.",
    },
    {
      name: "Safety-conscious",
      color: "#72cac9",
      image: "/images/home_buyers/Trusted Service Providers.svg",
      description:
        "Your property’s security is important, and so is your family’s safety. So a good handyman should be skilled enough to know how to safely handle electricity as well as their equipment so that they don’t put your family’s safety at risk.",
    },
    {
      name: "Tidy and thorough",
      color: "#F37C61",
      image: "/images/home_buyers/Illustartion 15.svg",
      description:
        "A good handyman is tidy and makes sure not to smear your walls and stain or dent your furniture as they work. They use the right equipment which they are always careful with and pack up after they are done.",
    },
    {
      name: "Find A Handyman To Hire In Canada",
      color: "#F37C61",
      image: "/images/home_buyers/Illustration 16.svg",
      description:
        "Souqh has a simple, user-friendly interface with advanced search features that filter candidates down to the local area. It provides handymen and other service providers with a storefront on which they can advertise their services and share other information you need to decide if they fit your needs.",
    },
    {
      name: "Earn Credits",
      color: "#B872AF",
      image: "/images/home_buyers/Illustartion 17.svg",
      description:
        "Every time you interact on Souqh or refer a service provider, you earn credits that can be used for future services. It pays to transact on Souqh!",
    },
    {
      name: "Entrust Your Home Repairs To The Right People",
      color: "#B872AF",
      image: "/images/home_buyers/Illustration 18.svg",
      description:
        "Souqh is a user-friendly and secure environment to work in. After the handyman has completed their task, feel free to have a look around. ",
    },
  ];

  return (
    <div
      style={{
        overflow: "hidden",
        position: "relative",
        top: smUp ? (lgUp ? 55 : 60) : 78,
        marginBottom: 105,
      }}
    >
      <ServicesLayout
        smUp={smUp}
        mdUp={mdUp}
        lgUp={lgUp}
        lgDown={lgDown}
        xsUp={xsUp}
        HB_QUESTION_ANSWERS={HB_QUESTION_ANSWERS}
        SP_QUESTION_ANSWERS={SP_QUESTION_ANSWERS}
        features={features}
        btnText="Handyman"
        title="Handy Man Services"
        serviceFeatureSubTitle="Souqh, an online marketplace for service providers you need at every stage of your homeownership journey has made the process a local handyman easy and painless."
        serviceName="Handyman Services"
      >
        Whether you need a handyman in Toronto, Ottawa, Mississauga, Surrey,
        hamilton, Edmonton, or Guelph, Souqh is where you can get one that’s
        trustable and is closest to you. Find a handyman near you.
        <br />
        <br />
      </ServicesLayout>
      <SubFooter
        title={title}
        subTitle={subTitle}
        btnText={btnText}
        redirectUrl={redirectUrl}
      />
    </div>
  );
}

export default HandyManServicesList;
