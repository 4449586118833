import React, { useEffect, useState } from "react";
import HowItWorks from "./HowItWorks";
import { Grid, useMediaQuery, useTheme } from "@material-ui/core";
import Features from "./Features";

import SubFooter from "../SubFooter";
import FrequentlyAskedQuestions from "./FrequentlyAskedQuestions";

//import HomeJournerySimplified from "./HomeJournerySimplified";
import CompanyLogos from "../serviceProviders/CompanyLogos";

function PlumberServicesList() {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const mdDown = useMediaQuery(theme.breakpoints.down("md"));
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const lgDown = useMediaQuery(theme.breakpoints.down("lg"));
  const smDown = useMediaQuery(theme.breakpoints.down("sm"));
  const xsUp = useMediaQuery(theme.breakpoints.up("xs"));
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));
  const commonPadding = lgUp ? "0px 6%" : "0px 2%";
  // const commonPadding = 0;
  const [selectedTab, setselectedTab] = useState(0);
  const title = "Get Started with Souqh";
  const subTitle =
    "If you are looking for the best realtors in your area anywhere in Canada, Souqh has the solution for you. Souqh is a marketplace for real estate service providers, from realtors, real estate lawyers, to contractors.";
  const btnText = "Sign Up Now!";
  const redirectUrl = "";

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div
      style={{
        overflow: "hidden",
        position: "relative",
        top: smUp ? (lgUp ? 55 : 60) : 78,
        marginBottom: 105,
      }}
    > <FrequentlyAskedQuestions
    smUp={smUp}
        mdUp={mdUp}
        lgUp={lgUp}
        lgDown={lgDown}
        xsUp={xsUp}
        
    
  ></FrequentlyAskedQuestions>
    
      <SubFooter
        title={title}
        subTitle={subTitle}
        btnText={btnText}
        redirectUrl={redirectUrl}
      /> 
    </div>
  );
}

export default PlumberServicesList;
