import React, { useState } from "react";
import { Grid, Button, TextField, Container, Hidden  } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";

function SectionThree({
  xlUp,
  mdUp,
  smUp,
  xsUp,
  mdDown,
  smDown,
  xsDown,
  setShowWaitListForm,
}) {
  return (
    <Container>
      <Container>
    <Grid container item xs={12}  md={12} alignContent="center" style={{
     paddingLeft: mdUp ? "10%" : smUp ? "5%" : "0",
       paddingRight: mdUp? "10%" : smUp ? "5%" : "0"
    }}>
      <Grid item xs={12}>
        <Grid
          align="left"
          className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 50 : 30,
           
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
            marginTop : mdUp ? 12:8,
          }}
        >
        Where To A Find A Trustable Local Plumber


        </Grid>
      </Grid>
      
      <Grid container item xs={12}>
        <Grid item xs={12} md={4} style={{
          //paddingLeft: mdUp ? "2%" : smUp ? "1%" : "0",
          padding: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ?  35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
         Ditch the traditional methods
        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
             lineHeight: 1.5,
            fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
  While it’s ideal to have a reliable local plumber on speed dial, the majority of us lack that level of emergency preparedness. The traditional methods of asking for recommendations from friends and family still work, but they are not reliable.

  <Hidden mdUp lgUp smUp xsUp>
  </Hidden>
</Grid>
        {/* <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 18 : 16,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
       For instance, the realtor was given incorrect information from a previous realtor or the homeowner and passed this on to the client. This is grounds to sue, however, you’re most likely to have only the damages compensated financially.
        </Grid> */}
        </Grid>
        <Grid item xs={12} md={4} style={{
          padding: mdUp ? "2%" : smUp ? "1%" : "0",
          //paddingRight: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
          Searching on Google
        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
             lineHeight: 1.5,
            fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
     For most people, Google is the next best option. While a local plumber is indeed a Google search away in most cases, it is not as easy to verify the claimed skills. Not as easy as the Souqh platform makes it.

      <Hidden mdUp smUp lgUp xsUp>

      </Hidden>
</Grid>
        
        </Grid>
        <Grid item xs={12} md={4} style={{
          padding: mdUp ? "2%" : smUp ? "1%" : "0",
          //paddingRight: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
          Trusted Plumbers at Souqh
        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
             lineHeight: 1.5,
            fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
      Souqh is a home services marketplace that provides service providers and contractors with an online storefront that has enough real estate to display all the important information about their business, qualifications, professional memberships, contact information, and reviews by previous clients.

      <Hidden mdUp lgUp xsUp smUp>
            </Hidden>
</Grid>
        
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} md={4} style={{
          //paddingLeft: mdUp ? "2%" : smUp ? "1%" : "0",
          padding: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ?  35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
          Reviews
        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
             lineHeight: 1.5,
            fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
  Among other important information, service providers on Souqh can list on their storefront their number of years in business, their areas of specialization, and their preferred payment methods. Souqh also makes it easy for you to leave a review on a plumber’s storefront for the benefit of people just like you.
<Hidden mdUp lgUp smUp xsUp xlUp>
At Souqh we believe, no validation is as important as that provided by people who have actually used a service. While we have several tools that allow you to call and connect with a service provider without leaving the platform, we consider client feedback to be a key part of every storefront. 
<br/>
Reviews are so important to us that we reward clients that take time to leave a review with points they can redeem for a discount the next time they use Souqh. So after your plumber is finished, kindly share your honest feedback on the overall experience working with them by leaving a review on their storefront.
<br/>
  </Hidden>
</Grid>
     
        </Grid>
      
      </Grid>
      {/* <Grid item xs={12} md={6} style={{ marginTop: mdUp ? 20 : 10 }}>
      <TextField fullWidth placeholder="Your email"></TextField>
    </Grid> */}
    


    </Grid>
    </Container>
    </Container>
  );
}



// function Introduction({
//   smUp,
//   smDown,
//   mdUp,
//   mdDown,
//   lgUp,
//   xlUp,
//   commonPadding,
// }) {
//   const [showWaitListForm, setShowWaitListForm] = useState(false);
//   const handleClose = () => {
//     setShowWaitListForm(false);
//   };
//   return (
//     <Grid
//       item
//       container
//       xs={12}
//       style={{
//         backgroundImage: lgUp
//           ? "url('/images/home_buyers/Green patch.svg')"
//           : "none",
//         backgroundRepeat: "no-repeat",
//         backgroundSize: "10%",
//         padding: commonPadding,
//       }}
//     >

//       <Container>
//         <Container>
//           <Grid container>
          
//               <Grid container direction="row">
//                 <SectionTwo
//                   xlUp={xlUp}
//                   mdUp={mdUp}
//                   mdDown={mdDown}
//                   smDown={smDown}

//                 />
                
//               </Grid>
          

//           </Grid>
//         </Container>
//       </Container>
//     </Grid>
//   );
// }

export default SectionThree;
