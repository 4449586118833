import { Container, Grid, Hidden } from "@material-ui/core";
import React from "react";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";

function Section4({ smUp, mdUp, lgUp, commonPadding }) {
  const moreFeatures = [
    {
      name: "Ditch the stress",
      description:
        "Buying a home can be a stressful life decision - we designed Souqh to help eliminate some of that stress.",
    },
    {
      name: "Trusted Service Providers",
      description:
        "Worried about the right fit? Souqh helps you find trusted service providers across a pool of storefronts that match your unique needs.",
    },
    {
      name: "Communicate Freely",
      description:
        "Souqh streamlines all your documents in a simple and secure portal, allowing you to create, share, e-sign and upload documents directly to service providers.",
    },
    {
      name: "Streamlined Documents",
      description:
        "Tired of calling, texting and overflown inboxes with missed attachments? Souqh simplifies all communications with service providers.",
    },
    {
      name: "Earn Rewards",
      description:
        "Every time you transact on Souqh or refer a service provider, you earn credits that can be used for future services. It pays to use Souqh!",
    },
    {
      name: "Rest Easy",
      description:
        "Rest easy knowing that you completely own and control your data.",
    },
  ];

  const firstThreeItems = moreFeatures.slice(0, 3);
  const lastThreeItems = moreFeatures.slice(3, 7);

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        backgroundImage: lgUp
          ? "url('/images/home_page/Purple strip.svg')"
          : "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "10%",
        padding: commonPadding,
        marginTop: 20,
      }}
    >
      <Container>
        <Container>
          <Grid container>
            <Grid
              item
              xs={12}
              className={clsx(WebStyles.purpleTxt, WebStyles.hankrndFont)}
              style={{
                fontSize: mdUp ? 40 : 20,
                fontWeight: 600,
              }}
            >
              More great features to help your journey
            </Grid>
            <Grid
              item
              xs={12}
              className={clsx(WebStyles.paraBlackText, WebStyles.nunitoFont)}
              style={{ marginBottom: mdUp ? 56 : 20, fontSize: mdUp ? 28 : 14 }}
            >
              Here's why home buyers and owners love Souqh
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {firstThreeItems.map((item) => (
                <Grid
                  container
                  item
                  xs={12}
                  style={{ marginBottom: 24 }}
                  key={item.name}
                >
                  <Grid
                    item
                    xs={1}
                    style={{
                      backgroundColor: "#B872AF33",
                      height: lgUp ? 23 : 20,
                      maxWidth: lgUp ? 24 : 20,
                      borderRadius: "100%",
                      marginTop: lgUp ? 10 : 3,
                    }}
                  ></Grid>
                  <Grid
                    container
                    item
                    xs={11}
                    justifyContent="flex-start"
                    style={{ paddingLeft: 20 }}
                  >
                    <Grid
                      item
                      xs={12}
                      className={clsx(
                        WebStyles.purpleTxt,
                        WebStyles.hankrndFont
                      )}
                      style={{
                        textAlign: "left",
                        fontSize: mdUp ? 28 : 14,
                        fontWeight: 600,
                      }}
                    >
                      {item.name}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={clsx(
                        WebStyles.paraBlackText,
                        WebStyles.nunitoFont
                      )}
                      style={{ textAlign: "left", fontSize: mdUp ? 20 : 10 }}
                    >
                      {item.description}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Hidden mdDown>
              <Grid item xs={4}>
                <img
                  src="/images/home_page/Illustration 22.svg"
                  style={{
                    height: 530,
                    // width: 300, marginTop: -51
                  }}
                />
              </Grid>
            </Hidden>
            <Grid item xs={12} md={6} lg={4}>
              {lastThreeItems.map((item) => (
                <Grid
                  container
                  item
                  xs={12}
                  style={{ marginBottom: 24 }}
                  key={item.name}
                >
                  <Grid
                    item
                    xs={1}
                    style={{
                      backgroundColor: "#B872AF33",
                      height: 23,
                      maxWidth: 24,
                      borderRadius: "100%",
                      marginTop: lgUp ? 10 : 0,
                    }}
                  ></Grid>
                  <Grid
                    container
                    item
                    xs={11}
                    justifyContent="flex-start"
                    style={{ paddingLeft: 20 }}
                  >
                    <Grid
                      item
                      xs={12}
                      className={clsx(
                        WebStyles.purpleTxt,
                        WebStyles.hankrndFont
                      )}
                      style={{
                        textAlign: "left",
                        fontSize: mdUp ? 28 : 14,
                        fontWeight: 600,
                      }}
                    >
                      {item.name}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={clsx(
                        WebStyles.paraBlackText,
                        WebStyles.nunitoFont
                      )}
                      style={{ textAlign: "left", fontSize: mdUp ? 20 : 10 }}
                    >
                      {item.description}
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}

export default Section4;
