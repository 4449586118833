import React from "react";
import { Grid, Container } from "@material-ui/core";
import BusinessFeatureItem from "./BusinessFeatureItem";

function BusinessFeatures({
  smDown,
  mdUp,
  lgUp,
  smUp,
  lgDown,
  xlUp,
  commonPadding,
}) {
  const businessFeatures = [
    {
      name: "Find Realtors",
      color: "#72cac9",
      image: "/images/home_page/Illustartion 18.svg",
      description:
        "Simply search, compare, chat and connect with realtors based on their profiles, locations, reviews and areas of specialization.",
    },
    {
      name: "Secure Financing",
      color: "#72cac9",
      image: "/images/home_page/Illustration 19.svg",
      description:
        "Getting your mortgage approval just got easier. Use our platform to search and transact with independent mortgage brokers. Upload documents, e-sign contracts and fast-track your mortgage approval process.",
    },
    {
      name: "Connect with Lawyers",
      color: "#F37C61",
      image: "/images/home_page/Illustration 20.svg",
      description:
        "Paperwork can be complicated. We help you find the right lawyers to complete all your closing documents and pay legal fees, without leaving your couch.",
    },
    {
      name: "Make your move",
      color: "#F37C61",
      image: "/images/home_page/Illustration 21.svg",
      description:
        "Hurray! It's time to plan the move and get you into that dream home.",
    },
  ];
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        backgroundImage: lgUp
          ? "url('/images/home_buyers/Purple patch.svg'), url('/images/home_buyers/orange patch_right_2.svg')"
          : "none",
        backgroundRepeat: "no-repeat, no-repeat, no-repeat",
        backgroundSize: "10%, 7%",
        backgroundPosition: "0% 35%, 100% 35%",
        padding: commonPadding,
        marginTop: 20,
      }}
    >
      <Container>
        <Grid
          item
          xs={12}
          lg={12}
          container
          style={{ paddingBottom: smUp ? 40 : 0 }}
        >
          <Grid item container xs={12} style={{ justifyContent: "center" }}>
            {businessFeatures.map((item, index) => {
              return (
                <BusinessFeatureItem
                  key={index}
                  smDown={smDown}
                  mdUp={mdUp}
                  businessFeature={item}
                  isEven={index % 2 === 0}
                  lgUp={lgUp}
                  lgDown={lgDown}
                  xlUp={xlUp}
                  index={index}
                  smUp={smUp}
                />
              );
            })}
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}

export default BusinessFeatures;
