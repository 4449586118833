import React, { useState } from "react";
import { Grid, Button, TextField, Container } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";
import HomeWebForm from "../home/HomeWebForm";
import PropTypes from "prop-types";


function Introduction({
  smUp,
  smDown,
  mdUp,
  mdDown,
  lgUp,
  xlUp,
  commonPadding,
}) {
  
  return (
    <Grid
      item
      container
      xs={12}
      style={{
        backgroundImage: lgUp
          ? "url('/images/home_buyers/Green patch.svg')"
          : "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "10%",
        padding: commonPadding,
      }}
    >
     
      <Container>
        <Container>
         
            <Grid item xs={12} style={{ marginTop: 30, marginBottom: 20 }}>
              <iframe width="100%" height="415" 
              src="https://www.youtube.com/embed/f_gb6_f0iig"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              >
              </iframe>

            </Grid>
          
        </Container>
      </Container>
    </Grid>
  
    );
}

export default Introduction;
