import { Grid, Container } from "@material-ui/core";
import React from "react";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";

function HomeBuyingFuture({ smUp, mdUp, lgUp, smDown, commonPadding }) {
  const height = lgUp ? 420 : 360;
  const radius = height / 2;

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        padding: commonPadding,
        position: "relative",
        zIndex: 1,
        marginTop: 56,
      }}
    >
      {smUp ? (
        <div
          style={{
            position: "absolute",
            backgroundColor: "#B872AF",
            height: height,
            right: -2000,
            left: lgUp ? "0%" : "-15%",
            bottom: 0,
            top: lgUp ? "-75%" : "-50%",
            zIndex: -1,
            borderTopLeftRadius: lgUp ? radius : "0px",
            borderBottomLeftRadius: lgUp ? radius : "0px",
            transform: "rotate(-10deg)",
            transformOrigin: "center",
            opacity: 0.1,
          }}
        ></div>
      ) : null}

      <Container>
        <Container>
          <Grid
            container
            justifyContent="center"
            direction={lgUp ? "row" : "column-reverse"}
          >
            <Grid container item xs={12} lg={6} alignContent="center">
              <Grid
                className={clsx(WebStyles.captionViolet, WebStyles.hankrndFont)}
                style={{
                  textAlign: smUp ? "left" : "center",
                  fontSize: mdUp ? 40 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginTop: smDown ? 10 : 0,
                  fontWeight: 600,
                }}
              >
                Come see how Souqh is<br></br> re-shaping the future of<br></br>{" "}
                home buying
              </Grid>
              <Grid
                className={clsx(WebStyles.paraBlackText, WebStyles.nunitoFont)}
                style={{
                  textAlign: smUp ? "left" : "center",
                  fontSize: mdUp ? 28 : 14,
                  lineHeight: mdUp ? "32px" : "16px",
                  marginTop: 20,
                }}
              >
                Whether you’re thinking of buying a home or need to hire someone
                for everyday home projects, Souqh helps you connect with the
                right people for free!
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
            <iframe width="100%" height="400" src="https://www.youtube.com/embed/f_gb6_f0iig" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            
              {/* <video
                // src="https://dl.dropboxusercontent.com/s/18c6c0uymlw6lab/Souqh_video_Type1_HD02.mp4"
                src="/images/Souqh_video_Type1_HD03.mp4"
                preload="auto"
                controls
                poster="/images/0IXGLLk0.png"
                // autoPlay
                crossorigin
                muted
                style={{
                  width: lgUp ? 550 : "100%",
                  height: lgUp ? 382 : mdUp ? 459 : 201,
                }}
              >
                Video not supported
              </video> */}
            </Grid>
          </Grid>
        </Container>
      </Container>
    </Grid>
  );
}

export default HomeBuyingFuture;
