import React, { useState } from "react";
import { Grid, Button, TextField, Container, Hidden  } from "@material-ui/core";
import WebStyles from "../../WebStyles.module.css";
import clsx from "clsx";

function SectionThree({
  xlUp,
  mdUp,
  smUp,
  xsUp,
  mdDown,
  smDown,
  xsDown,
  setShowWaitListForm,
}) {
  return (
    <Container>
      <Container>
    <Grid container item xs={12}  md={12} alignContent="center" style={{
     paddingLeft: mdUp ? "10%" : smUp ? "5%" : "0",
       paddingRight: mdUp? "10%" : smUp ? "5%" : "0"
    }}>
      <Grid item xs={12}>
        <Grid
          align="left"
          className={clsx(WebStyles.blueHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 50 : 30,
           
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
            marginTop : mdUp ? 12:8,
          }}
        >
         Why Choose Souqh For Finding A Moving Service

        </Grid>
      </Grid>
      <Hidden xsDown lgDown xlDown smDown mdDown>
          <Grid item xs={12}>
            align="left"
            className={clsx(WebStyles.paraBlackText)}
            style={{
              fontSize: "18px"
            }}
            Canada has many moving companies, some of which are large companies that cross provincial lines and others that are one-truck family-run operations that serve a local area. There are also generalist movers that move anything that needs moving and specialists with a narrow market focus. Souqh makes it easy to choose the right mover for you, among other benefits:

          </Grid>
      </Hidden>
      <Grid container item xs={12}>
        <Grid item xs={12} md={4} style={{
          //paddingLeft: mdUp ? "2%" : smUp ? "1%" : "0",
          padding: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ?  35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
          Searchable Database Of Local Movers
        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
             lineHeight: 1.5,
            fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
Souqh offers a pre-vetted database of trusted local movers for you to review at your convenience, saving you hours of research. Movers on Souqh maintain storefronts where they list the different services they offer, their credentials and qualifications, and other information you need to decide if they fit your needs.

  <Hidden mdUp lgUp smUp xsUp xlUp>
  Choosing a moving company, like any other service provider, requires reading reviews by other people who have used the company’s services. Souqh makes this easy, with a feature that allows users to leave reviews that prospective users can read when comparing services.

  </Hidden>
</Grid>
        {/* <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 18 : 16,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
       For instance, the realtor was given incorrect information from a previous realtor or the homeowner and passed this on to the client. This is grounds to sue, however, you’re most likely to have only the damages compensated financially.
        </Grid> */}
        </Grid>
        <Grid item xs={12} md={4} style={{
          padding: mdUp ? "2%" : smUp ? "1%" : "0",
          //paddingRight: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
          Easily Compare Estimates
        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
             lineHeight: 1.5,
            fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
With Souqh, you simply select ‘moving’ as your desired service on the home page and type in the first few letters of your local area into the search bar. The algorithm immediately gives you a drop-down of suggestions from which you will pick your location. In a matter of seconds, you will have all the movers that service your area.

      <Hidden mdUp smUp lgUp xsUp xlUp>
      Searching Google, asking around, and compiling a shortlist of local movers takes effort. By pooling movers into one database that is searchable by local area, Souqh has done all this legwork for you.
     <br/>
     Each storefront has options to call, message, or email the mover and request an estimate. You do all of this without ever leaving Souqh. This takes the pain out of requesting and comparing quotes.

      </Hidden>
</Grid>
        
        </Grid>
        <Grid item xs={12} md={4} style={{
          padding: mdUp ? "2%" : smUp ? "1%" : "0",
          //paddingRight: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ? 35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
          Reputable Local Movers For Your Family’s Safety

        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
             lineHeight: 1.5,
            fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
      Souqh invests a great deal of effort in vetting service providers on the platform. We do this to make sure all service providers have the necessary licenses and insurance cover. This is why we added a reviews feature so users can share their experiences - good or bad - with others. This feedback will help the higher quality services rise to the top.

      <Hidden mdUp lgUp xsUp smUp xlUp>
      Unfortunately, within the moving services industry are inexperienced and dishonest providers that can spoil your entire relocation. Others are flight-by-night chancers who are out to make a quick buck. This latter kind tends to cut corners and may hire questionable characters that can put your family at risk.

            </Hidden>
</Grid>
        
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={12} md={4} style={{
          //paddingLeft: mdUp ? "2%" : smUp ? "1%" : "0",
          padding: mdUp? "2%" : smUp ? "1%" : "0"
        }}>
        <Grid
          align="left"
          className={clsx(WebStyles.samonHeaderTxt, WebStyles.hankrndFont)}
          style={{
            fontSize: mdUp ?  35 : 20,
                  lineHeight: mdUp ? "48px" : "24px",
                  marginBottom: mdUp ? 12 : 8,
                  
            fontWeight: 600,
          }}
        >
          Secure, Insured Transportation For Your Delicate And Expensive Objects

        </Grid>
        <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 22 : 18,
             lineHeight: 1.5,
            fontWeight: 600,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
If you have priceless objects that require special transportation, like pianos, you may also be better off hiring a mover that specializes in moving such objects. Some moving companies on Souqh even offer white-glove furniture disassembly and reassembly services that take care of some of the most exhausting parts of the moving process.

  <Hidden mdUp lgUp smUp xsUp xlUp>
  Outside the random break-in and fire at your house, the only time you may ever worry about losing your prized antique furniture, art collection, family heirlooms, and delicate expensive furniture and gadgets is when moving them between houses. 
<br/>
So the moving company you choose should have experienced crews and carry the necessary insurance. An experienced, caring crew minimizes damage to your furniture and delicate objects, ensuring that the relocation goes smoothly.

  </Hidden>
</Grid>
        {/* <Grid
          align="left"
          className={clsx(WebStyles.paraBlackText, WebStyles.barlowFont)}
          style={{
            fontSize: mdUp ? 18 : 16,
            // lineHeight: 1,
            marginBottom: xlUp ? 24 : mdUp ? 40 : 10,
          }}
        >
       For instance, the realtor was given incorrect information from a previous realtor or the homeowner and passed this on to the client. This is grounds to sue, however, you’re most likely to have only the damages compensated financially.
        </Grid> */}
        </Grid>
     </Grid>




    </Grid>
    </Container>
    </Container>
  );
}



// function Introduction({
//   smUp,
//   smDown,
//   mdUp,
//   mdDown,
//   lgUp,
//   xlUp,
//   commonPadding,
// }) {
//   const [showWaitListForm, setShowWaitListForm] = useState(false);
//   const handleClose = () => {
//     setShowWaitListForm(false);
//   };
//   return (
//     <Grid
//       item
//       container
//       xs={12}
//       style={{
//         backgroundImage: lgUp
//           ? "url('/images/home_buyers/Green patch.svg')"
//           : "none",
//         backgroundRepeat: "no-repeat",
//         backgroundSize: "10%",
//         padding: commonPadding,
//       }}
//     >

//       <Container>
//         <Container>
//           <Grid container>
          
//               <Grid container direction="row">
//                 <SectionTwo
//                   xlUp={xlUp}
//                   mdUp={mdUp}
//                   mdDown={mdDown}
//                   smDown={smDown}

//                 />
                
//               </Grid>
          

//           </Grid>
//         </Container>
//       </Container>
//     </Grid>
//   );
// }

export default SectionThree;
